import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import 'react-dropzone-uploader/dist/styles.css'
import Select from 'react-select';

import { Modal } from 'react-bootstrap';

import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import country_arr from './common_arr/countries-arr.js';
import s_a from './states-arr.js';

function Warehouse(props) {
    const [userTheme, setUserTheme] = useState((localStorage.getItem("kt_metronic_theme_mode_menu") === "system") ? window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light" : localStorage.getItem("kt_metronic_theme_mode_menu"))
    const [state, setState] = useState(2);
    const [mainSubmitButtonState, setMainSubmitButtonState] = useState(false)

    const [addWarehouseModalShow, setAddWarehouseModalShow] = useState(false)
    const [editWarehouseModalShow, setEditWarehouseModalShow] = useState(false)

    const [countryArray, setCountryArray] = useState([])
    const [selectedCountry, setSelectedCountry] = useState({ index: 0 })
    const [stateArray, setStateArray] = useState([])

    const [newWarehouse, SetNewWarehouse] = useState({ name: "", branch_id: null, street1: "", street2: "", city: "", country: null, state: null, postalcode: "", phone: "", email: "" })
    const [editWarehouse, SetEditWarehouse] = useState({ name: "", branch_id: null, street1: "", street2: "", city: "", country: null, state: null, postalcode: "", phone: "", email: "" })

    const [branchList, setBranchList] = useState([])
    const [warehouseList, setWarehouseList] = useState([])

    const [submitButton, SetSubmitButton] = useState(false)

    var page_name = "warehouse";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    let dropdownStyleDark = {
        option: (provided, state) => ({
            ...provided,
            color: '#dddddf',
            backgroundColor: state.isSelected ? provided.backgroundColor : state.isFocused ? '#5f5f8d' : provided.backgroundColor,
        }),
        control: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
            borderColor: "#4c4c71"
        }),
        placeholder: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        singleValue: (provided, state) => ({
            ...provided,
            color: "#dddddf",
        }),
        menu: (provided, state) => ({
            ...provided,
            backgroundColor: "#2b2b40",
        })
    }

    useEffect(() => {
        let obj_array = country_arr.country_arr.map((obj, index) => {
            if (obj === "Select Country") { return { label: obj, value: "", index: index } }
            return { label: obj, value: obj, index: index }
        })
        setCountryArray(obj_array);
    }, [])

    useEffect(() => {
        let obj_array = s_a.s_a[selectedCountry.index].split("|").map((obj) => {
            if (obj === "Select State") { return { label: obj, value: "", cIndex: 0 } }
            return { label: obj, value: obj, cIndex: selectedCountry.index }
        })
        setStateArray(obj_array);
    }, [countryArray, selectedCountry])

    async function getbranches() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            setBranchList(body.data)
        }
    }
    async function getwarehouses() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/list-warehouse`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation === "success") {
            setWarehouseList(body.data)
        }
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {
            let p1 = getbranches();
            let p2 = getwarehouses();
            Promise.all([p1, p2])
                .then(() => {
                    setState(1)
                    // console.log("all apis done")
                })
                .catch((err) => {
                    setState(3)
                    console.log(err)
                })
        }

    }, [user, visibility, read, write])

    const editSetup = (warehouse) => {
        let temp = {
            name: warehouse.name, branch_id: "", street1: "", street2: "", city: "", country: "", state: "", postalcode: "", phone: "", email: ""
        }
        let cIndex = 0

        let findCountry = countryArray.find(country => country.value === warehouse.country)
        if (findCountry !== undefined) {
            cIndex = findCountry.index
        }
        // console.log(countryArray, cIndex, warehouse.country)
        let obj_array = s_a.s_a[cIndex].split("|").map((obj) => {
            if (obj === "Select State") { return { label: obj, value: "", cIndex: 0 } }
            return { label: obj, value: obj, cIndex: cIndex }
        })
        setStateArray(obj_array);
        SetEditWarehouse({ ...warehouse })
        // SetEditWarehouse(temp)
        setEditWarehouseModalShow(true);
    }


    const newWarehouseSubmit = () => {
        // var nexp = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~0-9]/;
        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 \/\\&|@#()\\[\]_.,:"']*)?$/;
        //Name check
        if (newWarehouse.name === "") {
            swal("Warning!", "Warehouse must have name", "warning");
            return
        }
        if (!newWarehouse.name.match(nexp)) {
            swal("Warning!", "Warehouse name cannot contain a number or symbols", "warning");
            return
        }
        let flag = false
        warehouseList.map((warehouse) => {
            // if (warehouse.wid !== object.wid) {
            if (warehouse.name.trim().toLowerCase() === newWarehouse.name.toLowerCase()) {
                flag = true

            }
            // }
        })
        if (flag) {
            swal("Warning!", "Name already exists", "warning");
            return
        }
        //Branch check
        if (newWarehouse.branch_id === null) {
            swal("Warning!", "Select a Branch", "warning");
            return
        }

        //Street1 check
        if (newWarehouse.street1 === "") {
            swal("Warning!", "Add a street name", "warning");
            return
        }

        //City check
        if (newWarehouse.city === "") {
            swal("Warning!", "Add a city", "warning");
            return
        }
        //Country check
        if (newWarehouse.country === null) {
            swal("Warning!", "Select a country", "warning");
            return
        }
        //State check
        if (newWarehouse.state === null) {
            swal("Warning!", "Select a state", "warning");
            return
        }

        let postalregex = /^[0-9]{5,6}$/
        //Postal check
        if (newWarehouse.postalcode === "") {
            swal("Warning!", "Add a postal code", "warning");
            return
        }
        if (!newWarehouse.postalcode.match(postalregex)) {
            swal("Warning!", "Add a valid postal code", "warning");
            return
        }

        let regex1 = /^(\+\d{1,3}[- ]?)?\d{10}$/
        //Phone check
        if (newWarehouse.phone === "") {
            swal("Warning!", "Phone cannot be empty", "warning");
            return
        }
        if (!regex1.test(newWarehouse.phone)) {
            swal("Warning!", "Please provide valid phone number", "warning");
            return
        }

        let regex2 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        //Email check
        if ((newWarehouse.email !== "") && (!regex2.test(newWarehouse.email))) {
            swal("Warning!", "Please enter a valid Email id", "warning");
            return false;
        }
        if ((newWarehouse.email === "")) {
            swal("Warning!", "Email id cannot be empty", "warning");
            return false;
        }

        let object = { ...newWarehouse, page_name: page_name, organization_id: organization_id };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        // console.log(object)
        SetSubmitButton(true)

        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-warehouse`, object, headers).then((res) => {
            SetSubmitButton(false)
            setSelectedCountry({ index: 0 })
            if (res.data.operation === "success") {
                swal("Great!", res.data.message, "success").then((value) => {
                    getwarehouses()
                    setAddWarehouseModalShow(false)
                    SetNewWarehouse({ name: "", branch_id: null, street1: "", street2: "", city: "", country: null, state: null, postalcode: "", phone: "", email: "" })
                });
            }
            else {
                swal("Oops!", res.data.message.substring(0, 30), "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const editWarehouseSubmit = () => {
        var nexp = /^(?!\d+$)(?:[a-zA-Z0-9][a-zA-Z0-9 \/\\&|@#()\\[\]_.,:"']*)?$/;
        //Name check
        if (editWarehouse.name === "") {
            swal("Warning!", "Warehouse must have name", "warning");
            return
        }
        if (!editWarehouse.name.match(nexp)) {
            swal("Warning!", "Warehouse name cannot contain a number or symbols", "warning");
            return
        }
        let flag = false
        warehouseList.map((warehouse) => {
            if (warehouse.wid !== editWarehouse.wid) {
                if (warehouse.name.trim().toLowerCase() === editWarehouse.name.trim().toLowerCase()) {
                    flag = true
                }
            }
        })
        if (flag) {
            swal("Warning!", "Name already exists", "warning");
            return
        }
        //Branch check
        if (editWarehouse.branch_id === null) {
            swal("Warning!", "Select a Branch", "warning");
            return
        }
        //Street1 check
        if (editWarehouse.street1 === "") {
            swal("Warning!", "Add a street name", "warning");
            return
        }

        //City check
        if (editWarehouse.city === "") {
            swal("Warning!", "Add a city", "warning");
            return
        }
        //Country check
        if (editWarehouse.country === null) {
            swal("Warning!", "Select a country", "warning");
            return
        }
        //State check
        if (editWarehouse.state === null) {
            swal("Warning!", "Select a state", "warning");
            return
        }

        let postalregex = /^[0-9]{5,6}$/
        //Postal check
        if (editWarehouse.postalcode === "") {
            swal("Warning!", "Add a postal code", "warning");
            return
        }
        if (!editWarehouse.postalcode.match(postalregex)) {
            swal("Warning!", "Add a valid postal code", "warning");
            return
        }

        let regex1 = /^(\+\d{1,3}[- ]?)?\d{10}$/
        //Phone check
        if (editWarehouse.phone === "") {
            swal("Warning!", "Phone cannot be empty", "warning");
            return
        }
        if (!regex1.test(editWarehouse.phone)) {
            swal("Warning!", "Please provide valid phone number", "warning");
            return
        }

        let regex2 = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        //Email check
        if ((editWarehouse.email !== "") && (!regex2.test(editWarehouse.email))) {
            swal("Warning!", "Please enter a valid Email id", "warning");
            return false;
        }
        if ((editWarehouse.email === "")) {
            swal("Warning!", "Email id cannot be empty", "warning");
            return false;
        }

        let object = { ...editWarehouse, page_name: page_name, service: "accounting" };

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        // console.log(object)
        SetSubmitButton(true)

        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-warehouse`, object, headers).then((res) => {
            SetSubmitButton(false)
            setSelectedCountry({ index: 0 })
            if (res.data.operation === "success") {
                swal("Great!", res.data.message, "success").then((value) => {
                    getwarehouses()
                    setEditWarehouseModalShow(false)
                    SetEditWarehouse({ name: "", branch_id: null, street1: "", street2: "", city: "", country: null, state: null, postalcode: "", phone: "", email: "" })
                });
            }
            else {
                swal("Oops!", res.data.message.substring(0, 30), "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    return (
        <>
            <div className="d-flex flex-column flex-column-fluid">
                <div className="app-toolbar py-3 py-lg-6" id="kt_app_toolbar">
                    <div id="kt_app_toolbar_container" className="app-container container-xxl d-flex flex-stack">
                        <h1 className="page-heading d-flex text-dark fw-bold flex-column justify-content-center my-0">Warehouse</h1>
                        <div className="d-flex align-items-center gap-2 gap-lg-3">
                            <button className='btn btn-success' onClick={(e) => { setAddWarehouseModalShow(true) }}><i className="las la-plus"></i>Add New</button>
                        </div>
                    </div>
                </div>
                <div id="kt_app_content" className="app-content flex-column-fluid">
                    <div id="kt_app_content_container" className="app-container container-xxl">
                        {
                            state === 1 ?
                                <>
                                    <div className='d-flex flex-wrap'>
                                        {
                                            warehouseList.map((warehouse, i) => {
                                                return (
                                                    <div key={i} className="card h-lg-100 m-2" style={{ minWidth: "380px" }}>
                                                        <div className="card-header mt-6 align-items-center">

                                                            <div className="card-title flex-column ">
                                                                <h3 className="fw-bolder mb-1">{warehouse.name}</h3>
                                                                <div className="col-lg-8 d-flex align-items-center">
                                                                    {<span className={`badge badge-light-${warehouse.status === "Active" ? "success" : warehouse.status === "Inactive" ? "warning" : "secondary"} rounded me-2`}>{warehouse.status}</span>}
                                                                    {warehouse.default ? <span className="badge badge-light-primary me-auto rounded">Default</span> : null}
                                                                </div>
                                                            </div>

                                                            {
                                                                !warehouse.default &&
                                                                <>
                                                                    <div className="py-3 px-5 bg-secondary rounded text-hover-primary" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"><i className="fas fa-bars px-0"></i>
                                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                            {
                                                                                warehouse.status === "Active" &&
                                                                                <li>
                                                                                    <div className="dropdown-item" style={{ cursor: "pointer" }} onClick={(e) => {
                                                                                        swal({
                                                                                            title: `Set ${warehouse.name} as Default Warehouse`,
                                                                                            text: "Are You Sure?",
                                                                                            icon: "warning",
                                                                                            buttons: ["Cancel", "Confirm"],
                                                                                        }).then((value) => {
                                                                                            if (!!value) {
                                                                                                var object = { wid: warehouse.wid, organization_id: warehouse.organization_id, page_name: page_name }
                                                                                                var headers = {
                                                                                                    headers: {
                                                                                                        "Content-Type": "application/json",
                                                                                                        "Accept": "*/*",
                                                                                                        "token": getCookie("access_token")
                                                                                                    }
                                                                                                }
                                                                                                SetSubmitButton(true)
                                                                                                axios.post(`${process.env.REACT_APP_SERVER_URL}/set-default-warehouse`, object, headers).then((res) => {
                                                                                                    SetSubmitButton(false)
                                                                                                    if (res.data.operation === "success") {
                                                                                                        swal("Great!", res.data.message, "success").then((value) => {
                                                                                                            window.location.reload();
                                                                                                        });
                                                                                                    }
                                                                                                    else {
                                                                                                        swal("Oops!", res.data.message, "error");
                                                                                                    }
                                                                                                });
                                                                                            }
                                                                                        })
                                                                                    }}>Set as Default</div>
                                                                                </li>
                                                                            }
                                                                            <li><div className="dropdown-item" style={{ cursor: "pointer" }} onClick={(e) => { editSetup(warehouse) }}>Edit</div></li>
                                                                            <li>
                                                                                <div className="dropdown-item" style={{ cursor: "pointer" }} onClick={(e) => {
                                                                                    swal({
                                                                                        title: `Change Status to ${(warehouse.status === "Active") ? "INACTIVE" : "ACTIVE"}`,
                                                                                        text: "Are You Sure?",
                                                                                        icon: "warning",
                                                                                        buttons: ["Cancel", "Confirm"],
                                                                                        dangerMode: true,
                                                                                    }).then((value) => {
                                                                                        if (!!value) {
                                                                                            var object = { ...warehouse, status: (warehouse.status === "Active") ? "Inactive" : "Active", page_name: page_name }
                                                                                            var headers = {
                                                                                                headers: {
                                                                                                    "Content-Type": "application/json",
                                                                                                    "Accept": "*/*",
                                                                                                    "token": getCookie("access_token")
                                                                                                }
                                                                                            }
                                                                                            SetSubmitButton(true)

                                                                                            axios.post(`${process.env.REACT_APP_SERVER_URL}/update-warehouse`, object, headers).then((res) => {
                                                                                                SetSubmitButton(false)
                                                                                                if (res.data.operation === "success") {
                                                                                                    swal("Great!", res.data.message, "success").then((value) => {
                                                                                                        getwarehouses()
                                                                                                    });
                                                                                                }
                                                                                                else {
                                                                                                    swal("Oops!", "Something went wrong", "error");
                                                                                                }
                                                                                            });
                                                                                        }
                                                                                    })
                                                                                }}>Change Status</div>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                        <div className="separator "></div>

                                                        <div className="card-body px-auto">

                                                            <div className="d-flex justify-content-between mb-3">
                                                                <div className="my-auto">
                                                                    <label className="fw-bold mb-2">Phone:</label>
                                                                </div>
                                                                <div className='my-auto'>
                                                                    {warehouse.phone}
                                                                </div>
                                                            </div>
                                                            <div className="separator separator-dashed mb-2"></div>
                                                            <div className="d-flex justify-content-between my-3">
                                                                <div className="my-auto">
                                                                    <label className="fw-bold mb-2">Email:</label>
                                                                </div>
                                                                <div className='my-auto'>
                                                                    {warehouse.email}

                                                                </div>
                                                            </div>
                                                            <div className="separator separator-dashed mb-2"></div>
                                                            <div className="d-flex justify-content-between my-3">
                                                                <div className="my-auto">
                                                                    <label className="fw-bold mb-2">Branch:</label>
                                                                </div>
                                                                <div className='my-auto'>
                                                                    {branchList.length > 0 && (branchList.find(a => a.branch_id === warehouse.branch_id)).branch_name}
                                                                </div>
                                                            </div>
                                                            <div className="separator separator-dashed mb-2"></div>
                                                            <div className="d-flex justify-content-between my-3">
                                                                <div className="my-auto">
                                                                    <label className="fw-bold mb-2">Address:</label>
                                                                </div>
                                                                <div className='my-auto'>
                                                                    {
                                                                        warehouse.street1 &&
                                                                        <>
                                                                            <span title={warehouse.street1}>{warehouse.street1.substring(0, 20) + (warehouse.street1.length > 20 ? "..." : "")},</span><br />
                                                                        </>
                                                                    }
                                                                    {warehouse.city} {warehouse.state} {warehouse.postalcode}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="separator "></div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                                :
                                state === 2 ?
                                    <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                    :
                                    <>
                                        <div className="flex-lg-row-fluid me-lg-15">
                                            <div className="card card-flush pt-3 mb-5 mb-lg-10">
                                                <div className="card-body pt-0">
                                                    <div className="text-gray-500 fw-bold fs-5 mb-5"></div>
                                                    <div className="d-flex align-items-center p-3 mb-2"></div>

                                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed rounded-3 p-6">
                                                        <div className="d-flex flex-stack flex-grow-1">
                                                            <div className="fw-bold">
                                                                <h4 className="text-gray-800 fw-bolder">Please Refresh this page</h4>
                                                                <div className="fs-6 text-gray-600"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                        }
                    </div>
                </div>

            </div>
            <Modal show={addWarehouseModalShow} onHide={() => { setAddWarehouseModalShow(false) }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>New Warehouse</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setAddWarehouseModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Name</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid" value={newWarehouse.name} onChange={(e) => { SetNewWarehouse({ ...newWarehouse, name: e.target.value }) }} placeholder='Name of Warehouse' />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Branch</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <Select
                                    className="form-control form-control-solid"
                                    styles={userTheme === 'dark' ? dropdownStyleDark : {}}
                                    options={branchList.map(x => { return { label: x.branch_name, value: x.branch_id } })}
                                    onChange={(val) => { SetNewWarehouse({ ...newWarehouse, branch_id: val.value }) }}
                                    value={newWarehouse.branch_id != null && { label: branchList.find(x => x.branch_id === newWarehouse.branch_id).branch_name, value: newWarehouse.branch_id }}
                                    placeholder="Select Branch"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Street 1</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid" value={newWarehouse.street1} onChange={(e) => { SetNewWarehouse({ ...newWarehouse, street1: e.target.value }) }} placeholder='Street line 1' />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="fs-5 mb-2">Street 2</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid" value={newWarehouse.street2} onChange={(e) => { SetNewWarehouse({ ...newWarehouse, street2: e.target.value }) }} placeholder='Street line 2' />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">City</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid" value={newWarehouse.city} onChange={(e) => { SetNewWarehouse({ ...newWarehouse, city: e.target.value }) }} placeholder='City' />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Country</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <Select
                                    className="form-control form-control-solid"
                                    styles={userTheme === 'dark' ? dropdownStyleDark : {}}
                                    options={countryArray}
                                    value={newWarehouse.country != null && { label: newWarehouse.country, value: newWarehouse.country }}
                                    onChange={(val) => { setSelectedCountry(val); SetNewWarehouse({ ...newWarehouse, country: val.value, state: null }) }}
                                    placeholder="Select Country"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">State</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <Select
                                    className="form-control form-control-solid"
                                    styles={userTheme === 'dark' ? dropdownStyleDark : {}}
                                    options={stateArray}
                                    value={newWarehouse.state != null && { label: newWarehouse.state, value: newWarehouse.state }}
                                    onChange={(val) => { SetNewWarehouse({ ...newWarehouse, state: val.value }) }}
                                    placeholder="Select State"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Postal code</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid" value={newWarehouse.postalcode} onChange={(e) => { SetNewWarehouse({ ...newWarehouse, postalcode: e.target.value }) }} placeholder='postal code' />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Phone</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid" value={newWarehouse.phone} onChange={(e) => { SetNewWarehouse({ ...newWarehouse, phone: e.target.value }) }} placeholder='phone' />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Email</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid" value={newWarehouse.email} onChange={(e) => { SetNewWarehouse({ ...newWarehouse, email: e.target.value }) }} placeholder='email' />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded me-auto" onClick={() => { setAddWarehouseModalShow(false) }}>Close</button>
                    {
                        write &&
                        <button className="btn btn-success rounded" data-kt-indicator={submitButton ? "on" : "off"} disabled={submitButton} onClick={() => { newWarehouseSubmit() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={editWarehouseModalShow} onHide={() => { setEditWarehouseModalShow(false) }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Edit Warehouse</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setEditWarehouseModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Name</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid" value={editWarehouse.name} onChange={(e) => { SetEditWarehouse({ ...editWarehouse, name: e.target.value }) }} placeholder='Name of Warehouse' />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Branch</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <Select
                                    className="form-control form-control-solid"
                                    styles={userTheme === 'dark' ? dropdownStyleDark : {}}
                                    options={branchList.map(x => { return { label: x.branch_name, value: x.branch_id } })}
                                    value={editWarehouse.branch_id != null && { label: branchList.find(x => x.branch_id === editWarehouse.branch_id).branch_name, value: editWarehouse.branch_id }}
                                    onChange={(val) => { SetEditWarehouse({ ...editWarehouse, branch_id: val.value }) }}
                                    placeholder="Select Branch"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Street 1</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid" value={editWarehouse.street1} onChange={(e) => { SetEditWarehouse({ ...editWarehouse, street1: e.target.value }) }} placeholder='Street line 1' />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="fs-5 mb-2">Street 2</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid" value={editWarehouse.street2} onChange={(e) => { SetEditWarehouse({ ...editWarehouse, street2: e.target.value }) }} placeholder='Street line 2' />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">City</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid" value={editWarehouse.city} onChange={(e) => { SetEditWarehouse({ ...editWarehouse, city: e.target.value }) }} placeholder='City' />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Country</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <Select
                                    className="form-control form-control-solid"
                                    styles={userTheme === 'dark' ? dropdownStyleDark : {}}
                                    options={countryArray}
                                    value={editWarehouse.country != null && { label: editWarehouse.country, value: editWarehouse.country }}
                                    onChange={(val) => { setSelectedCountry(val); SetEditWarehouse({ ...editWarehouse, country: val.value, state: "" }) }}
                                    placeholder="Select Country"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">State</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <Select
                                    className="form-control form-control-solid"
                                    styles={userTheme === 'dark' ? dropdownStyleDark : {}}
                                    options={stateArray}
                                    value={editWarehouse.state != null && { label: editWarehouse.state, value: editWarehouse.state }}
                                    onChange={(val) => { SetEditWarehouse({ ...editWarehouse, state: val.value }) }}
                                    placeholder="Select State"
                                />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Postal code</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid" value={editWarehouse.postalcode} onChange={(e) => { SetEditWarehouse({ ...editWarehouse, postalcode: e.target.value }) }} placeholder='postal code' />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Phone</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid" value={editWarehouse.phone} onChange={(e) => { SetEditWarehouse({ ...editWarehouse, phone: e.target.value }) }} placeholder='phone' />
                            </div>
                        </div>
                        <div className="d-flex justify-content-between mb-5">
                            <div className="col-3 my-auto">
                                <label className="required fs-5 mb-2">Email</label>
                            </div>
                            <div className='col-9 my-auto'>
                                <input type="text" className="form-control form-control-solid" value={editWarehouse.email} onChange={(e) => { SetEditWarehouse({ ...editWarehouse, email: e.target.value }) }} placeholder='email' />
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded me-auto" onClick={() => { setEditWarehouseModalShow(false) }}>Close</button>
                    {
                        write &&
                        <button className="btn btn-success rounded " data-kt-indicator={submitButton ? "on" : "off"} disabled={submitButton} onClick={() => { editWarehouseSubmit() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>
        </>
    );
}
export default Warehouse;