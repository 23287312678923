import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import { Link, Redirect, useHistory } from 'react-router-dom'
import Select, { components } from 'react-select';
import moment from 'moment';
import { Modal } from "react-bootstrap";

import country_arr from './common_arr/countries-arr.js';
import s_a from './common_arr/states-arr.js';
import product_unit_arr from './common_arr/product-unit-arr.js';
import currency_arr from './common_arr/currency-arr.js';

import { useTheme } from "./theme-provider.js"
import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import PageError from '../common_component/PageError.js';

function EditCustomerOrder(props) {

    const [state, setstate] = useState(2);
    const { theme, dropdownStyleDark } = useTheme()

    const history = useHistory()

    const [customerOrderDetails, setCustomerOrderDetails] = useState(null)

    const [branchList, setBranchlist] = useState([])
    const [transactionSeriesList, setTransactionSeriesList] = useState([])
    const [itemList, setItemList] = useState([])
    const [userList, setUserList] = useState([])
    const [termsList, setTermsList] = useState([])
    const [currencyList, setCurrencyList] = useState([])
    const [currencyExchangeRates, setCurrencyExchangeRates] = useState([])
    const [organizationDetails, setOrganizationDetails] = useState(null)
    const [addressList, setAddressList] = useState([]);
    const [customerGstinList, setCustomerGstinList] = useState([]);
    const [SupplyStateOptions, setSupplyStateOptions] = useState([]);
    const [warehouseList, setWarehouseList] = useState([])
    const [taxGroupList, setTaxGroupList] = useState([]);
    const [taxList, setTaxList] = useState([])
    const [negativeStock, setNegativeStock] = useState(false)
    const [stockAdditionWarehouseProcessing, setStockAdditionWarehouseProcessing] = useState(false)

    const [selectedCustomerFullDetails, setSelectedCustomerFullDetails] = useState(null)
    const [selectedCustomer, setSelectedCustomer] = useState(null)
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedTransactionSeries, setSelectedTransactionSeries] = useState(null);
    const [selectedBillingAddress, setSelectedBillingAddress] = useState(null)
    const [selectedShippingAddress, setSelectedShippingAddress] = useState(null)
    const [selectedGstin, setSelectedGstin] = useState(null)
    const [orderNumber, setOrderNumber] = useState("")
    const [referenceNumber, setReferenceNumber] = useState("")
    const [sourceOfSupply, setSourceOfSupply] = useState(null)
    const [selectedPlaceOfSupply, setSelectedPlaceOfSupply] = useState(null);
    const [orderDate, setOrderDate] = useState(moment.utc().format('YYYY-MM-DD'))
    const [selectedTerm, setSelectedTerm] = useState(null)
    const [dueDate, setDueDate] = useState(moment.utc().add(5, "days").format('YYYY-MM-DD'))
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedCurrency, setSelectedCurrency] = useState(null)
    const [selectedWarehouse, setSelectedWarehouse] = useState(null)
    const [subject, setSubject] = useState("")
    const [adjustment, setAdjustment] = useState({ text: "Adjustment", display: "0", value: 0 })
    const [selectedTaxCategory, setSelectedTaxCategory] = useState(null)
    const [selectedTax, setSelectedTax] = useState(null)
    const [customerNotes, setCustomerNotes] = useState("")
    const [termsAndConditions, setTermsAndConditions] = useState("")

    const [itemArray, setItemArray] = useState([{ item: null, item_data: null, item_info_popover: false, item_type: "", item_code: "", item_popover: false, item_popover_input: "", quantity: 1, quantity_unit: null, quantity_max: 0, pre_defined_rate: 0, rate: 0, rate_popover: false, discount_unit: 0, discount_type: { label: "amount", value: "amount" }, tax: null, taxable: false, tax_calculation_method: null, op_toggle: false }])
    const [subTotal, setSubTotal] = useState(0)
    const [grandTotal, setGrandTotal] = useState(0)

    const [submitButtonState, setSubmitButtonState] = useState(false);

    const [productCodeModalShow, setProductCodeModalShow] = useState(false)
    const [productCodeItemId, setProductCodeItemId] = useState(null)
    const [productCodeItemType, setProductCodeItemType] = useState(null)
    const [productCodeSearch, setProductCodeSearch] = useState("")
    const [productCodeList, setProductCodeList] = useState([])
    const [productCodeListApiState, setProductCodeListApiState] = useState(false)

    const [gstInListModalShow, setGstInListModalShow] = useState(false);

    const [upsertGstInModalShow, setUpsertGstInModalShow] = useState(false);
    const [upsertGstInId, setUpsertGstInId] = useState("");
    const [upsertGstInTreatmentList, setUpsertGstInTreatmentList] = useState([{ label: "Registered Business-Regular", value: "Registered Business-Regular", description: "A business that is registered under GST" }, { label: "Registered Business-Composition", value: "Registered Business-Composition", description: "A business that is registered under composition scheme in GST" }, { label: "Special Economic Zone", value: "Special Economic Zone", description: "Business that is located in a special economic zone" }, { label: "Deeemed Export", value: "Deeemed Export", description: "Supply of goods to an export oriented unit" }, { label: "SEZ Devloper", value: "SEZ Devloper", description: "An organization/person who owns atleast 26% of the equity in creating business units" }])
    const [upsertGstInTreatment, setUpsertGstInTreatment] = useState(null)
    const [upsertGstInNumber, setUpsertGstInNumber] = useState("");
    const [upsertGstInLegalName, setUpsertGstInLegalName] = useState("");
    const [upsertGstInTradeName, setUpsertGstInTradeName] = useState("");
    const [upsertGstInSelectedPlaceOfSupply, setUpsertGstInSelectedPlaceOfSupply] = useState(null)
    const [upsertGstInSubmitButtonState, setUpsertGstInSubmitButtonState] = useState(false);

    const [LegalName, setLegalName] = useState("");
    const [TradeName, setTradeName] = useState("");
    const [GstIN, setGstIN] = useState("");

    const [gstinSearchDetails, setGstinSearchDetails] = useState(null)
    const [gstinSearchDetailsApiState, setGstinSearchDetailsApiState] = useState(false)

    const [placeSupply, setPlaceSupply] = useState(null);

    const [ItemModalShow, setItemModalShow] = useState(false);
    const [Product_type, setProduct_type] = useState("Goods");
    const [ItemName, setItemName] = useState("");
    const [UnitSelectionList, setUnitSelectionList] = useState([]);
    const [Unit, setUnit] = useState("8");
    // const [TaxSelectionList, setTaxSelectionList] = useState([{ label: "Taxable", value: "Taxable" }, { label: "Non Taxable", value: "Non Taxable" }, { label: "Out of Scope", value: "Out of Scope" }, { label: "Non-GST Supply", value: "Non-GST Supply" }]);
    const [TaxPreference, setTaxPreference] = useState("Taxable");
    const [TaxCalculationMethod, setTaxCalculationMethod] = useState("TAX_Inclusive")
    const [Default_tax_preference, setDefault_tax_preference] = useState({ "Inter_State_Tax": "", "Intra_State_Tax": "" });
    const [Tracking_inventory, setTracking_inventory] = useState(false);
    const [Tracking_inventory_type, setTracking_inventory_type] = useState("");
    const [itemSubmitButtonState, setitemSubmitButtonState] = useState(false);

    const [AddressModalShow, setAddressModalShow] = useState(false);
    const [AddressType, setAddressType] = useState("");

    // const [CustomerApiState, setCustomerApiState] = useState(false);
    const [itemApiState, setItemApiState] = useState(false);
    const itemListApiController = useRef(new AbortController())

    const [termsModalShow, setTermsModalShow] = useState(false)
    const [termsPanelList, setTermsPanelList] = useState([])
    const [termsSubmitButtonState, setTermsSubmitButtonState] = useState(false);

    const [currencyModalShow, setCurrencyModalShow] = useState(false)
    const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(null)
    const [currencySymbol, setCurrencySymbol] = useState("")
    const [selectedCurrencyDecimalPlace, setSelectedCurrencyDecimalPlace] = useState(null)
    const [selectedCurrencyFormat, setSelectedCurrencyFormat] = useState(null)
    const [currencySubmitButtonState, setCurrencySubmitButtonState] = useState(false)
    const [approvalInfo, setApprovalInfo] = useState(null)

    const search_length = 2;

    var page_name = "edit-customer-order";

    const { user, read, write, visibility, organization_id, user_role } = useAuthData(page_name)

    const loaderstyle = {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        marginTop: "auto",
        marginBottom: "auto",
    };

    useEffect(() => {
        var obj_array = [];
        var Productunitarr = product_unit_arr.product_unit_arr;
        for (let k = 0; k < Productunitarr.length; k++) {
            var obj2 = {};
            obj2.label = Productunitarr[k].Short_term + "(" + Productunitarr[k].Unit + ")";
            obj2.value = Productunitarr[k].id;
            obj_array.push(obj2);
        }
        setUnitSelectionList(obj_array);
    }, [])

    useEffect(() => {
        if ((taxGroupList.length > 0) && (currencyList.length > 0)) {
            setSubTotal(trimDecimalPlaces(itemArray.reduce((p, o) => { return p + (((o.quantity * o.rate) - (o.discount_type.value == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) + (o.tax == null ? 0 : ((o.quantity * o.rate) - (o.discount_type.value == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) * (taxGroupList.find(x => x.tax_group_id == o.tax.value).tax_group_percentage / 100))) }, 0), selectedCurrency == null ? 3 : currencyList.find(x => x.currency_id == selectedCurrency.value).decimal_place))
        }
    }, [itemArray, selectedCurrency, currencyList, taxGroupList])

    useEffect(() => {
        if ((taxList.length > 0) && (currencyList.length > 0)) {
            setGrandTotal(trimDecimalPlaces(subTotal + adjustment.value + ((selectedTaxCategory == "TDS" ? (-1 * itemArray.reduce((p, o) => { return p + ((o.quantity * o.rate) - (o.discount_type.value == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) }, 0)) : subTotal) * (selectedTax == null ? 0 : parseFloat(taxList.find(x => x.tax_id == selectedTax.value).tax_percentage)) / 100), selectedCurrency == null ? 3 : currencyList.find(x => x.currency_id == selectedCurrency.value).decimal_place))
        }
    }, [subTotal, adjustment, selectedTaxCategory, selectedTax, selectedCurrency, currencyList, taxList])


    async function getCustomerDetails(cid) {

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/customer-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "cid": cid }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        let body = await response.json();
        if (body.data.length != 0) {
            setSelectedCustomerFullDetails(body.data[0])
        }
        else {
            throw "no such customer"
        }
    }

    async function getBranchDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)

        if (body.operation == "success") {
            setBranchlist(body.data);
        }
        else {
            throw "branch list api error"
        }
    }

    async function getTransactionSeriesList() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-transaction-series-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.operation == "success") {
            setTransactionSeriesList(body.data)
        }
        else {
            throw "transaction series api list error"
        }
    }

    async function getSubSeriesData(val) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-series-data-by-name`, { method: 'POST', body: JSON.stringify({ "email": user.email, "sub_series_name": "sales_order", "transaction_series_id": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation == "success") {
            setOrderNumber(`${body.data.prefix_value}-${parseInt(body.data.last_count) + 1}`)
            return true
        }
        else {
            swal("Alert!", body.message, "warning")
            return false
        }
    }

    async function getItems(search_value, signal_flag = true) {
        try {
            if (signal_flag && itemApiState) {
                itemListApiController.current.abort()
                itemListApiController.current = new AbortController()
            }

            setItemApiState(true);
            const response = await fetch(
                `${process.env.REACT_APP_SERVER_URL}/get-product-by-search`, { signal: signal_flag ? itemListApiController.current.signal : undefined, method: 'POST', body: JSON.stringify({ "email": user.email, "search_value": search_value, "type": "Customer", "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
            );
            const body = await response.json();
            // console.log(body)
            if (body.operation === "success") {
                setItemList(body.data);
                setItemApiState(false);

                return body.data;
            }
        }
        catch (err) {
            if (err.name !== 'AbortError') {
                console.log(err)
            }
        }
    }

    async function getuserdetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-user-list`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        setUserList(body)
    }

    async function getWarehouses() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/list-warehouse`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setWarehouseList(body.data)
        }
    }

    async function getTaxGroupDetails() {
        var object = {};
        object.email = user.email;

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/tax-group-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if ((body.operation == "success") && (body.data.length > 0)) {
            setTaxGroupList(body.data);
        }
    }

    async function getTerms() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-invoice-terms`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setTermsList(body)
        }
    }

    async function getCurrencies() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/currency-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCurrencyList(body.data)
        }
    }

    async function getCurrencyExchangeRates() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-live-currency`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setCurrencyExchangeRates(body[0])
        }
    }

    async function getTdsTcsTaxes() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/tax-datatable`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation == "success") {
            setTaxList(body.data);
        }
        else {
            throw "tds tcs tax get api error"
        }
    }

    async function getOrganizationDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/show-organization`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body)
        if (body.length != 0) {
            setOrganizationDetails(body.find(x => x.organization_id == getCookie("organization_id")))
        }
    }

    async function getAddressDetails(val) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-customer-addresses`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();

        if ((body.operation == "success") && (body.data.length > 0)) {
            setAddressList(body.data[0].address_details)
        }
        else {
            throw "address details api error"
        }
    }

    async function getCustomerGstinDetails(val) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-customer-tax-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "cid": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if ((body.operation == "success") && (body.data.length > 0)) {
            setCustomerGstinList(body.data);
        }
        else {
            throw "tax details api error"
        }
    }

    async function updateProductCode(id, type, val) {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/update-hsnsac-code-of-product`, { method: 'POST', body: JSON.stringify({ "email": user.email, product_id: id, product_type: type, product_code: val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation != "success") {
            throw "update product code api error"
        }
    }

    async function getProductCodeList(type, val) {
        setProductCodeListApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-product-hsn-code`, { method: 'POST', body: JSON.stringify({ "email": user.email, "search_type": type === "Goods" ? "HSN" : "SAC", "search_key": val, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation == "success") {
            setProductCodeList(body.data)
            setProductCodeListApiState(false)
        }
        else {
            throw "get product code list api error"
        }
    }

    async function getCustomerOrderDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-customer-order-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "customer_order_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        // console.log(body.data)

        if (body.operation == "success") {
            if (["CUSTOMER_ORDER_DRAFT", "CUSTOMER_ORDER_PENDING_APPROVAL", "CUSTOMER_ORDER_FINALIZED"].includes(body.data.add_status)) {
                setCustomerOrderDetails(body.data)
                return body.data
            }
            else {
                throw "This customer order is not editable"
            }
        }
        else {
            throw "get customer order details api error"
        }
    }

    async function getItemStockSettings() {

        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-item-stock-settings`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setNegativeStock(body.data.negative_item_stock_state);
            setStockAdditionWarehouseProcessing(body.data.stock_addition_warehouse_processing_state)
        }
    }

    async function getApprovalDetails() {
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-specific-approval`, {
            method: 'POST', body: JSON.stringify({ "organization_id": organization_id, "key": "customer_order_approval", "email": user.email, "page_name": page_name }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") }
        });
        const body = await response.json();
        if (body.operation === "success") {
            setApprovalInfo(body.data);

        }
    }

    async function getGstinSearchDetails(gstin) {
        setGstinSearchDetailsApiState(true)
        const response = await fetch(
            `${process.env.REACT_APP_SERVER_URL}/get-gstin-search-details`, { method: 'POST', body: JSON.stringify({ gstin: gstin, "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
        );
        const body = await response.json();
        if (body.operation == "success") {
            setGstinSearchDetails(body.info[0])
            setLegalName(body.info[0].lgnm)
            setTradeName(body.info[0].tradeNam)
            setPlaceSupply({ label: body.info[0].pradr.addr.stcd, value: body.info[0].pradr.addr.stcd })
        }
        else {
            swal("Oops!", body.message, "error");
        }
        setGstinSearchDetailsApiState(false)
    }

    useEffect(() => {
        if ((read || write) && (user.email && visibility)) {

            let p1 = getCustomerOrderDetails()
            let p2 = getBranchDetails()
            let p3 = getTransactionSeriesList()
            let p4 = getWarehouses()
            let p5 = getTaxGroupDetails()
            let p6 = getTdsTcsTaxes()
            let p7 = getuserdetails()
            let p8 = getCurrencies()
            let p9 = getCurrencyExchangeRates()
            let p10 = getOrganizationDetails()
            let p11 = getItems(null)
            let p12 = getTerms()
            let p13 = getItemStockSettings()
            let p14 = getApprovalDetails()

            Promise.all([p1, p2, p3, p4, p5, p6, p7, p8, p9, p10, p11, p12, p13, p14])
                .then(async (val) => {
                    await getCustomerDetails(val[0].customer_id)
                    setstate(1)
                    console.log("all apis done")
                })
                .catch((err) => {
                    setstate(3)
                    console.log(err)
                    swal("Oops!", err, "error").then(() => { history.push("/customer-order-list") })
                })
        }
    }, [user, visibility, read, write])

    useEffect(() => {
        if ((customerOrderDetails != null) && (branchList.length > 0) && (transactionSeriesList.length > 0) && (warehouseList.length > 0)) {

            // console.log(customerOrderDetails)            

            setSelectedCustomer({ label: customerOrderDetails.customer_name, value: customerOrderDetails.customer_id })
            // setSelectedCustomerFullDetails(customerList.find(x => x.cid == customerOrderDetails.customer_id))
            setSelectedBranch({ label: customerOrderDetails.branch_name, value: customerOrderDetails.branch_id });
            setSourceOfSupply(warehouseList.find(x => x.wid == customerOrderDetails.warehouse_id).state)
            setSelectedTransactionSeries({ label: transactionSeriesList.find(x => x.transaction_series_id == customerOrderDetails.transaction_series_id).series_name, value: customerOrderDetails.transaction_series_id });
            setSelectedBillingAddress(customerOrderDetails.billing_address)
            setSelectedShippingAddress(customerOrderDetails.shipping_address)
            setSelectedGstin(customerOrderDetails.gstin_details)
            setOrderNumber(customerOrderDetails.customer_order_number)
            setReferenceNumber(customerOrderDetails.reference_number)
            setSelectedPlaceOfSupply({ label: customerOrderDetails.place_of_supply, value: customerOrderDetails.place_of_supply });
            setOrderDate(moment(customerOrderDetails.order_date).format('YYYY-MM-DD'))
            setSelectedTerm(customerOrderDetails.term == null ? null : { label: customerOrderDetails.term.term_name, value: customerOrderDetails.term.term_days })
            setDueDate(moment(customerOrderDetails.due_date).format('YYYY-MM-DD'))
            setSelectedUser({ label: customerOrderDetails.additional_user_name + " - " + customerOrderDetails.additional_user_email, value: customerOrderDetails.additional_user_email })
            setSelectedCurrency({ label: customerOrderDetails.order_currency.currency_code, value: customerOrderDetails.order_currency.currency_id })
            setSelectedWarehouse({ label: warehouseList.find(x => x.wid == customerOrderDetails.warehouse_id).name, value: customerOrderDetails.warehouse_id })
            setSubject(customerOrderDetails.subject)
            setAdjustment({ ...customerOrderDetails.adjustment, display: customerOrderDetails.adjustment.value.toString() })
            setSelectedTaxCategory(customerOrderDetails.tax_category)
            setSelectedTax(customerOrderDetails.tax_id == null ? null : { label: `${customerOrderDetails.tax_name} (${customerOrderDetails.tax_percentage}%)`, value: customerOrderDetails.tax_id })
            setCustomerNotes(customerOrderDetails.customer_notes)
            setTermsAndConditions(customerOrderDetails.terms_and_conditions)

            let temp = customerOrderDetails.item_array.map(async (item) => {
                let t = await getItems(item.item_id, false)
                let pr_ac = (t[0].product_details.length > 0 && t[0].product_details.find(x => customerOrderDetails.branch_id == x.branch_id && customerOrderDetails.warehouse_id == x.warehouse_id) !== undefined) ? t[0].product_details.find(x => customerOrderDetails.branch_id == x.branch_id && customerOrderDetails.warehouse_id == x.warehouse_id).available_count : 0;
                let g_rate = t[0].price_information === null ? 0 : trimDecimalPlaces((parseFloat((t[0].price_information.type === "Customer" ? t[0].price_information.customer_invoice_price : 0))), customerOrderDetails.order_currency.decimal_place);

                return {
                    item: { label: item.item_name, value: item.item_id },
                    item_data: t[0],
                    item_type: item.item_type,
                    item_code: item.tax_code,
                    item_info_popover: false,
                    item_popover: false,
                    item_popover_input: "",
                    quantity: item.quantity,
                    quantity_unit: item.quantity_unit,
                    quantity_max: pr_ac,
                    pre_defined_rate: g_rate,
                    rate: item.rate,
                    rate_popover: false,
                    discount_unit: item.discount_unit,
                    discount_type: { label: item.discount_type == "amount" ? "amount" : "%", value: item.discount_type },
                    tax: item.tax_group_id == null ? null : { label: `${item.tax_group_name} (${item.tax_group_percentage}%)`, value: item.tax_group_id },
                    taxable: item.taxable,
                    tax_calculation_method: item.tax_calculation_method,
                    op_toggle: false
                }
            })
            Promise.all(temp)
                .then((val) => {
                    // console.log(val);
                    setItemArray(val)
                })
                .catch((err) => {
                    console.log(err);
                })

        }
    }, [customerOrderDetails, branchList, transactionSeriesList, warehouseList])

    useEffect(() => {
        if (selectedCustomer != null) {
            let p1 = getAddressDetails(selectedCustomer.value)
            let p2 = getCustomerGstinDetails(selectedCustomer.value)
            Promise.all([p1, p2])
                .then(() => {
                    console.log("address details api, tax details api done")
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [selectedCustomer])

    const handleGstModalClose = () => {
        setUpsertGstInModalShow(false);
        setGstinSearchDetails(null)
        setGstinSearchDetailsApiState(false)
        setUpsertGstInId("");
        setUpsertGstInTreatment(null)
        setUpsertGstInNumber("");
        setUpsertGstInLegalName("");
        setUpsertGstInTradeName("");
        setUpsertGstInSelectedPlaceOfSupply(null)

    }

    const gstSubmitValidation = () => {

        if (upsertGstInTreatment === null) {
            swal("Warning!", "GST Treatment cannot be empty", "warning");
            return false
        }
        if (upsertGstInNumber === "") {
            swal("Warning!", "GSTIN cannot be empty", "warning");
            return false
        }
        if (upsertGstInTreatment && gstinSearchDetails === null) {
            swal("Warning!", "verify GST before submitting", "warning");
            return false
        }
        if (upsertGstInLegalName === "") {
            swal("Warning!", "Business Legal Name cannot be empty", "warning");
            return false
        }
        if (upsertGstInSelectedPlaceOfSupply === null) {
            swal("Warning!", "Supply place cannot be empty", "warning");
            return false
        }
        return true
    }

    const newGstInSubmit = () => {

        var object = {};

        object.GstIn = upsertGstInNumber;
        object.customer_id = selectedCustomer.value;
        object.gstTreatment = upsertGstInTreatment.value;
        object.place_of_supply = upsertGstInSelectedPlaceOfSupply.value;
        object.legal_name = upsertGstInLegalName;
        object.trade_name = upsertGstInTradeName;
        object.id = upsertGstInId;

        object.organization_id = organization_id;
        object.page_name = page_name;

        setUpsertGstInSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/save-customer-tax-details`, object, headers).then((res) => {
            setUpsertGstInSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Gstin details updated successfully!", "success");
                setUpsertGstInModalShow(false);
                getCustomerGstinDetails(selectedCustomer.value)

                setUpsertGstInId("");
                setUpsertGstInTreatment(null)
                setUpsertGstInNumber("");
                setUpsertGstInLegalName("");
                setUpsertGstInTradeName("");
                setUpsertGstInSelectedPlaceOfSupply(null)
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const submitItemDetails = () => {

        if (ItemName.length == 0) {
            swal("Warning!", "Please enter a Item Name", "warning");
            return;
        }

        if (Unit.length == 0) {
            swal("Warning!", "Please select a unit", "warning");
            return;
        }

        if (Default_tax_preference.Inter_State_Tax === "" || Default_tax_preference.Intra_State_Tax === "") {
            swal("Warning!", "Please select proper tax type", "warning");
            return;
        }

        var values = {
            "ItemName": ItemName,
            "Unit": Unit,
            "Sku": "",
            "Product_type": Product_type,
            "Manufacturer": "",
            "SubCategory": "",
            "Category": "",
            "Brand": "",
            "Hsn_code": "",
            "Sac_code": "",
            "Tracking_inventory": Tracking_inventory,
            "Tracking_inventory_type": Tracking_inventory_type,
            "Shipping_flag": 0,
            "Shipping_info": JSON.stringify([{ "Weight": "", "Dimension": { "Width": "", "Height": "", "Length": "" } }]),
            "Specification_info": JSON.stringify({ "MPN": "", "ICBN": "", "UPC": "", "EAN": "" }),
            "TaxPreference": TaxPreference,
            "Tax_Calculation_Method": TaxCalculationMethod,
            "Default_tax_preference": JSON.stringify(Default_tax_preference),
            "organization_id": organization_id,
            "page_name": page_name,
        };

        setitemSubmitButtonState(true);
        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }

        axios.post(`${process.env.REACT_APP_SERVER_URL}/product`, values, headers).then((res) => {
            setitemSubmitButtonState(false)
            if (res.data && res.data.operation == "success") {
                swal("Great!", "Item details saved successfully!", "success").then((value) => {
                    getItems(null);
                    setItemModalShow(false);
                    setProduct_type("")
                    setItemName("")
                    setUnit("")
                    setTaxPreference("")
                    setTaxCalculationMethod("")
                    setTracking_inventory(false)
                    setTracking_inventory_type("")
                });
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        });
    }

    const trimDecimalPlaces = (amt, decimal_place) => {
        return Math.round(amt * Math.pow(10, decimal_place)) / Math.pow(10, decimal_place)
    }

    const validateOrder = () => {

        if (selectedBillingAddress == null) {
            swal("Oops!", "Billing Address cannot be empty", "warning");
            return false
        }
        if (selectedShippingAddress == null) {
            swal("Oops!", "Shipping Address cannot be empty", "warning");
            return false
        }
        if (selectedGstin == null) {
            swal("Oops!", "Gstin cannot be empty", "warning");
            return false
        }
        if (selectedPlaceOfSupply == null) {
            swal("Oops!", "Place of Supply cannot be empty", "warning");
            return false
        }
        if (dueDate == "") {
            swal("Oops!", "Due Date cannot be empty", "warning");
            return false
        }
        if (selectedCurrency == null) {
            swal("Oops!", "Currency cannot be empty", "warning");
            return false
        }
        if (selectedWarehouse == null) {
            swal("Oops!", "Warehouse cannot be empty", "warning");
            return false
        }
        if ((selectedTaxCategory != null) && (selectedTax == null)) {
            swal("Oops!", "Select TDS or TCS tax", "warning");
            return false
        }

        let t = itemArray.find(o => {
            if (o.item == null) {
                return o
            }
        })
        if (t != undefined) {
            swal("Oops!", "Order item cannot be empty", "warning");
            return false
        }

        let t2 = itemArray.find(o => {
            if ((o.taxable == true) && (o.tax == null)) {
                return o
            }
        })
        if (t2 != undefined) {
            swal("Oops!", "Select a tax for the taxable item", "warning");
            return false
        }

        return true
    }


    const updateOrderSubmit = (val) => {
        var object = {}

        object.billing_address = selectedBillingAddress
        object.shipping_address = selectedShippingAddress
        object.gstin_number = selectedGstin.GstIn
        object.gstin = selectedGstin

        object.reference_number = referenceNumber.trim()

        object.place_of_supply = selectedPlaceOfSupply.value
        object.term = (selectedTerm == null) ? undefined : { term_name: selectedTerm.label, term_days: selectedTerm.value }
        object.due_date = dueDate

        let org_c_code = currencyList.find(x => x.currency_id == organizationDetails.currency).currency_code;
        // let dlr_c_code = JSON.parse(customerList.find(x => x.cid == selectedCustomer.value).currency).currency_code      
        // console.log(selectedCustomerFullDetails);
        let dlr_c_code = selectedCustomerFullDetails.currency.currency_code

        let org_c_rate = (org_c_code == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + org_c_code]
        let dlr_c_rate = (dlr_c_code == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + dlr_c_code]
        let ord_c_rate = (selectedCurrency.label == "USD") ? 1 : currencyExchangeRates.data.quotes["USD" + selectedCurrency.label]

        let exchange_rate = {
            organization: { base_curr: "USD", quote_curr: org_c_code, rate: org_c_rate },
            customer: { base_curr: "USD", quote_curr: dlr_c_code, rate: dlr_c_rate },
            order: { base_curr: "USD", quote_curr: selectedCurrency.label, rate: ord_c_rate },
        }

        let ic = currencyList.find(x => x.currency_id == selectedCurrency.value)
        object.order_currency = { currency_id: ic.currency_id, currency_symbol: ic.currency_symbol, currency_code: ic.currency_code, format: ic.format, decimal_place: ic.decimal_place, exchange_rate: exchange_rate }

        object.warehouse_id = selectedWarehouse.value
        object.subject = subject

        let temp = []
        itemArray.forEach((obj) => {
            let tobj = {}
            tobj.item_name = obj.item.label
            tobj.item_id = obj.item.value
            tobj.item_type = obj.item_type
            tobj.tax_code_type = obj.item_type == "Goods" ? "HSN" : "SAC"
            tobj.tax_code = obj.item_code
            tobj.quantity = obj.quantity
            tobj.quantity_unit = obj.quantity_unit
            tobj.rate = obj.rate
            tobj.discount_unit = obj.discount_unit
            tobj.discount_type = obj.discount_type.value
            tobj.taxable = obj.taxable ? 1 : 0
            tobj.tax_calculation_method = obj.tax_calculation_method
            tobj.tax_group_id = (obj.tax == null) ? null : obj.tax.value
            tobj.tax_group_name = (obj.tax == null) ? null : taxGroupList.find(x => x.tax_group_id == obj.tax.value).tax_group_name
            tobj.tax_group_percentage = (obj.tax == null) ? null : taxGroupList.find(x => x.tax_group_id == obj.tax.value).tax_group_percentage
            tobj.tax_group_details = (obj.tax == null) ? null : taxGroupList.find(x => x.tax_group_id == obj.tax.value).tax_group_details

            temp.push(tobj)
        })
        object.item_array = temp

        let t = { text: adjustment.text, value: adjustment.value }
        object.adjustment = t

        object.tax_category = selectedTaxCategory
        object.tax_id = selectedTax == null ? null : selectedTax.value
        object.tax_name = selectedTax == null ? null : taxList.find(x => x.tax_id == selectedTax.value).tax_name
        object.tax_percentage = selectedTax == null ? null : taxList.find(x => x.tax_id == selectedTax.value).tax_percentage

        object.grand_total = grandTotal
        object.customer_notes = customerNotes
        object.terms_and_conditions = termsAndConditions

        object.save_flag = val
        object.customer_order_id = props.match.params.id
        object.organization_id = organization_id
        object.page_name = page_name;

        // console.log(object);
        setSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-customer-order`, object, headers).then((res) => {
            setSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", `Order updated successfully!`, "success").then((value) => { history.push("/customer-order-list") })

            }
            else if (res.data.operation === "duplicate") {
                swal("Oops!", "This Reference Number already exists!!", "error");
            }
            else {
                swal("Oops!", res.data.message, "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const newTermsSubmit = () => {
        let f1 = true
        termsPanelList.forEach(obj => {
            if ((obj.term_name.trim() == "") || (obj.no_of_days == "")) {
                f1 = false
            }
        })
        if (f1 == false) {
            swal("Oops!", "Term Name or No of Days cannot be empty", "warning");
            return
        }

        let temp = termsPanelList.map(obj => { return obj.term_name.trim() })
        if (temp.length != [... new Set(temp)].length) {
            swal("Oops!", "Term Name cannot be a duplicate", "warning");
            return
        }

        var object = {};

        object.terms_panel_list = termsPanelList

        object.organization_id = organization_id
        object.page_name = page_name;

        setTermsSubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/update-invoice-terms`, object, headers).then((res) => {
            setTermsSubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Terms added successfully!", "success")
                handleNewTermsModalClose()
                getTerms()
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleNewTermsModalClose = () => {
        setTermsModalShow(false)
        setTermsPanelList([])
    }

    const newCurrencySubmit = () => {

        if (selectedCurrencyCode == null) {
            swal("Oops!", "Currency Code cannot be empty", "error");
            return
        }
        else if (currencySymbol == "") {
            swal("Oops!", "Currency Symbol cannot be empty", "error");
            return
        }

        var object = {};

        object.currency_code = selectedCurrencyCode.value
        object.currency_symbol = currencySymbol
        object.decimal_place = selectedCurrencyDecimalPlace == null ? null : selectedCurrencyDecimalPlace.value
        object.format = selectedCurrencyFormat == null ? null : selectedCurrencyFormat.value

        object.organization_id = organization_id;
        object.page_name = page_name;

        // console.log(object);
        setCurrencySubmitButtonState(true)

        var headers = {
            headers: {
                "Content-Type": "application/json",
                "Accept": "*/*",
                "token": getCookie("access_token")
            }
        }
        axios.post(`${process.env.REACT_APP_SERVER_URL}/add-currency`, object, headers).then((res) => {
            setCurrencySubmitButtonState(false)
            if (res.data.operation === "success") {
                swal("Great!", "Currency added successfully!", "success")
                handleNewCurrencyModalClose()
                getCurrencies()
            }
            else if (res.data.operation === "same_currency_code") {
                swal("Oops!", "This Currency code is already used!", "error");
            }
            else {
                swal("Oops!", "Something went wrong!", "error");
            }
        })
            .catch(function (error) {
                console.log(error)
            });
    }

    const handleNewCurrencyModalClose = () => {
        setCurrencyModalShow(false)
        setSelectedCurrencyCode(null)
        setCurrencySymbol("")
        setSelectedCurrencyDecimalPlace(null)
        setSelectedCurrencyFormat(null)
    }


    return (
        <>
            <div>
                <div className="d-flex flex-stack mb-5">
                    <h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">Edit Order Details</h1>
                </div>
                <div>
                    {
                        ((!approvalInfo || approvalInfo.type === "not_configure") && state === 1) &&
                        <>
                            <div className="notice d-flex bg-light-warning rounded border-warning border border-dashed  p-6">
                                <span className="svg-icon svg-icon-2tx svg-icon-warning me-4"><svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <rect opacity="0.3" x="2" y="2" width="20" height="20" rx="10" fill="currentColor"></rect>
                                    <rect x="11" y="14" width="7" height="2" rx="1" transform="rotate(-90 11 14)" fill="currentColor"></rect>
                                    <rect x="11" y="17" width="2" height="2" rx="1" transform="rotate(-90 11 17)" fill="currentColor"></rect>
                                </svg>
                                </span>
                                <div className="d-flex flex-stack flex-grow-1 ">
                                    <div className=" fw-semibold">
                                        <div className="fs-6 text-gray-700 ">Customer Order Approval Part Not Configured yet. Configure this, From <Link to={"/approvals"}>Approval Page</Link>.</div>
                                    </div>
                                </div>
                            </div><br />
                        </>
                    }
                    <div className="card">
                        <div className="card-body">

                            {
                                state == 1 ?
                                    <>
                                        <div className="row mb-sm-5 gx-0 ">
                                            <div className="col-md-4 px-1 mb-2">
                                                <label className="required fs-5 fw-bold mb-2">Customer Name</label>
                                                <Select
                                                    // options={customerList.map(x => { return { label: x.customer_display_name, value: x.cid } })}
                                                    // onChange={(val) => {
                                                    //     setSelectedCustomer(val);
                                                    //     setSelectedCustomerFullDetails(customerList.find(x => x.cid == val.value));
                                                    //     setSelectedCustomerEmail(customerList.find(x => x.cid == val.value).email); setSelectedBillingAddress(null); setSelectedShippingAddress(null); setSelectedGstin(null); setSelectedPlaceOfSupply(null);
                                                    // }}
                                                    value={selectedCustomer}
                                                    styles={theme === 'dark' ? dropdownStyleDark : {}}
                                                    // onInputChange={(val) => { (val.length > search_length) && getCustomers(val) }}
                                                    // onMenuClose={() => { getCustomers(); }}
                                                    // isLoading={CustomerApiState}
                                                    isDisabled
                                                />
                                            </div>
                                            <div className="col-md-4 px-1 mb-2">
                                                <label className="required fs-5 fw-bold mb-2">Branch:</label>
                                                <Select
                                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                    // options={branchList.map((x) => { return { label: x.branch_name, value: x.branch_id } })}
                                                    // onChange={(val) => {
                                                    //     swal("Alert!", "Please verify applicable taxes for selected items", "info")
                                                    //     setSelectedBranch(val);
                                                    //     setSelectedTransactionSeries(null)
                                                    // }}
                                                    value={selectedBranch}
                                                    isDisabled
                                                // placeholder="Select Branch"
                                                />
                                            </div>
                                            <div className="col-md-4 px-1 mb-2">
                                                <label className="required fs-5 fw-bold mb-2">Transaction Series:</label>
                                                <Select
                                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                    // options={selectedBranch != null && transactionSeriesList.filter(x => branchList.find(a => a.branch_id == selectedBranch.value).transaction_series_id.split(",").includes(x.transaction_series_id)).map(x => { return { label: x.series_name, value: x.transaction_series_id } })}
                                                    // onChange={async (val) => { await getSubSeriesData(val.value) && setSelectedTransactionSeries(val) }}
                                                    value={selectedTransactionSeries}
                                                    isDisabled
                                                // placeholder="Select Transaction Series"
                                                />
                                            </div>
                                        </div>

                                        <div className="row gx-0" style={{ transition: "all 1s ease-in-out", opacity: selectedCustomer != null ? "1" : "0", visibility: selectedCustomer != null ? "visible" : "hidden", height: selectedCustomer != null ? "auto" : "0", marginBottom: selectedCustomer != null ? "1rem" : "0" }} >
                                            <div className="col-md-6 mt-2 px-1">
                                                <div className="card p-4 flex-row shadow-sm rounded">
                                                    <div className="d-flex flex-column flex-grow-1">
                                                        <div className="d-flex align-items-center fs-5 fw-bold mb-3 text-primary">
                                                            Billing Address
                                                            <button onClick={(e) => { setAddressModalShow(true); setAddressType("billing") }} className="btn btn-sm btn-outline-primary ms-3">
                                                                <i className="fa-solid fa-pencil fs-5"></i>
                                                            </button>
                                                        </div>
                                                        {selectedBillingAddress != null ? (
                                                            <div className="bg-light p-3 rounded shadow-sm">
                                                                <div className="address-info mb-2">
                                                                    <span className="fw-semibold text-muted">Address 1: </span>
                                                                    <span className="fs-6 text-dark fw-bold">{selectedBillingAddress.address_line1 || "NA"}</span>
                                                                </div>
                                                                {selectedBillingAddress.address_line2 && (
                                                                    <div className="address-info mb-2">
                                                                        <span className="fw-semibold text-muted">Address 2: </span>
                                                                        <span className="fs-6 text-dark fw-bold">{selectedBillingAddress.address_line2}</span>
                                                                    </div>
                                                                )}
                                                                <div className="address-info mb-2">
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <span className="fw-semibold text-muted">City: </span>
                                                                            <span className="fs-6 text-dark fw-bold">{selectedBillingAddress.city}</span>
                                                                        </div>
                                                                        <div className="col">
                                                                            <span className="fw-semibold text-muted">Pincode: </span>
                                                                            <span className="fs-6 text-dark fw-bold">{selectedBillingAddress.pincode}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="address-info">
                                                                    <div className="row">
                                                                        <div className="col">
                                                                            <span className="fw-semibold text-muted">State: </span>
                                                                            <span className="fs-6 text-dark fw-bold">{selectedBillingAddress.state}</span>
                                                                        </div>
                                                                        <div className="col">
                                                                            <span className="fw-semibold text-muted">Country: </span>
                                                                            <span className="fs-6 text-dark fw-bold">{selectedBillingAddress.country}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="bg-light p-3 rounded shadow-sm text-muted">No Billing Address Added. Please select an address.</div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 mt-2 px-1">
                                                <div className="card p-4 flex-row shadow-sm rounded">
                                                    <div className="d-flex flex-column flex-grow-1">
                                                        <div className="d-flex align-items-center fs-5 fw-bold mb-3 text-primary">
                                                            Shipping Address
                                                            <button onClick={(e) => { setAddressModalShow(true); setAddressType("shipping") }} className="btn btn-sm btn-outline-primary ms-3">
                                                                <i className="fa-solid fa-pencil fs-5"></i>
                                                            </button>
                                                        </div>
                                                        {selectedShippingAddress != null ?
                                                            (
                                                                <div className="bg-light p-3 rounded shadow-sm">
                                                                    <div className="address-info mb-2">
                                                                        <span className="fw-semibold text-muted">Address 1: </span>
                                                                        <span className="fs-6 text-dark fw-bold">{selectedShippingAddress.address_line1 || "NA"}</span>
                                                                    </div>
                                                                    {selectedShippingAddress.address_line2 && (
                                                                        <div className="address-info mb-2">
                                                                            <span className="fw-semibold text-muted">Address 2: </span>
                                                                            <span className="fs-6 text-dark fw-bold">{selectedShippingAddress.address_line2}</span>
                                                                        </div>
                                                                    )}
                                                                    <div className="address-info mb-2">
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <span className="fw-semibold text-muted">City: </span>
                                                                                <span className="fs-6 text-dark fw-bold">{selectedShippingAddress.city}</span>
                                                                            </div>
                                                                            <div className="col">
                                                                                <span className="fw-semibold text-muted">Pincode: </span>
                                                                                <span className="fs-6 text-dark fw-bold">{selectedShippingAddress.pincode}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="address-info">
                                                                        <div className="row">
                                                                            <div className="col">
                                                                                <span className="fw-semibold text-muted">State: </span>
                                                                                <span className="fs-6 text-dark fw-bold">{selectedShippingAddress.state}</span>
                                                                            </div>
                                                                            <div className="col">
                                                                                <span className="fw-semibold text-muted">Country: </span>
                                                                                <span className="fs-6 text-dark fw-bold">{selectedShippingAddress.country}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            ) : (
                                                                <div className="bg-light p-3 rounded shadow-sm text-muted">No Shipping Address Added. Please select an address.</div>
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row gx-0" style={{ transition: "all 1s ease-in-out", opacity: selectedCustomer != null ? "1" : "0", visibility: selectedCustomer != null ? "visible" : "hidden", height: selectedCustomer != null ? "auto" : "0", marginBottom: selectedCustomer != null ? "1rem" : "0" }}>
                                            <div className="col-12">
                                                <div className="bg-light border border-primary d-flex flex-column w-100 p-5 rounded shadow-sm">
                                                    <h5 className="d-flex align-items-center mb-4">
                                                        <span className="badge bg-primary text-white mx-3">GST Details</span>
                                                        <span className="font-weight-semi-bold text-dark">{selectedGstin && customerGstinList.filter(customer => customer.GstIn !== "").length > 0 ? `GST Count: ${customerGstinList.filter(customer => customer.GstIn !== "").length}` : ""}</span>
                                                    </h5>
                                                    <div className="d-flex flex-wrap align-items-center gap-2">
                                                        {selectedGstin !== null ? (
                                                            <>
                                                                <div className="border border-gray-300 border-dashed rounded py-3 px-4 mx-3 flex-grow-1 bg-white shadow-sm">
                                                                    <span className="fs-6 text-dark fw-bold">{selectedGstin.gst_treatment}</span>
                                                                    <div className="fw-semibold text-muted">GST Treatment</div>
                                                                </div>
                                                                {(selectedGstin.gst_treatment !== "Unregistered Business" && selectedGstin.gst_treatment !== "Consumer") && (
                                                                    <div className="border border-gray-300 border-dashed rounded py-3 px-4 mx-3 flex-grow-1 bg-white shadow-sm">
                                                                        <span className="fs-6 text-dark fw-bold">{selectedGstin.GstIn}</span>
                                                                        <div className="fw-semibold text-muted">GSTIN</div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        ) : (
                                                            <div className="border border-gray-300 border-dashed rounded py-3 px-4 mx-3 flex-grow-1 bg-white shadow-sm">
                                                                <span className="fs-6 text-dark fw-bold">No GSTIN selected</span>
                                                            </div>
                                                        )}
                                                        <div>
                                                            <button onClick={(e) => { setGstInListModalShow(true); }} className="btn btn-sm btn-primary px-3">
                                                                <i className="fa-solid fa-pencil fs-5 text-white"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text-muted fs-7 mt-3">Set or add GST.</div>
                                            </div>
                                        </div>

                                        <div className="row mb-5">
                                            <div className="col-md-6">
                                                <label className="fs-5 fw-bold mb-2 required">Warehouse</label>
                                                <Select
                                                    options={warehouseList.map(x => { return { label: x.name, value: x.wid } })}
                                                    onChange={(val) => {
                                                        swal("Alert!", "Please verify applicable taxes for selected items", "info")
                                                        setSelectedWarehouse(val);
                                                        setSourceOfSupply(warehouseList.find(x => x.wid == val.value).state);
                                                    }}
                                                    value={selectedWarehouse}
                                                    placeholder="Select Warehouse..."
                                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="fs-5 fw-bold mb-2 required">Currency</label>
                                                <Select
                                                    options={currencyList.map(x => { return { label: x.currency_code, value: x.currency_id } })}
                                                    onChange={(val) => { setSelectedCurrency(val) }}
                                                    value={selectedCurrency}
                                                    components={{
                                                        MenuList: (props) => {
                                                            return (
                                                                <components.MenuList  {...props}>
                                                                    {props.children}
                                                                    <div className='d-flex justify-content-center'>
                                                                        <button className='btn btn-success rounded btn-sm my-1' onClick={() => { setCurrencyModalShow(true) }} >&#10011; Add New Currency</button>
                                                                    </div>
                                                                </components.MenuList >
                                                            )
                                                        }
                                                    }}
                                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-5">
                                            <div className="col-md-6 mb-2">
                                                <label className="fs-5 fw-bold mb-2">Reference Number</label>
                                                <input type="text" className="form-control form-control-solid" value={referenceNumber}
                                                    onChange={(e) => { setReferenceNumber(e.target.value) }}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="fs-5 fw-bold mb-2">Order Number</label>
                                                <input type="text" className="form-control form-control-solid" value={orderNumber} readOnly disabled />
                                            </div>
                                        </div>

                                        <div className="row mb-5">
                                            <div className="col-md-6">
                                                <label className="required fs-5 fw-bold mb-2">Place Of Supply</label>
                                                <Select
                                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                    options={SupplyStateOptions}
                                                    onChange={(val) => {
                                                        swal("Alert!", "Please verify applicable taxes for selected items", "info")
                                                        setSelectedPlaceOfSupply(val)
                                                    }}
                                                    value={selectedPlaceOfSupply}
                                                    isClearable
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="required fs-5 fw-bold mb-2">Order Date</label>
                                                <input type="date" className="form-control form-control-solid" max={moment.utc().format("YYYY-MM-DD")}
                                                    value={orderDate}
                                                    disabled readOnly />
                                            </div>
                                        </div>

                                        <div className="row mb-5">
                                            <div className="col-md-6 mb-2">
                                                <label className="fs-5 fw-bold mb-2">Terms</label>
                                                <Select
                                                    options={termsList.map(x => { return { label: x.term_name, value: x.no_of_days } })}
                                                    onChange={(val) => { setSelectedTerm(val); setDueDate(moment.utc(orderDate).add(val.value, "days").format('YYYY-MM-DD')); }}
                                                    value={selectedTerm}
                                                    components={{
                                                        MenuList: (props) => {
                                                            return (
                                                                <components.MenuList  {...props}>
                                                                    {props.children}
                                                                    <div className='d-flex justify-content-center'>
                                                                        <button className='btn btn-success rounded btn-sm my-1'
                                                                            onClick={() => {
                                                                                setTermsModalShow(true)
                                                                                setTermsPanelList(termsList.map(x => { return { ...x } }))
                                                                            }}
                                                                        >&#10011; Configure Terms</button>
                                                                    </div>
                                                                </components.MenuList >
                                                            )
                                                        }
                                                    }}
                                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="fs-5 fw-bold mb-2 required">Due Date</label>
                                                <input type="date" className="form-control form-control-solid" max={moment.utc().format("YYYY-MM-DD")}
                                                    value={dueDate}
                                                    onChange={(e) => { setDueDate(e.target.value); setSelectedTerm(null); }} />
                                            </div>
                                        </div>

                                        <div className="row mb-5">
                                            <div className="col-md-6 mb-2">
                                                <label className="fs-5 fw-bold mb-2">Select Additional User</label>
                                                <Select
                                                    options={userList.map(x => { return { label: x.name + " - " + x.user_id, value: x.user_id } })}
                                                    onChange={(val) => { setSelectedUser(val) }}
                                                    value={selectedUser.value ? selectedUser : { label: "No additional User", value: "No additional User" }}
                                                    placeholder="Select additional User..."
                                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                    isDisabled
                                                />
                                            </div>
                                        </div>

                                        <div className="row mb-5">
                                            <div className="col-md-12">
                                                <label className="fs-5 fw-bold mb-2">Subject</label>
                                                <textarea className="form-control form-control-solid fs-6" rows="3" value={subject} onChange={(e) => { setSubject(e.target.value) }} placeholder={"Let your customer know what this Invoice is for"} ></textarea>
                                            </div>
                                        </div>
                                        <hr />

                                        <div style={{ overflowX: "auto" }}>
                                            <div style={{ minWidth: "100rem" }}>
                                                <div className="row mb-5 gx-0">
                                                    <div className="col-2"><p className="fs-5 text-center">Item</p></div>
                                                    <div className="col"><p className="fs-5 text-center">Rate</p></div>
                                                    <div className="col"><p className="fs-5 text-center">Quantity</p></div>
                                                    <div className="col"><p className="fs-5 text-center">Total Amt. Excl.</p></div>
                                                    <div className="col-2"><p className="fs-5 text-center">Total Discount</p></div>
                                                    <div className="col-2"><p className="fs-5 text-center">Tax (%)</p></div>
                                                    <div className="col-1"><p className="fs-5 text-center">Tax amount</p></div>
                                                    <div className="col"><p className="fs-5 text-center">Line Total</p></div>
                                                    <div className="col"></div>
                                                </div>
                                                {
                                                    itemArray.map((obj, indx, arr) => {
                                                        return (
                                                            <div key={indx} className="row mb-5 gx-0 align-items-baseline">
                                                                <div className="col-2 px-0">
                                                                    <Select
                                                                        options={itemList.map(x => { return { label: x.product_name, value: x.pid } })}
                                                                        onChange={(val) => {
                                                                            if (itemArray.filter(x => ((x.item !== null) && (x.item.value == val.value))).length > 0) {
                                                                                swal("Oops!", "This product already selected.", "warning");
                                                                                return
                                                                            }

                                                                            let pr_d = itemList.find(x => x.pid == val.value)

                                                                            let pr_ac = (pr_d.product_details.length > 0 && pr_d.product_details.find(x => selectedBranch.value == x.branch_id && selectedWarehouse.value == x.warehouse_id) !== undefined) ? pr_d.product_details.find(x => selectedBranch.value == x.branch_id && selectedWarehouse.value == x.warehouse_id).available_count : 0;
                                                                            if (negativeStock === false && pr_ac <= 0) {
                                                                                swal("Warning", "No stock available for this product!", "warning");
                                                                                return
                                                                            }

                                                                            let temp = itemArray.map(a => { return { ...a } });
                                                                            temp[indx].item = val;

                                                                            temp[indx].item_data = pr_d

                                                                            temp[indx].quantity_max = pr_ac;

                                                                            temp[indx].item_type = pr_d.product_type
                                                                            let ic = pr_d.product_type == "Goods" ? pr_d.hsn_code : pr_d.sac_code
                                                                            temp[indx].item_code = ic ? parseInt(ic) : null
                                                                            temp[indx].tax_calculation_method = pr_d.tax_information[0].tax_calculation_method
                                                                            temp[indx].quantity_unit = pr_d.usage_unit

                                                                            let g_rate = pr_d.price_information === null ? 0 : trimDecimalPlaces((parseFloat((pr_d.price_information.type === "Customer" ? pr_d.price_information.customer_invoice_price : 0))), selectedCurrency == null ? 3 : currencyList.find(x => x.currency_id == selectedCurrency.value).decimal_place);
                                                                            temp[indx].pre_defined_rate = g_rate

                                                                            if (pr_d.tax_information[0].tax_preference == "Taxable") {
                                                                                temp[indx].taxable = true
                                                                                temp[indx].tax = null
                                                                            }
                                                                            else {
                                                                                temp[indx].taxable = false
                                                                                temp[indx].tax = null
                                                                            }

                                                                            if ((pr_d.tax_information[0].tax_preference == "Taxable") && (sourceOfSupply != null) && (selectedPlaceOfSupply != null)) {
                                                                                let applied_tax

                                                                                let tp = pr_d.tax_information[0].tax_details
                                                                                if (tp.Inter_State_Tax && tp.Inter_State_Tax_Name && tp.Inter_State_Tax_Percentage && tp.Intra_State_Tax && tp.Intra_State_Tax_Name && tp.Intra_State_Tax_Percentage) {
                                                                                    if (sourceOfSupply != selectedPlaceOfSupply.value) {
                                                                                        applied_tax = tp.Inter_State_Tax_Percentage
                                                                                        temp[indx].tax = { label: `(${tp.Inter_State_Tax_Percentage}%) ${tp.Inter_State_Tax_Name}`, value: tp.Inter_State_Tax }
                                                                                    }
                                                                                    else {
                                                                                        applied_tax = tp.Intra_State_Tax_Percentage
                                                                                        temp[indx].tax = { label: `(${tp.Intra_State_Tax_Percentage}%) ${tp.Intra_State_Tax_Name}`, value: tp.Intra_State_Tax }
                                                                                    }
                                                                                }

                                                                                if (pr_d.tax_information[0].tax_calculation_method === "TAX_Inclusive") {
                                                                                    temp[indx].rate = g_rate / (1 + (applied_tax / 100))
                                                                                }
                                                                                else {
                                                                                    temp[indx].rate = g_rate
                                                                                }
                                                                            }
                                                                            else {
                                                                                temp[indx].rate = g_rate
                                                                            }

                                                                            setItemArray(temp)
                                                                        }}
                                                                        onInputChange={(val) => { (val.length > search_length) && getItems(val); }}
                                                                        onMenuClose={() => { getItems(null); }}
                                                                        isLoading={itemApiState}
                                                                        value={itemArray[indx].item}
                                                                        menuPosition='fixed'
                                                                        // menuShouldBlockScroll={true}
                                                                        components={{
                                                                            MenuList: (props) => {
                                                                                return (
                                                                                    <components.MenuList  {...props}>
                                                                                        {props.children}
                                                                                        <div className='d-flex justify-content-center'>
                                                                                            <button className='btn btn-success rounded btn-sm my-1' onClick={() => { setItemModalShow(true) }} >&#10011; Add New Item</button>
                                                                                        </div>
                                                                                    </components.MenuList >
                                                                                )
                                                                            }
                                                                        }}
                                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                    />
                                                                    {
                                                                        itemArray[indx].item != null &&
                                                                        <div className='mt-1'>
                                                                            <div style={{ position: "relative", display: "inline-block" }} >
                                                                                <button className='btn btn-link p-0'
                                                                                    onClick={() => {
                                                                                        let temp = itemArray.map(a => { return { ...a } })
                                                                                        temp[indx].item_info_popover = true
                                                                                        setItemArray(temp)
                                                                                    }}
                                                                                    onBlur={() => {
                                                                                        let temp = itemArray.map(a => { return { ...a } })
                                                                                        temp[indx].item_info_popover = false
                                                                                        setItemArray(temp)
                                                                                    }}
                                                                                ><i className="fa fa-info-circle mx-1 text-hover-primary" style={{ cursor: "pointer" }} aria-hidden="true" ></i></button>
                                                                                <div style={{ position: "absolute", top: "-60px", left: "0px", boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)", backgroundColor: "#84CCCD", padding: "1rem", width: "20rem", borderRadius: "0.5rem", transition: "all 0.25s ease-in-out", zIndex: 1, opacity: itemArray[indx].item_info_popover ? "1" : "0", visibility: itemArray[indx].item_info_popover ? "visible" : "hidden" }}>
                                                                                    <div>
                                                                                        {
                                                                                            !obj.item_data.product_details.find(x => ((x.branch_id === selectedBranch.value) && (x.warehouse_id === selectedWarehouse.value))) ? <p>This product does not have any stock in selected Warehouse</p> :
                                                                                                <>
                                                                                                    <div><span className="bullet bg-info me-3"></span><span>Available stock: {obj.item_data.product_details.find(x => ((x.branch_id === selectedBranch.value) && (x.warehouse_id === selectedWarehouse.value))).available_count}</span></div>
                                                                                                    <div><span className="bullet bg-info me-3"></span><span>Committed Stock: {obj.item_data.product_details.find(x => ((x.branch_id === selectedBranch.value) && (x.warehouse_id === selectedWarehouse.value))).committed_count}</span></div>
                                                                                                </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            {
                                                                                itemArray[indx].item_type == "Goods" ?
                                                                                    <>
                                                                                        <span className="badge badge-primary me-1">Goods</span>
                                                                                        <span className='text-info'>HSN code: </span>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <span className="badge badge-success me-1">Service</span>
                                                                                        <span className='text-info'>SAC code: </span>
                                                                                    </>
                                                                            }

                                                                            <div style={{ position: "relative", display: "inline-block" }} >
                                                                                <span className='text-hover-primary' style={{ cursor: "pointer" }}
                                                                                    onClick={() => {
                                                                                        let temp = itemArray.map(a => { return { ...a } })
                                                                                        temp[indx].item_popover = true
                                                                                        setItemArray(temp)
                                                                                    }}
                                                                                >{itemArray[indx].item_code == "" ? "Update " : itemArray[indx].item_code}<i className="fa fa-pencil ms-1" aria-hidden="true"></i>
                                                                                </span>
                                                                                <div style={{ position: "absolute", top: "-90px", left: "80px", boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)", backgroundColor: "#84CCCD", padding: "1rem", width: "20rem", borderRadius: "0.5rem", transition: "all 0.25s ease-in-out", zIndex: 1, opacity: itemArray[indx].item_popover ? "1" : "0", visibility: itemArray[indx].item_popover ? "visible" : "hidden" }}>
                                                                                    <p className='mb-1'>{itemArray[indx].item_type == "Goods" ? "HSN Code" : "SAC Code"}</p>
                                                                                    <div className='d-flex gap-1 mb-1'>
                                                                                        <input type="text" className="form-control form-control-solid px-2" value={itemArray[indx].item_popover_input}
                                                                                            onChange={(e) => {
                                                                                                let temp = itemArray.map(a => { return { ...a } })
                                                                                                temp[indx].item_popover_input = e.target.value
                                                                                                setItemArray(temp)
                                                                                            }}
                                                                                        />
                                                                                        <button className='btn btn-sm btn-secondary px-3' onClick={() => { setProductCodeModalShow(true); setProductCodeItemId(itemArray[indx].item.value); setProductCodeItemType(itemArray[indx].item_type); }}><i className="fa fa-search p-0" aria-hidden="true"></i></button>
                                                                                    </div>
                                                                                    <div className='d-flex gap-1 mb-1'>
                                                                                        <button className='btn btn-sm btn-success px-2 py-1'
                                                                                            onClick={async () => {
                                                                                                let temp = itemArray.map(a => { return { ...a } })
                                                                                                await updateProductCode(temp[indx].item.value, temp[indx].item_type, temp[indx].item_popover_input)
                                                                                                temp[indx].item_code = temp[indx].item_popover_input
                                                                                                temp[indx].item_popover = false
                                                                                                setItemArray(temp)
                                                                                            }}
                                                                                        >Save</button>
                                                                                        <button className='btn btn-sm btn-secondary px-2 py-1'
                                                                                            onClick={() => {
                                                                                                let temp = itemArray.map(a => { return { ...a } })
                                                                                                temp[indx].item_popover = false
                                                                                                setItemArray(temp)
                                                                                            }}
                                                                                        >Close</button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="col px-1">
                                                                    <input type="number" className="form-control form-control-solid px-2" value={trimDecimalPlaces(itemArray[indx].rate, selectedCurrency == null ? 3 : currencyList.find(x => x.currency_id == selectedCurrency.value).decimal_place).toString()}
                                                                        onChange={(e) => {
                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                            temp[indx].rate = e.target.value == "" ? 0 : parseFloat(e.target.value)
                                                                            temp[indx].pre_defined_rate = e.target.value == "" ? 0 : parseFloat(e.target.value)
                                                                            setItemArray(temp)
                                                                        }}
                                                                        onBlur={(e) => {
                                                                            if (itemArray[indx].tax_calculation_method == "TAX_Inclusive") {
                                                                                let temp = itemArray.map(a => { return { ...a } });
                                                                                if (temp[indx].tax != null) {
                                                                                    temp[indx].rate = temp[indx].pre_defined_rate == "" ? 0 : parseFloat(temp[indx].pre_defined_rate) / (1 + (taxGroupList.find(x => x.tax_group_id == temp[indx].tax.value).tax_group_percentage / 100))
                                                                                }
                                                                                setItemArray(temp)
                                                                            }
                                                                        }}
                                                                    />
                                                                    {
                                                                        itemArray[indx].item != null &&
                                                                        <div className='mt-1'>
                                                                            <div style={{ position: "relative", display: "inline-block" }} >
                                                                                <button className='btn btn-link p-0'
                                                                                    onClick={() => {
                                                                                        let temp = itemArray.map(a => { return { ...a } })
                                                                                        temp[indx].rate_popover = true
                                                                                        setItemArray(temp)
                                                                                    }}
                                                                                    onBlur={() => {
                                                                                        let temp = itemArray.map(a => { return { ...a } })
                                                                                        temp[indx].rate_popover = false
                                                                                        setItemArray(temp)
                                                                                    }}
                                                                                >Update<i className="fa fa-pencil ms-1" aria-hidden="true"></i>
                                                                                </button>
                                                                                <div style={{ position: "absolute", top: "-60px", left: "65px", boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)", backgroundColor: "#84CCCD", padding: "1rem", width: "10rem", borderRadius: "0.5rem", transition: "all 0.25s ease-in-out", zIndex: 1, opacity: itemArray[indx].rate_popover ? "1" : "0", visibility: itemArray[indx].rate_popover ? "visible" : "hidden" }}>
                                                                                    <div className={`p-1 rounded cursor-pointer ${itemArray[indx].tax_calculation_method == "TAX_Inclusive" && "bg-primary"}`}
                                                                                        onClick={() => {
                                                                                            let temp = itemArray.map(a => { return { ...a } });
                                                                                            temp[indx].tax_calculation_method = "TAX_Inclusive"
                                                                                            if (temp[indx].tax != null) {
                                                                                                temp[indx].rate = temp[indx].pre_defined_rate / (1 + (taxGroupList.find(x => x.tax_group_id == temp[indx].tax.value).tax_group_percentage / 100))
                                                                                            }
                                                                                            setItemArray(temp)
                                                                                        }}
                                                                                    >Tax Inclusive
                                                                                    </div>
                                                                                    <div className={`p-1 rounded cursor-pointer ${itemArray[indx].tax_calculation_method == "TAX_Exclusive" && "bg-primary"}`}
                                                                                        onClick={() => {
                                                                                            let temp = itemArray.map(a => { return { ...a } });
                                                                                            temp[indx].tax_calculation_method = "TAX_Exclusive"
                                                                                            if (temp[indx].tax != null) {
                                                                                                temp[indx].rate = temp[indx].pre_defined_rate
                                                                                            }
                                                                                            setItemArray(temp)
                                                                                        }}
                                                                                    >Tax Exclusive
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                <div className="col px-1">
                                                                    <input type="number" className="form-control form-control-solid px-2" value={itemArray[indx].quantity.toString()}
                                                                        onChange={(e) => {
                                                                            if (parseFloat(e.target.value) < 0) {
                                                                                return
                                                                            }

                                                                            let temp = itemArray.map(a => { return { ...a } })

                                                                            if (negativeStock === false && parseFloat(e.target.value) > temp[indx].quantity_max) {
                                                                                swal("Maximum quantity reached!", "Negative Stock Not Allowed", "warning");
                                                                                return
                                                                            }

                                                                            temp[indx].quantity = e.target.value == "" ? 0 : parseFloat(e.target.value)
                                                                            setItemArray(temp)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className="col px-1">
                                                                    <input type="number" className="form-control form-control-solid px-2" readOnly value={trimDecimalPlaces(((itemArray[indx].quantity * itemArray[indx].rate) - (itemArray[indx].discount_type.value == "amount" ? itemArray[indx].discount_unit : itemArray[indx].quantity * itemArray[indx].rate * itemArray[indx].discount_unit / 100)), selectedCurrency == null ? 3 : currencyList.find(x => x.currency_id == selectedCurrency.value).decimal_place)} />
                                                                </div>
                                                                <div className='col pe-0 ps-1'>
                                                                    <input type="number" className="form-control form-control-solid px-2" value={itemArray[indx].discount_unit.toString()}
                                                                        onChange={(e) => {
                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                            temp[indx].discount_unit = e.target.value == "" ? 0 : parseFloat(e.target.value)
                                                                            setItemArray(temp)
                                                                        }}
                                                                    />
                                                                </div>
                                                                <div className='col ps-0 pe-1'>
                                                                    <Select
                                                                        options={[{ label: "amount", value: "amount" }, { label: "%", value: "percentage" }]}
                                                                        onChange={(val) => {
                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                            temp[indx].discount_type = val
                                                                            setItemArray(temp)
                                                                        }}
                                                                        value={itemArray[indx].discount_type}
                                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                    />
                                                                </div>
                                                                <div className='col-2 px-0'>
                                                                    <Select
                                                                        options={taxGroupList.filter(x => {
                                                                            if ((sourceOfSupply == null) || (selectedPlaceOfSupply == null)) {
                                                                                return true
                                                                            }
                                                                            else {
                                                                                if (sourceOfSupply != selectedPlaceOfSupply.value) {
                                                                                    return x.tax_group_type == "INTERSTATE"
                                                                                }
                                                                                else {
                                                                                    return x.tax_group_type != "INTERSTATE"
                                                                                }
                                                                            }
                                                                        }).map(x => { return { label: `(${x.tax_group_percentage}%) ${x.tax_group_name}`, value: x.tax_group_id } })}
                                                                        onChange={(val) => {
                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                            temp[indx].tax = val
                                                                            if (val != null) {
                                                                                if (temp[indx].tax_calculation_method == "TAX_Inclusive") {
                                                                                    temp[indx].rate = temp[indx].pre_defined_rate / (1 + (taxGroupList.find(x => x.tax_group_id == val.value).tax_group_percentage / 100))
                                                                                }
                                                                                else if (temp[indx].tax_calculation_method == "TAX_Exclusive") {
                                                                                    temp[indx].rate = temp[indx].pre_defined_rate
                                                                                }
                                                                            }

                                                                            setItemArray(temp)
                                                                        }}
                                                                        isClearable
                                                                        value={itemArray[indx].tax}
                                                                        isDisabled={!obj.taxable}
                                                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                                        menuPosition={'fixed'}
                                                                        menuShouldBlockScroll={true}
                                                                    />
                                                                </div>
                                                                <div className="col px-1">
                                                                    <input type="number" className="form-control form-control-solid px-2" readOnly value={trimDecimalPlaces((itemArray[indx].tax == null ? 0 : ((itemArray[indx].quantity * itemArray[indx].rate) - (itemArray[indx].discount_type.value == "amount" ? itemArray[indx].discount_unit : itemArray[indx].quantity * itemArray[indx].rate * itemArray[indx].discount_unit / 100)) * (taxGroupList.find(x => x.tax_group_id == itemArray[indx].tax.value).tax_group_percentage / 100)), selectedCurrency == null ? 3 : currencyList.find(x => x.currency_id == selectedCurrency.value).decimal_place)} />
                                                                </div>
                                                                <div className="col px-1">
                                                                    <input type="number" className="form-control form-control-solid px-2" readOnly value={trimDecimalPlaces((((itemArray[indx].quantity * itemArray[indx].rate) - (itemArray[indx].discount_type.value == "amount" ? itemArray[indx].discount_unit : itemArray[indx].quantity * itemArray[indx].rate * itemArray[indx].discount_unit / 100)) + (itemArray[indx].tax == null ? 0 : ((itemArray[indx].quantity * itemArray[indx].rate) - (itemArray[indx].discount_type.value == "amount" ? itemArray[indx].discount_unit : itemArray[indx].quantity * itemArray[indx].rate * itemArray[indx].discount_unit / 100)) * (taxGroupList.find(x => x.tax_group_id == itemArray[indx].tax.value).tax_group_percentage / 100))), selectedCurrency == null ? 3 : currencyList.find(x => x.currency_id == selectedCurrency.value).decimal_place)} />
                                                                </div>
                                                                {
                                                                    arr.length > 1 ?
                                                                        <div className="col position-relative">
                                                                            <button className='btn btn-sm btn-outline-info rounded-pill border border-info fw-bolder'
                                                                                onClick={() => {
                                                                                    let temp = itemArray.map(a => { return { ...a } })
                                                                                    temp[indx].op_toggle = !temp[indx].op_toggle
                                                                                    setItemArray(temp)
                                                                                }}
                                                                            >&#x2630;</button>
                                                                            {
                                                                                itemArray[indx].op_toggle &&
                                                                                <div className='position-absolute' style={{ top: "-40px", left: "-30px", width: "max-content" }}>
                                                                                    <button className='btn btn-sm btn-secondary fw-bolder border border-info fs-5' style={{ borderRadius: "50% 0 0 50%", padding: "6px 15px" }}
                                                                                        onClick={() => {
                                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                                            temp.splice(indx, 1)
                                                                                            setItemArray(temp)
                                                                                        }}
                                                                                    >&#10006;</button>
                                                                                    <button className='btn btn-sm btn-secondary fw-bolder border-top border-bottom border-info fs-3 rounded-0' style={{ padding: "4px 10px" }}
                                                                                        onClick={() => {
                                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                                            temp[indx].op_toggle = false
                                                                                            temp.splice(indx, 0, temp[indx])
                                                                                            setItemArray(temp)
                                                                                        }}
                                                                                    >&#10063;
                                                                                    </button>
                                                                                    <button className='btn btn-sm btn-secondary fw-bolder border border-info fs-5' style={{ borderRadius: "0 50% 50% 0", padding: "6px 15px" }}
                                                                                        onClick={() => {
                                                                                            let temp = itemArray.map(a => { return { ...a } })
                                                                                            if (indx == 0) {
                                                                                                temp[indx].op_toggle = false
                                                                                            }
                                                                                            else {
                                                                                                let t = itemArray[indx]
                                                                                                temp.splice(indx, 1)
                                                                                                temp.splice(indx - 1, 0, t)
                                                                                            }
                                                                                            setItemArray(temp)
                                                                                        }}
                                                                                    >
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" version="1.0" width="12" height="12" viewBox="0 0 512.000000 512.000000">
                                                                                            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                                                                                                <path d="M2475 5090 c-82 -9 -181 -44 -253 -88 -73 -44 -2015 -1625 -2063 -1679 -54 -60 -99 -137 -127 -215 -34 -96 -42 -271 -18 -368 51 -204 209 -377 408 -447 106 -38 264 -44 371 -14 43 12 104 34 135 50 63 32 76 42 977 776 351 286 643 520 649 519 6 -1 342 -270 746 -600 840 -684 838 -683 915 -718 123 -56 276 -73 411 -46 221 44 421 238 479 465 19 76 19 231 0 314 -21 87 -84 204 -147 273 -51 54 -1958 1613 -2049 1675 -122 81 -284 120 -434 103z" />
                                                                                                <path d="M2480 2870 c-64 -7 -165 -37 -225 -67 -52 -26 -115 -77 -1108 -886 -671 -547 -981 -806 -1012 -844 -54 -70 -101 -165 -120 -246 -19 -81 -19 -237 0 -312 56 -221 245 -408 465 -459 151 -36 329 -12 463 62 40 22 377 290 838 666 l770 628 42 -33 c23 -18 368 -299 767 -624 399 -325 745 -604 770 -621 101 -69 222 -106 355 -107 113 -1 177 14 285 65 160 76 290 237 335 413 18 69 20 221 4 297 -19 91 -91 227 -156 294 -56 57 -1935 1592 -2038 1665 -122 86 -277 125 -435 109z" />
                                                                                            </g>
                                                                                        </svg>
                                                                                    </button>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        : <div className="col" style={{ visibility: "hidden" }}></div>
                                                                }
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className="row mb-5">
                                                    <div className="col-3">
                                                        <button className='btn btn-primary rounded fs-7' onClick={() => { setItemArray([...itemArray, { item: null, item_data: null, item_info_popover: false, item_type: "", item_code: "", item_popover: false, item_popover_input: "", quantity: 1, quantity_unit: null, quantity_max: 0, pre_defined_rate: 0, rate: 0, rate_popover: false, discount_unit: 0, discount_type: { label: "amount", value: "amount" }, tax: null, taxable: false, tax_calculation_method: null, op_toggle: false }]) }}>&#10011; Add a row</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>
                                            <div className="col-md-3"></div>
                                            <div className='col-md-3 d-flex align-items-center'>
                                                <p className='m-0 fs-5'>Sub Total</p>
                                            </div>
                                            <div className="col-md-3"></div>
                                            <div className='col-md-3 my-2'>
                                                <input type="text" className="form-control form-control-solid" value={subTotal} readOnly />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-md-4 d-flex align-items-center justify-content-md-end'>
                                                <p className='m-0 fs-5'>Tax added</p>
                                            </div>
                                            <div className="col-md-2 d-flex align-items-center">
                                                <div className={`rounded mx-2 px-3 py-2 ${selectedTaxCategory == "TDS" && "bg-primary"}`} style={{ cursor: "pointer" }} onClick={() => { setSelectedTaxCategory("TDS"); setSelectedTax(null); }}>TDS</div>
                                                <div className={`rounded mx-2 px-3 py-2 ${selectedTaxCategory == "TCS" && "bg-primary"}`} style={{ cursor: "pointer" }} onClick={() => { setSelectedTaxCategory("TCS"); setSelectedTax(null); }}>TCS</div>
                                                {selectedTaxCategory != null && <div className='text-hover-primary' style={{ cursor: "pointer" }} onClick={() => { setSelectedTaxCategory(null); setSelectedTax(null); }}>&#10006;</div>}
                                            </div>
                                            <div className='col-md-3 my-2'>
                                                <Select
                                                    options={taxList.filter(x => x.category == selectedTaxCategory).map(x => { return { label: `(${x.tax_percentage}%) ${x.tax_name}`, value: x.tax_id } })}
                                                    onChange={(val) => { setSelectedTax(val) }}
                                                    isClearable
                                                    value={selectedTax}
                                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                                    isDisabled={selectedTaxCategory == null}
                                                />
                                            </div>
                                            <div className='col-md-3 my-2'>
                                                <input type="text" className="form-control form-control-solid" value={trimDecimalPlaces(((selectedTaxCategory == "TDS" ? itemArray.reduce((p, o) => { return p + ((o.quantity * o.rate) - (o.discount_type.value == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) }, 0) : subTotal) * (selectedTax == null ? 0 : parseFloat(taxList.find(x => x.tax_id == selectedTax.value).tax_percentage)) / 100), selectedCurrency == null ? 3 : currencyList.find(x => x.currency_id == selectedCurrency.value).decimal_place)} readOnly />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-3"></div>
                                            <div className="col-md-3 my-2">
                                                <input type="text" className="form-control form-control-solid" value={adjustment.text} onChange={(e) => { setAdjustment({ ...adjustment, text: e.target.value }) }} />
                                            </div>
                                            <div className="col-md-3"></div>
                                            <div className='col-md-3 my-2'>
                                                <input type="number" className="form-control form-control-solid" value={adjustment.display.toString()}
                                                    onChange={(e) => { setAdjustment({ ...adjustment, display: e.target.value, value: e.target.value == "" ? 0 : parseFloat(e.target.value) }) }}
                                                    onFocus={(e) => { if (adjustment.display === "0") { setAdjustment({ ...adjustment, display: "" }) } }}
                                                    onBlur={(e) => { if (adjustment.display === "") { setAdjustment({ ...adjustment, display: "0" }) } }}
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-md-3"></div>
                                            <div className='col-md-3 mt-4 d-flex align-items-center'>
                                                <h4 className='m-0'>Grand Total</h4>
                                            </div>
                                            <div className="col-md-3"></div>
                                            <div className='col-md-3 mt-4 d-flex align-items-center'>
                                                <h3>{currencyList.length > 0 && selectedCurrency != null && currencyList.find(x => x.currency_id == selectedCurrency.value).currency_symbol} {grandTotal}</h3>
                                            </div>
                                        </div>
                                        <hr />

                                        <div className="row mb-5">
                                            <div className="col-md-6 my-2">
                                                <label className="fs-5 fw-bold mb-2">Customer Notes</label>
                                                <textarea className="form-control form-control-solid" rows="3" value={customerNotes} onChange={(e) => { setCustomerNotes(e.target.value) }} placeholder={"Looking forward for your business."}></textarea>
                                            </div>
                                            <div className="col-md-6 my-2">
                                                <label className="fs-5 fw-bold mb-2">Terms and Conditions</label>
                                                <textarea className="form-control form-control-solid" rows="3" value={termsAndConditions} onChange={(e) => { setTermsAndConditions(e.target.value) }} placeholder={"Enter the terms and conditions of your business to be displayed in your transaction"}></textarea>
                                            </div>
                                        </div>

                                        {
                                            write &&
                                            <button type="button" className="btn btn-lg btn-primary mt-15 mx-3 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
                                                onClick={() => {
                                                    if (customerOrderDetails.add_status == "CUSTOMER_ORDER_FINALIZED") {
                                                        swal("Alert!", "This Order is alredy Approved, editing this will reset the status, Are you sure?", "info").then(() => {
                                                            validateOrder() && updateOrderSubmit(false)
                                                        })
                                                    }
                                                    else {
                                                        validateOrder() && updateOrderSubmit(false)
                                                    }
                                                }}>
                                                <span className="indicator-label">Save as Draft</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        }

                                        {
                                            write &&
                                            <button type="button" className="btn btn-lg btn-info mt-15 mx-3 rounded" data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
                                                onClick={() => {
                                                    if (customerOrderDetails.add_status == "CUSTOMER_ORDER_FINALIZED") {
                                                        swal("Alert!", "This Order is alredy Approved, editing this will reset the status, Are you sure?", "info").then(() => {
                                                            validateOrder() && updateOrderSubmit(true)
                                                        })
                                                    }
                                                    else {
                                                        validateOrder() && updateOrderSubmit(true)
                                                    }
                                                }}>
                                                <span className="indicator-label">Save</span>
                                                <span className="indicator-progress">Please wait...
                                                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                                                </span>
                                            </button>
                                        }
                                    </>
                                    :
                                    state == 2 ?
                                        <img src="/images/loader-06.svg" alt="" style={loaderstyle} />
                                        :
                                        <PageError>
                                            <Link className='btn btn-light-success rounded' to={`/dashboard`}>Go to Dashboard</Link>
                                        </PageError>
                            }
                        </div>

                    </div>
                </div>
            </div>

            <Modal show={gstInListModalShow} onHide={() => { setGstInListModalShow(false) }} centered>
                <Modal.Header>
                    <Modal.Title className='py-2'>GST List</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setGstInListModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body style={{ backgroundColor: "#f4f6f9" }}>
                    <div className='container'>
                        {
                            customerGstinList.length === 0 ?
                                <div className="overflow-auto pb-5">
                                    <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed min-w-lg-600px flex-shrink-0 p-6">
                                        <span className="svg-icon svg-icon-2tx svg-icon-primary me-4">
                                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path opacity="0.3" d="M19.0687 17.9688H11.0687C10.4687 17.9688 10.0687 18.3687 10.0687 18.9688V19.9688C10.0687 20.5687 10.4687 20.9688 11.0687 20.9688H19.0687C19.6687 20.9688 20.0687 20.5687 20.0687 19.9688V18.9688C20.0687 18.3687 19.6687 17.9688 19.0687 17.9688Z" fill="currentColor"></path>
                                                <path d="M4.06875 17.9688C3.86875 17.9688 3.66874 17.8688 3.46874 17.7688C2.96874 17.4688 2.86875 16.8688 3.16875 16.3688L6.76874 10.9688L3.16875 5.56876C2.86875 5.06876 2.96874 4.46873 3.46874 4.16873C3.96874 3.86873 4.56875 3.96878 4.86875 4.46878L8.86875 10.4688C9.06875 10.7688 9.06875 11.2688 8.86875 11.5688L4.86875 17.5688C4.66875 17.7688 4.36875 17.9688 4.06875 17.9688Z" fill="currentColor"></path>
                                            </svg>
                                        </span>
                                        <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                                            <div className="mb-3 mb-md-0 fw-semibold">
                                                <h4 className="text-gray-900 fw-bold">No Data Available.</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <>
                                    <div className="d-flex flex-column gap-5">

                                        {
                                            customerGstinList.sort((a, b) => a.add_date_time - b.add_date_time).map((obj, index) => {
                                                return (
                                                    <div key={index} className="position-relative fv-row w-100 flex-md-root cursor-pointer" onClick={() => { setSelectedGstin(obj); setSelectedPlaceOfSupply({ label: obj.place_of_supply, value: obj.place_of_supply }); setGstInListModalShow(false); }}>

                                                        {
                                                            (obj.gst_treatment !== "Unregistered Business" && obj.gst_treatment !== "Consumer") ? (
                                                                <div className="card card-flush h-lg-100 shadow-sm rounded">
                                                                    <div className="card-header mt-6 bg-light">
                                                                        <div className="card-title flex-column">
                                                                            <h3 className="fw-bolder mb-1 text-primary">{obj.GstIn}</h3>
                                                                        </div>
                                                                    </div>
                                                                    <div className="d-flex flex-wrap justify-content-between p-3">
                                                                        <div className="card-body p-4 bg-white rounded shadow-sm">
                                                                            <div className="border border-dashed rounded py-3 px-4 mx-2 mb-3 bg-light">
                                                                                <div className="fs-6 fw-bold text-gray-700">{obj.legal_name}</div>
                                                                                <div className="fw-semibold text-gray-400">Business Legal Name</div>
                                                                            </div>
                                                                            <div className="border border-dashed rounded py-3 px-4 mx-2 mb-3 bg-light">
                                                                                <div className="fs-6 fw-bold text-gray-700">{obj.gst_treatment}</div>
                                                                                <div className="fw-semibold text-gray-400">GST Treatment</div>
                                                                            </div>
                                                                            <div className="border border-dashed rounded py-3 px-4 mx-2 mb-3 bg-light">
                                                                                <div className="fs-6 fw-bold text-gray-700">{obj.place_of_supply}</div>
                                                                                <div className="fw-semibold text-gray-400">Place of Supply</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div className="card card-flush h-lg-100 shadow-sm rounded">
                                                                    <div className="d-flex flex-wrap justify-content-center p-3">
                                                                        <div className="card-body p-4 bg-white rounded shadow-sm">
                                                                            <div className="border border-dashed rounded py-3 px-4 mx-2 mb-3 bg-light">
                                                                                <div className="fs-6 fw-bold text-gray-700">{obj.gst_treatment}</div>
                                                                                <div className="fw-semibold text-gray-400">GST Treatment</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                        {
                                                            selectedGstin !== null && selectedGstin.unique_id === obj.unique_id &&
                                                            <div className='position-absolute' style={{ top: "3rem", right: "4rem" }} >
                                                                <svg width="30" height="30" viewBox="0 0 64 64">
                                                                    <path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50  l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#43a047" />
                                                                </svg>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className='btn btn-success' onClick={(e) => { setUpsertGstInModalShow(true); }}><i className="las la-plus"></i>Add New</button>
                    <button className="btn btn-danger rounded" onClick={() => { setGstInListModalShow(false) }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={upsertGstInModalShow} onHide={() => { handleGstModalClose() }} size="lg" centered>
                <Modal.Header className='py-2'>
                    <Modal.Title>Add GSTIN Details</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleGstModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="d-flex flex-wrap gap-5 mb-2">
                            <div className="fv-row w-100 flex-md-root">
                                <label className="required fw-bold form-label">GST Treatment</label>
                                <Select
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                    options={upsertGstInTreatmentList}
                                    value={upsertGstInTreatment}
                                    onChange={(val) => { setUpsertGstInTreatment(val) }}
                                    components={{
                                        Option: (props) => {
                                            return (
                                                <components.Option  {...props}>
                                                    {props.children}
                                                    <span className="fw-semibold text-gray-400 d-block fs-8">{props.data.description}</span>
                                                </components.Option >
                                            )
                                        }
                                    }}
                                />
                            </div>
                        </div>


                        <div className="row mb-5">
                            <label className="required fs-5 fw-bold mb-2">GSTIN</label>
                            <div className="row mb-2">
                                <div className="col-md-10 mb-2">
                                    <input id="GSTIN" type="text" className="form-control" placeholder="Enter GST number" name="GSTIN" value={upsertGstInNumber} onChange={(e) => { setUpsertGstInNumber(e.target.value) }} />
                                </div>
                                <div className="col-md-2">
                                    <button className="btn btn-sm btn-secondary gap-2" disabled={gstinSearchDetailsApiState}
                                        onClick={() => {
                                            const gstinRegex = /^[0-9A-Za-z]{15}$/;
                                            if (!gstinRegex.test(upsertGstInNumber)) {
                                                swal("Warning!", "Invalid GSTIN format", "warning");
                                                return;
                                            }
                                            getGstinSearchDetails(upsertGstInNumber);
                                        }}
                                    >
                                        {
                                            gstinSearchDetailsApiState === true ?
                                                <span className="spinner-border spinner-border-sm align-middle"></span>
                                                :
                                                gstinSearchDetails === null ?
                                                    <span className="text-dark">Verify</span> :
                                                    <i className="bi bi-patch-check-fill text-success fs-2 p-0"></i>
                                        }
                                    </button>
                                </div>
                            </div>

                            <div className='row'>
                                <div className="col-md-4 fv-row">
                                    <label className="required fs-5 fw-bold mb-2">Business Legal Name</label>
                                    <input id="Business_Legal_Name" type="text" className="form-control mb-2" placeholder="Legal Name" name="Business_Legal_Name" value={upsertGstInLegalName} onChange={(e) => { setUpsertGstInLegalName(e.target.value) }} />
                                </div>
                                <div className="col-md-4 fv-row">
                                    <label className="fs-5 mb-2">Business Trade Name</label>
                                    <input id="Business_Trade_Name" type="text" className="form-control mb-2" placeholder="Trade Name" name="Business_Trade_Name" value={upsertGstInTradeName} onChange={(e) => { setUpsertGstInTradeName(e.target.value) }} />
                                </div>
                                <div className="col-md-4 fv-row">
                                    <label className="required fw-bold form-label">Place Of Supply</label>
                                    <Select
                                        styles={theme == 'dark' ? dropdownStyleDark : {}}
                                        options={SupplyStateOptions}
                                        onChange={(val) => { setUpsertGstInSelectedPlaceOfSupply(val) }}
                                        value={upsertGstInSelectedPlaceOfSupply}
                                        isClearable
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { handleGstModalClose() }}>Close</button>
                    {
                        write &&
                        <button className="btn btn-info rounded" data-kt-indicator={upsertGstInSubmitButtonState ? "on" : "off"} disabled={upsertGstInSubmitButtonState} onClick={() => { gstSubmitValidation() && newGstInSubmit() }}>
                            <span className="indicator-label">Save</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={ItemModalShow} onHide={() => { setItemModalShow(false) }} dialogClassName="h-modal-60" centered>
                <Modal.Header className='py-2'>
                    <Modal.Title>Add Item</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setItemModalShow(false) }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="fv-row mb-10">
                            <div className="row">
                                <div className="col p-2">
                                    <label className={`active btn btn-active-light-primary d-flex rounded-4 text-start ${Product_type === "Goods" && "active"}`} data-kt-button="true">
                                        <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                            <input className="form-check-input" type="radio" name="discount_option" value="2" onChange={(e) => { setProduct_type("Goods") }} checked={Product_type === "Goods"} />
                                        </span>
                                        <span className="ms-5"><span className="fs-4 fw-bold text-gray-800 d-block">Goods</span></span>
                                    </label>
                                </div>
                                <div className="col p-2">
                                    <label className={`active btn btn-active-light-primary d-flex rounded-4 text-start ${Product_type === "Service" && "active"}`} data-kt-button="true">
                                        <span className="form-check form-check-custom form-check-solid form-check-sm align-items-start mt-1">
                                            <input className="form-check-input" type="radio" name="discount_option" value="3" onChange={(e) => { setProduct_type("Service") }} checked={Product_type === "Service"} />
                                        </span>
                                        <span className="ms-5"><span className="fs-4 fw-bold text-gray-800 d-block">Service</span></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className="col-md-9">
                                <label className="required form-label form-control-solid">Item Name</label>
                                <input type="text" name="product_name" className="form-control mb-2" placeholder="Item name" value={ItemName} onChange={(e) => { setItemName(e.target.value) }} />
                                <div className="text-muted fs-7 mb-3">A Item name is required and recommended to be unique.</div>
                            </div>
                            <div className="col-md-3">
                                <label className="required form-label">Unit</label>
                                <Select
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                    options={UnitSelectionList}
                                    onChange={(event) => { setUnit(event.value); }}
                                    value={UnitSelectionList.filter(option => option.value == Unit).length != 0 && UnitSelectionList.filter(option => option.value == Unit)[0]}
                                    placeholder="--Select product Unit--"
                                />
                                <div className="text-muted fs-7">Enter the product Unit.</div>
                            </div>
                        </div>

                        <div className="mb-5">
                            <label className="required form-label">Tax Preference</label>
                            <Select
                                styles={theme == 'dark' ? dropdownStyleDark : {}}
                                options={["Taxable", "Non Taxable", "Out of Scope", "Non-GST Supply"].map(x => ({ label: x, value: x }))}
                                onChange={(val) => { setTaxPreference(val.value); }}
                                value={{ label: TaxPreference, value: TaxPreference }}
                                placeholder="--Select Tax Preference--"
                            />
                            <div className="text-muted fs-7">Enter the Tax Preference.</div>
                        </div>
                        {
                            TaxPreference == "Taxable" &&
                            <>
                                <div className="mb-5">
                                    <label className="fs-6 fw-semibold mb-2">Tax Calculation Method</label>
                                    <div className="d-flex align-items-center">
                                        <div className={`rounded mx-2 px-6 py-2 cursor-pointer ${TaxCalculationMethod == "TAX_Inclusive" && "bg-primary"}`} onClick={() => { setTaxCalculationMethod("TAX_Inclusive"); }}>TAX Inclusive</div>
                                        <div className={`rounded mx-2 px-6 py-2 cursor-pointer ${TaxCalculationMethod == "TAX_Exclusive" && "bg-primary"}`} onClick={() => { setTaxCalculationMethod("TAX_Exclusive"); }}>TAX Exclusive</div>
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className="col-md-6">
                                        <label className="required form-label">Intra State Tax Rate</label>
                                        <Select
                                            styles={theme == 'dark' ? dropdownStyleDark : {}}
                                            options={taxGroupList.filter(x => x.tax_group_type != "INTERSTATE").map(x => { return { label: x.tax_group_name, value: x.tax_group_id } })}
                                            onChange={(val) => { setDefault_tax_preference({ ...Default_tax_preference, Intra_State_Tax: val.value }) }}
                                            value={Default_tax_preference.Intra_State_Tax === "" ? null : { label: taxGroupList.find(x => x.tax_group_id == Default_tax_preference.Intra_State_Tax).tax_group_name, value: Default_tax_preference.Intra_State_Tax }}
                                            placeholder="--Select Intra state tax group--"
                                        />
                                        <div className="text-muted fs-7">Set the Intra State Tax Rate.</div>
                                    </div>
                                    <div className="col-md-6">
                                        <label className="required form-label">Inter State Tax Rate</label>
                                        <Select
                                            styles={theme == 'dark' ? dropdownStyleDark : {}}
                                            options={taxGroupList.filter(x => x.tax_group_type == "INTERSTATE").map(x => { return { label: x.tax_group_name, value: x.tax_group_id } })}
                                            onChange={(val) => { setDefault_tax_preference({ ...Default_tax_preference, Inter_State_Tax: val.value }) }}
                                            value={Default_tax_preference.Inter_State_Tax === "" ? null : { label: taxGroupList.find(x => x.tax_group_id == Default_tax_preference.Inter_State_Tax).tax_group_name, value: Default_tax_preference.Inter_State_Tax }}
                                            placeholder="--Select Inter state tax group--"
                                        />
                                        <div className="text-muted fs-7">Set the Inter State Tax Rate.</div>
                                    </div>
                                </div>
                            </>
                        }

                        <label className="form-check form-check-custom form-check-solid align-items-start">
                            <input className="form-check-input me-3" type="checkbox" name="email-preferences[]" checked={Tracking_inventory === true} value={Tracking_inventory} onChange={(e) => { setTracking_inventory(e.target.checked) }} />
                            <span className="form-check-label d-flex flex-column align-items-start">
                                <span className="fw-bolder fs-5 mb-0">Track Inventory for this item</span>
                                {Tracking_inventory && <span className="text-muted fs-6">You cannot enable/disable inventory tracking once you've created transactions for this item</span>}
                            </span>
                        </label>

                        {
                            Tracking_inventory === true &&
                            <div className="container mb-5">
                                <label className="required fw-semibold fs-6 mb-5">Advanced Inventory Tracking</label>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-check form-check-custom form-check-solid">
                                            <input className="form-check-input me-3" name="user_role" type="radio" onChange={(e) => { setTracking_inventory_type("None") }} checked={Tracking_inventory_type === "None"} />
                                            <label className="form-check-label" for="kt_modal_update_role_option_0">
                                                <div className="fw-bold text-gray-800">Track Quantity</div>
                                                <div className="text-gray-600"></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check form-check-custom form-check-solid">
                                            <input className="form-check-input me-3" name="user_role" type="radio" onChange={(e) => { setTracking_inventory_type("Track Serial Number") }} checked={Tracking_inventory_type === "Track Serial Number"} />
                                            <label className="form-check-label" for="kt_modal_update_role_option_1">
                                                <div className="fw-bold text-gray-800">Track Serial Number</div>
                                                <div className="text-gray-600"></div>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-check form-check-custom form-check-solid">
                                            <input className="form-check-input me-3" name="user_role" type="radio" onChange={(e) => { setTracking_inventory_type("Track Batches") }} checked={Tracking_inventory_type === "Track Batches"} />
                                            <label className="form-check-label" for="kt_modal_update_role_option_2">
                                                <div className="fw-bold text-gray-800">Track Batches</div>
                                                <div className="text-gray-600"></div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setItemModalShow(false) }}>Close</button>
                    {
                        write &&
                        <button className="btn btn-success rounded" data-kt-indicator={itemSubmitButtonState ? "on" : "off"} disabled={itemSubmitButtonState} onClick={() => { submitItemDetails() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                </Modal.Footer>
            </Modal>

            <Modal show={termsModalShow} onHide={() => { handleNewTermsModalClose() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>Configure Payment Terms</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleNewTermsModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row">
                            <div className='col-5 text-center'>
                                <label className="fs-5 fw-bold my-2 required">Term Name</label>
                            </div>
                            <div className='col-5 text-center'>
                                <label className="fs-5 fw-bold my-2 required">Number of Days</label>
                            </div>
                        </div>
                        {
                            termsPanelList.length > 0 ?
                                termsPanelList.map((x, indx) => {
                                    return (
                                        <div key={indx} className="row mb-2">
                                            <div className='col-5'>
                                                <input type="text" className="form-control form-control-solid" value={x.term_name}
                                                    onChange={(e) => {
                                                        let temp = termsPanelList.map(x => { return { ...x } })
                                                        temp[indx].term_name = e.target.value
                                                        setTermsPanelList(temp)
                                                    }}
                                                />
                                            </div>
                                            <div className='col-5'>
                                                <input type="number" min="0" className="form-control form-control-solid" value={x.no_of_days}
                                                    onChange={(e) => {
                                                        if (e.target.value >= 0) {
                                                            let temp = termsPanelList.map(x => { return { ...x } })
                                                            temp[indx].no_of_days = e.target.value
                                                            setTermsPanelList(temp)
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className='col-2 my-auto'>
                                                <button className='btn btn-sm btn-outline-danger rounded-pill border border-danger'
                                                    onClick={() => {
                                                        let temp = termsPanelList.map(a => { return { ...a } })
                                                        temp.splice(indx, 1)
                                                        setTermsPanelList(temp)
                                                    }}
                                                >&#10006;</button>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <p className='text-center'>No terms available. Create a new term</p>
                        }
                        <div className="row">
                            <div className='col-6'>
                                <button className='btn btn-link' onClick={() => { setTermsPanelList([...termsPanelList, { term_name: "", no_of_days: "" }]) }} >Add a term</button>
                            </div>
                        </div>

                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        write &&
                        <button className="btn btn-success rounded me-auto" data-kt-indicator={termsSubmitButtonState ? "on" : "off"} disabled={termsSubmitButtonState} onClick={() => { newTermsSubmit() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className="btn btn-danger rounded" onClick={() => { handleNewTermsModalClose() }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={currencyModalShow} onHide={() => { handleNewCurrencyModalClose() }} size="lg" centered>
                <Modal.Header>
                    <Modal.Title>New Currency</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { handleNewCurrencyModalClose() }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-5 fw-bold mb-2 required">Currency Code</label>
                                <Select
                                    options={Object.keys(currency_arr.currency_arr[0]).map(x => { return { label: x, value: x } })}
                                    onChange={(e) => { setSelectedCurrencyCode(e); setCurrencySymbol(currency_arr.currency_arr[0][e.value].currency_symbol); }}
                                    value={selectedCurrencyCode}
                                    placeholder="--Choose a Currency--"
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-6 fw-bold mb-2 required">Currency symbol</label>
                                <input type="text" className="form-control form-control-solid" value={currencySymbol} onChange={(e) => { setCurrencySymbol(e.target.value) }} />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-5 fw-bold mb-2">Decimal Places</label>
                                <Select
                                    options={[0, 2, 3].map(x => { return { label: x, value: x } })}
                                    onChange={(e) => { setSelectedCurrencyDecimalPlace(e) }}
                                    value={selectedCurrencyDecimalPlace}
                                    placeholder="--Choose Decimal Places--"
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className='col-12'>
                                <label className="fs-6 fw-bold mb-2">Format</label>
                                <Select
                                    options={["1,234,567.89", "1.234.567,89", "1 234 567,89"].map(x => { return { label: x, value: x } })}
                                    onChange={(e) => { setSelectedCurrencyFormat(e) }}
                                    value={selectedCurrencyFormat}
                                    placeholder="--Choose a Format--"
                                    styles={theme == 'dark' ? dropdownStyleDark : {}}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        write &&
                        <button className="btn btn-success rounded me-auto" data-kt-indicator={currencySubmitButtonState ? "on" : "off"} disabled={currencySubmitButtonState} onClick={() => { newCurrencySubmit() }}>
                            <span className="indicator-label">Save and Continue</span>
                            <span className="indicator-progress">Please wait...
                                <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                            </span>
                        </button>
                    }
                    <button className="btn btn-danger rounded" onClick={() => { handleNewCurrencyModalClose() }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={AddressModalShow} onHide={() => { setAddressModalShow(false); }} centered>
                <Modal.Header>
                    <Modal.Title>Select {AddressType === "billing" ? "Billing" : "Shipping"} Address</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setAddressModalShow(false); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className='container'>
                        <div className="row gx-9 gy-6">
                            {
                                addressList.map((obj, indx) => {
                                    return (
                                        <div key={indx} className="card card-dashed flex-wrap p-6" style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (AddressType == "billing") {
                                                    setSelectedBillingAddress(obj)
                                                }
                                                else {
                                                    setSelectedShippingAddress(obj)
                                                }
                                                setAddressModalShow(false)
                                            }}
                                        >
                                            <div className="d-flex flex-column py-2">
                                                <div className="d-flex align-items-center fs-5 fw-bold mb-5">Address {indx + 1} {selectedBillingAddress != null && selectedBillingAddress.id === obj.id && "(Billing)"} {selectedShippingAddress != null && selectedShippingAddress.id === obj.id && "(Shipping)"} </div>
                                                <div className="fs-6 fw-semibold text-gray-600">
                                                    {obj.state}
                                                    <br />{obj.city},{obj.pincode}
                                                    <br />{obj.country}
                                                </div>
                                            </div>
                                            {
                                                (selectedBillingAddress != null && selectedShippingAddress != null) && (selectedBillingAddress.id === obj.id || selectedShippingAddress.id === obj.id) &&
                                                <div className='position-absolute' style={{ top: "1rem", right: "1rem" }} >
                                                    <svg width="30" height="30" viewBox="0 0 64 64">
                                                        <path d="M32,2C15.431,2,2,15.432,2,32c0,16.568,13.432,30,30,30c16.568,0,30-13.432,30-30C62,15.432,48.568,2,32,2z M25.025,50  l-0.02-0.02L24.988,50L11,35.6l7.029-7.164l6.977,7.184l21-21.619L53,21.199L25.025,50z" fill="#43a047" />
                                                    </svg>
                                                </div>
                                            }
                                        </div>
                                    );
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-danger rounded" onClick={() => { setAddressModalShow(false) }}>Close</button>
                </Modal.Footer>
            </Modal>

            <Modal show={productCodeModalShow} onHide={() => { setProductCodeModalShow(false); setProductCodeItemId(null); setProductCodeItemType(null); setProductCodeSearch(""); setProductCodeList([]); setProductCodeListApiState(false); }} centered>
                <Modal.Header>
                    <Modal.Title>Find {productCodeItemType == "Goods" ? "HSN" : "SAC"} Code</Modal.Title>
                    <div className="btn btn-sm btn-icon btn-active-color-primary" onClick={() => { setProductCodeModalShow(false); setProductCodeItemId(null); setProductCodeItemType(null); setProductCodeSearch(""); setProductCodeList([]); setProductCodeListApiState(false); }}>
                        <span className="svg-icon svg-icon-1">
                            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                <g transform="translate(12.000000, 12.000000) rotate(-45.000000) translate(-12.000000, -12.000000) translate(4.000000, 4.000000)" fill="#000000">
                                    <rect fill="#000000" x="0" y="7" width="16" height="2" rx="1" />
                                    <rect fill="#000000" opacity="0.5" transform="translate(8.000000, 8.000000) rotate(-270.000000) translate(-8.000000, -8.000000)" x="0" y="7" width="16" height="2" rx="1" />
                                </g>
                            </svg>
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll-y me-n7 pe-7" id="kt_modal_new_address_scroll" data-kt-scroll="true" data-kt-scroll-activate="{default: false, lg: true}" data-kt-scroll-max-height="auto" data-kt-scroll-dependencies="#kt_modal_new_address_header" data-kt-scroll-wrappers="#kt_modal_new_address_scroll" data-kt-scroll-offset="300px">
                        <div className="container">

                            <label className="form-label">Search {productCodeItemType == "Goods" ? "HSN" : "SAC"} code for your item</label>
                            <div className="d-flex gap-1">
                                <input type="text" className="form-control" value={productCodeSearch} onChange={(e) => { setProductCodeSearch(e.target.value) }} />
                                <button className="btn btn-primary btn-sm rounded" onClick={(e) => { getProductCodeList(productCodeItemType, productCodeSearch) }} disabled={productCodeListApiState}>Search</button>
                            </div>

                            {
                                productCodeList.length > 0 && productCodeList.map((obj, index) => {
                                    return (
                                        <div key={index} className="d-flex align-items-center my-2"
                                            onClick={() => {
                                                setProductCodeModalShow(false);
                                                let temp = itemArray.map(a => { return { ...a } })
                                                let ti = itemArray.findIndex(x => x.item.value == productCodeItemId)
                                                temp[ti].item_popover_input = obj.codes
                                                setItemArray(temp)

                                                setProductCodeItemId(null);
                                                setProductCodeItemType(null);
                                                setProductCodeSearch("");
                                                setProductCodeList([]);
                                                setProductCodeListApiState(false);
                                            }}
                                        >
                                            <div className="symbol symbol-40px">
                                                <span className="symbol-label bg-light">
                                                    <span className="svg-icon svg-icon-2 svg-icon-primary">
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M2 16C2 16.6 2.4 17 3 17H21C21.6 17 22 16.6 22 16V15H2V16Z" fill="currentColor"></path>
                                                            <path opacity="0.3" d="M21 3H3C2.4 3 2 3.4 2 4V15H22V4C22 3.4 21.6 3 21 3Z" fill="currentColor"></path>
                                                            <path opacity="0.3" d="M15 17H9V20H15V17Z" fill="currentColor"></path>
                                                        </svg>
                                                    </span>
                                                </span>
                                            </div>
                                            <div className="d-flex flex-column">
                                                <p className="fs-6 text-gray-800 text-hover-primary fw-semibold">{obj.code_description}</p>
                                                <span className="fs-7 text-muted fw-semibold">#{obj.codes}</span>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}
export default EditCustomerOrder;