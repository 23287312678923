import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import swal from 'sweetalert';
import moment from 'moment';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { ToWords } from 'to-words'
import { Modal, Navbar, Nav, Container } from 'react-bootstrap';

import { setCookie, getCookie } from '../common_component/cookie.js';
import { useAuthData } from "./auth-provider.js"
import StatusBadge from '../common_component/StatusBadge.js';
import PageError from '../common_component/PageError.js';

export const ViewCustomerOrder = (props) => {

	const [customerOrderDetails, setCustomerOrderDetails] = useState([]);
	const [customerOrderHistoryDetails, setCustomerOrderHistoryDetails] = useState([]);
	const [branchList, setBranchList] = useState([])

	const [printPdfTemplate, setPrintPdfTemplate] = useState("")
	const [printBillTemplate, setPrintBillTemplate] = useState("")
	const [printModalShow, setPrintModalShow] = useState(false)
	const [downloadModalShow, setDownloadModalShow] = useState(false)
	const [loading, setLoading] = useState(false);

	const [itemDetails, setItemDetails] = useState([]);
	const [taxDetails, setTaxDetails] = useState([]);
	const [billingAddress, setBillingAddress] = useState({});
	const [shippingAddress, setShippingAddress] = useState({});
	const [gstDetails, setGstDetails] = useState({});
	const [currencyCode, setCurrencyCode] = useState("");
	const [currencySymbol, setCurrencySymbol] = useState("");

	const [adjustmentValue, setAdjustmentValue] = useState("");
	const [adjustmentText, setAdjustmentText] = useState("");

	const [folder_name, setfolder_name] = useState("organization_logo");
	const [image, setImage] = useState("");
	const history = useHistory()
	const [state, setstate] = useState(2);
	const [subTotal, setSubTotal] = useState(0)

	const [decimalPlace, setDecimalPlace] = useState();
	const [userRole, setUserRole] = useState("")

	const [approvalChainPopup, setApprovalChainPopup] = useState(false)

	const [approveFinalButtonShow, setApproveFinalButtonShow] = useState(false)
	const [approveButtonShow, setApproveButtonShow] = useState(false)
	const [cancelButtonShow, setCancelButtonShow] = useState(false)

	const [submitButtonState, setSubmitButtonState] = useState(false);

	const timer = useRef(0)
	const [clock_timer, setClockTimer] = useState(0);

	var page_name = "view-customer-order";

	const { user, read, write, visibility, organization_id, user_role, organization_name } = useAuthData(page_name)

	const loaderstyle = {
		display: "block",
		marginLeft: "auto",
		marginRight: "auto",
		marginTop: "auto",
		marginBottom: "auto",
	};

	async function getCustomerOrderDetails() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-customer-order-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "customer_order_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log(body.data);

		if (body.operation === "success") {
			setCustomerOrderDetails(body.data);

			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/individual-user-info`, { method: 'POST', body: JSON.stringify({ "email": user.email, "unique_id": "", "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const t_body = await response.json();

			let user_role = t_body.data[0].user_role
			setUserRole(t_body.data[0].user_role)

			if (user_role == "Admin") {
				setApproveFinalButtonShow(true)
				setCancelButtonShow(true)
			}

			let t1 = body.data.approval_info
			let t2 = body.data.add_status
			let t3 = body.data.approval_level
			let t4 = t1.value.length
			if (t2 == "CUSTOMER_ORDER_PENDING_APPROVAL") {
				if (t1.type == "multilevel_approval") {

					let tl = t1.value.find(x => x.level == (t3 == null ? 1 : t3 + 1)).user_value
					if (tl.includes(user.email)) {
						setApproveButtonShow(true)
					}

					let tl2 = t1.value.map(x => x.user_value).flat(1)
					if (tl2.includes(user.email)) {
						setCancelButtonShow(true)
					}
				}
				else if (t1.type == "custom_approval") {
					let ty = t1.value[0]
					if (ty.user_type == "Group User") {
						if (ty.user_value.includes(user_role)) {
							setApproveButtonShow(true)
							setCancelButtonShow(true)
						}
					}
					else if (ty.user_type == "Individual User") {
						if (ty.user_value.includes(user.email)) {
							setApproveButtonShow(true)
							setCancelButtonShow(true)
						}
					}
				}
			}

			setCurrencyCode(body.data.order_currency.currency_code);
			let decimal = Math.pow(10, parseInt(body.data.order_currency.decimal_place));

			setDecimalPlace(decimal);
			setCurrencySymbol(body.data.order_currency.currency_symbol);
			setAdjustmentValue(body.data.adjustment.value);
			setAdjustmentText(body.data.adjustment.text);

			let temp = body.data.item_array;
			setItemDetails(temp);

			let arr = [];
			temp.map((obj, index) => {
				if (obj.taxable == 0) {
					obj.taxable_amount = 0;
				}
				else {
					obj.total_taxable_amount = Math.round((((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * (obj.tax_group_percentage / 100)) * decimal) / decimal;
					obj.taxable_amount = Number(obj.rate) * obj.tax_group_percentage / 100;
				}

				if (obj.tax_calculation_method == "TAX_Exclusive") {
					obj.TAX_Inclusive_Price = Number(obj.rate) + obj.taxable_amount;
					obj.TAX_Exclusive_Price = obj.rate;
				}
				else {
					obj.TAX_Inclusive_Price = Number(obj.rate) + obj.taxable_amount;
					obj.TAX_Exclusive_Price = obj.rate;
				}

				obj.total_tax = obj.tax_group_details == null ? 0 : obj.tax_group_details.reduce((p, o) => { return p + Number(o.tax_percentage) }, 0);
				if (obj.tax_group_details != null) {
					obj.tax_group_details.map((obj1, index1) => {
						if (arr.find(x => x.tax_id == obj1.tax_id)) {
							let p = arr.find(x => x.tax_id == obj1.tax_id);
							p.tax_amount = p.tax_amount + Math.round((Number(obj.total_taxable_amount) * Number(obj1.tax_percentage) / Number(obj.total_tax)) * decimal) / decimal;
						}
						else {
							let json = {};
							json.tax_id = obj1.tax_id;
							json.tax_type = obj1.tax_type;
							json.tax_name = obj1.tax_name;
							json.tax_percentage = obj1.tax_percentage;
							json.tax_amount = Math.round((Number(obj.total_taxable_amount) * Number(obj1.tax_percentage) / Number(obj.total_tax)) * decimal) / decimal;
							arr.push(json);
						}
					});
				}
			})
			setTaxDetails(arr);
			// console.log(body.data);
			// setSubTotal(Math.round(body.data.item_array).reduce((p, o) => { return p + (((o.quantity * o.rate) - (o.discount_type == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) + (o.taxable == 0 ? 0 : ((o.quantity * o.rate) - (o.discount_type == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) * (o.tax_group_percentage / 100))) }, 0) * decimal / decimal)
			setSubTotal(Math.round(body.data.item_array.reduce((p, o) => { return (p + (((o.quantity * o.rate) - (o.discount_type === "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) + (o.taxable === 0 ? 0 : ((o.quantity * o.rate) - (o.discount_type === "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) * (o.tax_group_percentage / 100)))); }, 0) * decimal / decimal));
			setBillingAddress(body.data.billing_address);
			setShippingAddress(body.data.shipping_address);
			setGstDetails(body.data.gstin_details);
		}
	}

	async function getOrderHistorydetails() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-customer-order-history-details`, { method: 'POST', body: JSON.stringify({ "email": user.email, "customer_order_id": props.match.params.id, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log(body);
		if (body.operation === "success") {
			setCustomerOrderHistoryDetails(body.data);
		}
	}

	async function getBranchList() {
		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-branches`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// console.log(body)

		if (body.operation == "success") {
			setBranchList(body.data);
		}
		else {
			throw "branch list api error"
		}
	}

	async function getCustomerOrderPdfTemplate() {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-customer-order-pdf-template`, { method: 'POST', body: JSON.stringify({ "email": user.email, "category_type": "customer_order", "format_type": "pdf_type", "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			if (body.operation === "success") {
				setPrintPdfTemplate(body.data);
			} else {
				throw "Order template API error";
			}
		} catch (error) {
			console.error("Order template API call:", error);
		}
	}

	async function getCustomerOrderBillTemplate() {
		try {
			const response = await fetch(
				`${process.env.REACT_APP_SERVER_URL}/get-customer-order-bill-template`, { method: 'POST', body: JSON.stringify({ "email": user.email, "category_type": "customer_order", "format_type": "bill_type", "page_name": page_name, "organization_id": organization_id }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
			);
			const body = await response.json();

			if (body.operation === "success") {
				setPrintBillTemplate(body.data);
			} else {
				throw "Order template for bill API error";
			}
		} catch (error) {
			console.error("Order template for bill API call:", error);
		}
	}

	async function getorganizationdetails() {

		var object = {};
		object.email = user.email;

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URL}/get-organization-information`, { method: 'POST', body: JSON.stringify({ "email": user.email, "page_name": page_name, "organization_id": organization_id, "id": organization_id, "flag": "details" }), headers: { "Content-type": "application/json; charset=UTF-8", "token": getCookie("access_token") } }
		);
		const body = await response.json();
		// let obj = {}
		if (body.length > 0) {
			if ((body[0].file_name !== undefined) && (typeof body[0].file_name === "string")) {
				setImage(`${process.env.REACT_APP_GENERAL_IMAGE_BUCKET}/${folder_name}/${body[0].file_name}`);
			}
		}
		else {
			console.log("Invalid organization image api call")
			return null
		}
	}

	useEffect(() => {
		if ((read || write) && (user.email && visibility)) {

			let p1 = getCustomerOrderDetails()
			let p2 = getOrderHistorydetails()
			let p3 = getBranchList()
			let p4 = getCustomerOrderPdfTemplate()
			let p5 = getCustomerOrderBillTemplate()
			let p6 = getorganizationdetails()

			Promise.all([p1, p2, p3, p4, p5, p6])
				.then(() => {
					setstate(1);
					console.log("all apis done")
				})
				.catch((err) => {
					setstate(0);
					console.log(err)
				})
		}
	}, [user, visibility, read, write])

	const approveOrderSubmit = (final_flag, cancel_flag, cancel_msg) => {

		var object = {};

		object.final_flag = final_flag
		object.cancel_flag = cancel_flag
		object.cancel_msg = cancel_msg
		object.customer_order_id = props.match.params.id
		object.user_email = user.email
		object.user_role = userRole

		object.organization_id = organization_id
		object.page_name = page_name;

		console.log(object);
		setSubmitButtonState(true)

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		axios.post(`${process.env.REACT_APP_SERVER_URL}/approve-customer-order`, object, headers).then((res) => {
			setSubmitButtonState(false)
			if (res.data.operation === "success") {
				swal("Great!", "Order reviewed successfully!", "success").then(() => { history.push("/customer-order-list") })
			}
			else {
				swal("Oops!", res.data.message, "error");
			}
		})
			.catch(function (error) {
				console.log(error)
			});
	}

	const handleOperation = (operationType) => {

		let tbr = branchList.find(x => x.branch_id === customerOrderDetails.branch_id)
		let gst = customerOrderDetails.gstin_details

		let head_details =
			`<div class="card-body p-9">
				<div class="text-end">
       				<span class="fw-semi-bold fs-10">SALES ORDER</span>
    			</div>
				${image ? `<div>
				    <img src="${image}" alt="Organization Logo" class="rounded float-left" style=" height: 200px; object-fit: contain;">
				</div>` : `<div class="fw-bold"><h2>${organization_name}</h2></div>`}
				<div class="row mb-7">				
					<span class="fw-semibold text-gray-800 fs-6">
						Org GST: ${tbr.GstIn}
					</span>				
				</div>
				<div class="row mb-7">					
					<span class="fw-semibold text-gray-800 fs-6">
						Branch Details: ${tbr.branch_name}-${tbr.branch_address_street1}-${tbr.branch_address_city}-${tbr.branch_address_state}-${tbr.branch_address_zipcode}
					</span>
				</div>
				<div class="row mb-7">	
					<span class="fw-semibold text-gray-800 fs-6">
						Phone: ${tbr.branch_address_phone}
					</span>					
				</div>
				<div class="row mb-7">					
					<span class="fw-semibold text-gray-800 fs-6">
						IRN: NA
					</span>					
				</div>				
				
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Way Bill No: NA &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Way Bill Date: NA
					</span>
				</div>
			</div >`

		let part_One =
			`<div class="card-body p-9">
				<label class="col-lg-4 col-sm-8 fw-bold text-muted">Order Details</label>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Reference Number: ${customerOrderDetails.reference_number ? customerOrderDetails.reference_number : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Order Number: ${customerOrderDetails.customer_order_number ? customerOrderDetails.customer_order_number : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Order Date: ${customerOrderDetails.order_date ? moment(customerOrderDetails.order_date).format('ll') : "NA"}
					</span>
				</div>				
			</div>`

		let part_two =
			`<div class="card-body p-9">
				<label class="col-lg-4 col-sm-8 fw-bold text-muted">Other Details</label>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Transportation Mode: ${customerOrderDetails.transportation_mode ? customerOrderDetails.transportation_mode : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Vehicle Number: ${customerOrderDetails.vehicle_number ? customerOrderDetails.vehicle_number : "NA"}
					</span>
				</div>				
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Place of Supply: ${customerOrderDetails.place_of_supply ? customerOrderDetails.place_of_supply : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Payment Terms: ${customerOrderDetails.term ? customerOrderDetails.term : "NA"}
					</span>
				</div>
			</div>`

		let part_three =
			`<div class="card-body p-9">
				<label class="col-lg-4 col-sm-8 fw-bold text-muted">Customer Details</label>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Name: ${customerOrderDetails.customer_name}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Phone: ${customerOrderDetails.customer_workphone ? customerOrderDetails.customer_workphone : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						email: ${customerOrderDetails.customer_email ? customerOrderDetails.customer_email : "NA"}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						GST: ${gst.GstIn === "" ? "NA" : gst.GstIn}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						PAN: NA
					</span>
				</div>
			</div>`

		let tb = customerOrderDetails.billing_address
		let ts = customerOrderDetails.shipping_address

		let part_four =
			`<div class="card-body p-9">
				<label class="col-lg-4 col-sm-8 fw-bold text-muted">Address Details</label>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Reciever Address (Billing): ${tb.address_line1 == "" ? "" : tb.address_line1 + ", "}${tb.address_line2 == "" ? "" : tb.address_line2 + ", "}${tb.city == "" ? "" : tb.city + ", "}${tb.state == "" ? "" : tb.state + ", "}${tb.country == "" ? "" : tb.country + ", "}${tb.pincode}
					</span>
				</div>
				<div class="d-flex">
					<span class="fw-semibold text-gray-800 fs-6">
						Consignee Address (Shipping): ${ts.address_line1 == "" ? "" : ts.address_line1 + ", "}${ts.address_line2 == "" ? "" : ts.address_line2 + ", "}${ts.city == "" ? "" : ts.city + ", "}${ts.state == "" ? "" : ts.state + ", "}${ts.country == "" ? "" : ts.country + ", "}${tb.pincode}
					</span>
				</div>
				<div class="d-flex">
					<span class="fw-semibold text-gray-800 fs-6">
						Due Date: ${moment(customerOrderDetails.due_date).format('ll')}
					</span>
				</div>				
			</div>`

		let table_details =

			`<table class="text-center table table-bordered">
				<thead>
					<tr>
						<th>Sl No.</th>
						<th>Description of Goods</th>
						<th>HSN/SAC</th>
						<th>Quantity</th>
						<th>Rate</th>
						<th>Disc %</th>
						<th>Disc Amt</th>
						<th>Tax Type</th>
						<th>Tax %</th>
						<th>Tax Inc</th>
						<th>Tax Exc</th>							
						<th>Amount</th>
					</tr>						
				</thead>
				<tbody>
					##
					<tr>
						<td>{{sl_no}}</td>
						<td>{{description_of_goods}}</td>
						<td>{{product_tax_code}}</td>
						<td>{{quantity}}</td>
						<td>{{rate}}</td>
						<td>{{discount_percentage}}</td>
						<td>{{discount_amount}}</td>							
						<td>{{tax_group}}</td>							
						<td>{{taxable_percentage}}</td>							
						<td>{{tax_inclusive}}</td>							
						<td>{{tax_exclusive}}</td>							
						<td>{{amount}}</td>
					</tr>
					##
				<tbody>	
				<tfoot>
					<tr>
						<td colspan="9" rowspan="{{footer_row_count}}">
							Total in Words <br> {{total_in_words}} <br><br>
							Customer Note <br> {{customer_note}} <br><br>
							Terms and Conditions <br> {{terms_and_conditions}} <br><br>
						</td>
						<td colspan="2">SubTotal</td>
						<td>{{subtotal}}</td>
					</tr>
					{{tax_total_details}}
					{{tds_tcs_details}}
					<tr>
						<td colspan="2">{{adjustment_text}}</td>
						<td>{{adjustment_value}}</td>
					</tr>
					<tr>
						<td colspan="9">Order Grand Total</td>
						<td colspan="3">{{document_total}}</td>
					</tr>
				</tfoot>					
			</table>`

		let td = taxDetails.reduce((p, o) => {
			if (p.hasOwnProperty(o.tax_type)) {
				p[o.tax_type] += o.tax_amount
			}
			else {
				p[o.tax_type] = o.tax_amount
			}
			return p
		}, {})

		let d = customerOrderDetails.item_array
		// console.log(d);

		let cd = customerOrderDetails.order_currency

		let decimalPlace = cd.decimal_place;

		function round(value, decimals) {
			return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
		}

		let roundedSubTotal = round(subTotal, decimalPlace);

		table_details = table_details.replace("{{subtotal}}", roundedSubTotal)

		let footer_row_count = 3
		let roundedFd1 = Object.entries(td).map(([taxType, amount]) => {
			footer_row_count++
			return `<tr>
				<td colspan="2">${taxType} Total</td>
				<td>${round(amount, decimalPlace)}</td>
			</tr>`
		}).join('');

		let roundedFd2 = `<tr>
			  <td colspan="2">TDS/TCS Total</td>
			  <td>${round(Math.round((customerOrderDetails.tax_category == "TDS" ? customerOrderDetails.item_array.reduce((p, o) => { return p + ((o.quantity * o.rate) - (o.discount_type == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) }, 0) : subTotal) * (customerOrderDetails.tax_id == null ? 0 : parseFloat(customerOrderDetails.tax_percentage)) / 100 * decimalPlace) / decimalPlace, decimalPlace)}</td>
		  </tr>`

		table_details = table_details.replace("{{footer_row_count}}", footer_row_count)
		table_details = table_details.replace("{{tax_total_details}}", roundedFd1);
		table_details = table_details.replace("{{tds_tcs_details}}", roundedFd2);

		table_details = table_details.replace("{{adjustment_text}}", customerOrderDetails.adjustment.text)
		table_details = table_details.replace("{{adjustment_value}}", customerOrderDetails.adjustment.value)

		let tw = new ToWords()
		table_details = table_details.replace("{{total_in_words}}", tw.convert(customerOrderDetails.grand_total) + " Only")
		table_details = table_details.replace("{{customer_note}}", customerOrderDetails.customer_notes)
		table_details = table_details.replace("{{terms_and_conditions}}", customerOrderDetails.terms_and_conditions)

		let rs = table_details.indexOf("##")
		let re = table_details.lastIndexOf("##")
		let r_code = table_details.substring(rs + 2, re)

		let item = customerOrderDetails.item_array.map((obj, i) => {

			let temp = r_code
			temp = temp.replace("{{sl_no}}", i + 1)
			temp = temp.replace("{{description_of_goods}}", obj.item_name)
			temp = temp.replace("{{product_tax_code}}", obj.tax_code)
			temp = temp.replace("{{quantity}}", obj.quantity)
			temp = temp.replace("{{rate}}", Math.round(obj.rate * Math.pow(10, decimalPlace)) / Math.pow(10, decimalPlace))
			temp = temp.replace("{{discount_percentage}}", obj.discount_type == "percentage" ? obj.discount_unit : Math.round((Number(obj.discount_unit) / (obj.rate * obj.quantity)) * 100 * decimalPlace) / decimalPlace)
			temp = temp.replace("{{discount_amount}}", obj.discount_type == "amount" ? obj.discount_unit : Math.round((obj.rate * obj.quantity * Number(obj.discount_unit) / 100) * decimalPlace) / decimalPlace)
			temp = temp.replace("{{tax_group}}", obj.tax_group_details === null ? "NA" : obj.tax_group_details[0].tax_type)
			temp = temp.replace("{{taxable_percentage}}", obj.tax_group_percentage === null ? 0 : obj.tax_group_percentage)
			temp = temp.replace("{{tax_inclusive}}", Math.round(itemDetails.find(x => x.item_id == obj.item_id).TAX_Inclusive_Price * Math.pow(10, decimalPlace)) / Math.pow(10, decimalPlace))
			temp = temp.replace("{{tax_exclusive}}", Math.round(itemDetails.find(x => x.item_id == obj.item_id).TAX_Exclusive_Price * Math.pow(10, decimalPlace)) / Math.pow(10, decimalPlace))
			temp = temp.replace("{{amount}}", Math.round((((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) + (obj.taxable == 0 ? 0 : ((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * (obj.tax_group_percentage / 100))) * decimalPlace) / decimalPlace)

			let tcd = ""
			if (td.hasOwnProperty("CGST")) {
				let v = obj.taxable == 0 ? undefined : obj.tax_group_details.find(x => x.tax_type == "CGST")
				if (v != undefined) {
					tcd += `<td>${v.tax_percentage}%</td>
					<td>${Math.round((Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace) * v.tax_percentage / 100 * decimalPlace) / decimalPlace}</td>`
				}
				else {
					tcd += `<td>--</td>
					<td>--</td>`
				}
			}
			if (td.hasOwnProperty("SGST")) {
				let v = obj.taxable == 0 ? undefined : obj.tax_group_details.find(x => x.tax_type == "SGST")
				if (v != undefined) {
					tcd += `<td>${v.tax_percentage}%</td>
					<td>${Math.round((Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace) * v.tax_percentage / 100 * decimalPlace) / decimalPlace}</td>`
				}
				else {
					tcd += `<td>--</td>
					<td>--</td>`
				}
			}
			if (td.hasOwnProperty("UTGST")) {
				let v = obj.taxable == 0 ? undefined : obj.tax_group_details.find(x => x.tax_type == "UTGST")
				if (v != undefined) {
					tcd += `<td>${v.tax_percentage}%</td>
					<td>${Math.round((Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace) * v.tax_percentage / 100 * decimalPlace) / decimalPlace}</td>`
				}
				else {
					tcd += `<td>--</td>
					<td>--</td>`
				}
			}
			if (td.hasOwnProperty("IGST")) {
				let v = obj.taxable == 0 ? undefined : obj.tax_group_details.find(x => x.tax_type == "IGST")
				if (v != undefined) {
					tcd += `<td>${v.tax_percentage}%</td>
					<td>${Math.round((Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace) * v.tax_percentage / 100 * decimalPlace) / decimalPlace}</td>`
				}
				else {
					tcd += `<td>--</td>
					<td>--</td>`
				}
			}

			temp = temp.replace("{{tax_column_details}}", tcd)
			table_details = table_details.replace("{{document_total}}", customerOrderDetails.order_currency.currency_symbol + " " + customerOrderDetails.grand_total)
			return temp
		}).join("")
		table_details = table_details.substring(0, rs) + item + table_details.substring(re + 2, table_details.length);

		let modifiedTemplate = printPdfTemplate
		modifiedTemplate = modifiedTemplate.replace("$head_details", head_details)
		modifiedTemplate = modifiedTemplate.replace("$part_1", part_One)
		modifiedTemplate = modifiedTemplate.replace("$part_2", part_two)
		modifiedTemplate = modifiedTemplate.replace("$part_3", part_three)
		modifiedTemplate = modifiedTemplate.replace("$part_4", part_four)
		modifiedTemplate = modifiedTemplate.replace("$table_details", table_details)
		// console.log(modifiedTemplate);
		if (operationType === 'print') {
			let nnww = window.open()
			nnww.document.body.parentNode.innerHTML = modifiedTemplate;

			setTimeout(() => {
				nnww.print();
			}, 1000);
		}
		else if (operationType === 'download') {
			return modifiedTemplate;
		}
	}

	const handleOperationBill = (operation) => {
		let tbr = branchList.find(x => x.branch_id === customerOrderDetails.branch_id)
		let gst = customerOrderDetails.gstin_details

		let organization_details =
			`<div class="card-body p-2">
				<div class="text-end">
					<span class="fw-semi-bold fs-7">SALES ORDER</span>
				</div>
				${image ? `
			<div>
				<img src="${image}" alt="Organization Logo" class="rounded float-left" style="height: 100px; object-fit: contain;">
				<div class="fw-bold"><h2>${organization_name}</h2></div>
			</div>`
				:
				`<div class="fw-bold"><h5>${organization_name}</h5></div>`
			}
			</div>`

		let branch_details =
			`<div class="card-body p-2">
				<div class="row mb-7">				
					<span class="fw-semibold text-gray-800 fs-6">
						Org GST: ${tbr.GstIn}
					</span>				
				</div>
				<div class="row mb-7">					
					<span class="fw-semibold text-gray-800 fs-6">
						Branch Details: ${tbr.branch_name}-${tbr.branch_address_street1}-${tbr.branch_address_city}-${tbr.branch_address_state}-${tbr.branch_address_zipcode}
					</span>
				</div>
				<div class="row mb-7">	
					<span class="fw-semibold text-gray-800 fs-6">
						Phone: ${tbr.branch_address_phone}
					</span>					
				</div>
				${customerOrderDetails.IRN ?
				`<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						IRN: ${customerOrderDetails.IRN}
					</span>
				</div>`
				: ''}
				${customerOrderDetails.way_bill ?
				`<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Way Bill No: ${customerOrderDetails.bill_no} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Way Bill Date: ${customerOrderDetails.bill_date}
					</span>
				</div>`
				: ''}
			</div >`

		let document_details =
			`<div class="card-body p-2">
				<label class="fw-bold text-muted">Order Details</label>
				${customerOrderDetails.reference_number ?
				`<div class="row mb-7">
						<span class="fw-semibold text-gray-800 fs-6">
							Reference Number: ${customerOrderDetails.reference_number}
						</span>
					</div>`
				: ''}
				${customerOrderDetails.customer_order_number ?
				`<div class="row mb-7">
						<span class="fw-semibold text-gray-800 fs-6">
							Order Number: ${customerOrderDetails.customer_order_number}
						</span>
					</div>`
				: ''}				
				${customerOrderDetails.order_date ?
				`<div class="row mb-7">
						<span class="fw-semibold text-gray-800 fs-6">
							Order Date: ${moment(customerOrderDetails.order_date).format('ll')}
						</span>
					</div>`
				: ''}
				${customerOrderDetails.transportation_mode ?
				`<div class="row mb-7">
						<span class="fw-semibold text-gray-800 fs-6">
							Transportation Mode: ${customerOrderDetails.transportation_mode}
						</span>
					</div>`
				: ''}				
				${customerOrderDetails.vehicle_number ?
				`<div class="row mb-7">
						<span class="fw-semibold text-gray-800 fs-6">
							Vehicle Number: ${customerOrderDetails.vehicle_number}
						</span>
					</div>`
				: ''}				
				${customerOrderDetails.place_of_supply ?
				`<div class="row mb-7">
						<span class="fw-semibold text-gray-800 fs-6">
							Place of Supply: ${customerOrderDetails.place_of_supply}
						</span>
					</div>`
				: ''}
				${customerOrderDetails.term ?
				`<div class="row mb-7">
						<span class="fw-semibold text-gray-800 fs-6">
							Payment Terms: ${customerOrderDetails.term}
						</span>
					</div>`
				: ''}
			</div>`

		let tb = customerOrderDetails.billing_address
		let ts = customerOrderDetails.shipping_address
		// console.log("gst:", gst);
		let customer_details =
			`<div class="card-body p-2">
				<label class="fw-bold text-muted">Customer Details</label>				
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Name: ${customerOrderDetails.customer_name}
					</span>
				</div>
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Phone: ${customerOrderDetails.customer_workphone ? customerOrderDetails.customer_workphone : "NA"}
					</span>
				</div>
				${customerOrderDetails.customer_email ?
				`<div class="row mb-7">
							<span class="fw-semibold text-gray-800 fs-6">
								email: ${customerOrderDetails.customer_email}
							</span>
						</div>`
				: ''}				
				${gst.GstIn === "" ?
				`<div class="row mb-7">
						<span class="fw-semibold text-gray-800 fs-6">
							GST Treatment: ${gst.gst_treatment}
						</span>
					</div>`
				: `<div class="row mb-7">
						<span class="fw-semibold text-gray-800 fs-6">
							GST No: ${gst.GstIn}
						</span>
					</div>`}
				${customerOrderDetails.PAN ?
				`<div class="row mb-7">
						<span class="fw-semibold text-gray-800 fs-6">
							PAN: ${customerOrderDetails.PAN}
						</span>
					</div>`
				: ''}
				<div class="row mb-7">
					<span class="fw-semibold text-gray-800 fs-6">
						Reciever Address (Billing): ${tb.address_line1 == "" ? "" : tb.address_line1 + ", "}${tb.address_line2 == "" ? "" : tb.address_line2 + ", "}${tb.city == "" ? "" : tb.city + ", "}${tb.state == "" ? "" : tb.state + ", "}${tb.country == "" ? "" : tb.country + ", "}${tb.pincode}
					</span>
				</div>
				<div class="d-flex">
					<span class="fw-semibold text-gray-800 fs-6">
						Consignee Address (Shipping): ${ts.address_line1 == "" ? "" : ts.address_line1 + ", "}${ts.address_line2 == "" ? "" : ts.address_line2 + ", "}${ts.city == "" ? "" : ts.city + ", "}${ts.state == "" ? "" : ts.state + ", "}${ts.country == "" ? "" : ts.country + ", "}${tb.pincode}
					</span>
				</div>
				<div class="d-flex">
					<span class="fw-semibold text-gray-800 fs-6">
						Due Date: ${moment(customerOrderDetails.due_date).format('ll')}
					</span>
				</div>				
			</div>`

		let table_details =

			`<table class="text-center table table-bordered">
				<thead>
					<tr>						
						<th rowspan="2">Item Name</th>						
						<th rowspan="2">Qty</th>												
						<th rowspan="2">Amt</th>
						<th rowspan="2">Tax</th>
					</tr>						
				</thead>
				<tbody>
					##
					<tr>						
						<td>{{description_of_goods}}</td>						
						<td>{{quantity}}</td>												
						<td>{{amount}}</td>
						<td>{{taxable_percentage}}</td>
					</tr>
					##
				<tbody>	
				<tfoot>
					<tr>							
						<td colspan="3">SubTotal</td>
						<td>{{subtotal}}</td>
					</tr>
					{{tax_total_details}}
					{{tds_tcs_details}}
					<tr>
						<td colspan="3">{{adjustment_text}}</td>
						<td>{{adjustment_value}}</td>
					</tr>
					<tr>
						<td colspan="3">Order Grand Total</td>
						<td colspan="3">{{document_total}}</td>
					</tr>
					<tr>
					<td colspan="4" style="text-align: left;">
						Total Items: 2<br>
						Total in Words: <br> Fifty Four Thousand Eight Hundred Point Zero Two Only <br><br>
						Customer Note: <br>  <br><br>
						Terms and Conditions: <br>  <br><br>
					</td>
					</tr>
				</tfoot>						
			</table>`

		let td = taxDetails.reduce((p, o) => {
			if (p.hasOwnProperty(o.tax_type)) {
				p[o.tax_type] += o.tax_amount
			}
			else {
				p[o.tax_type] = o.tax_amount
			}
			return p
		}, {})

		let tax_column_count = 1
		let tch1 = ""
		let tch2 = ""
		if (td.hasOwnProperty("CGST")) {
			tch1 += `<th colspan="2">CGST</th>`
			tch2 += `<th>%</th>
			<th>Amt.</th>`
			tax_column_count += 2
		}
		if (td.hasOwnProperty("SGST")) {
			tch1 += `<th colspan="2">SGST</th>`
			tch2 += `<th>%</th>
			<th>Amt.</th>`
			tax_column_count += 2
		}
		if (td.hasOwnProperty("UTGST")) {
			tch1 += `<th colspan="2">UTGST</th>`
			tch2 += `<th>%</th>
			<th>Amt.</th>`
			tax_column_count += 2
		}
		if (td.hasOwnProperty("IGST")) {
			tch1 += `<th colspan="2">IGST</th>`
			tch2 += `<th>%</th>
			<th>Amt.</th>`
			tax_column_count += 2
		}

		let footer_row_count = 2
		let fd1 = ""
		if (td.hasOwnProperty("CGST")) {
			fd1 += `<tr>
				<td colspan="${tax_column_count}">CGST Total</td>
				<td>${td["CGST"]}</td>
			</tr>`
			footer_row_count++
		}
		if (td.hasOwnProperty("SGST")) {
			fd1 += `<tr>
				<td colspan="${tax_column_count}">SGST Total</td>
				<td>${td["SGST"]}</td>
			</tr>`
			footer_row_count++
		}
		if (td.hasOwnProperty("UTGST")) {
			fd1 += `<tr>
				<td colspan="${tax_column_count}">UTGST Total</td>
				<td>${td["UTGST"]}</td>
			</tr>`
			footer_row_count++
		}
		if (td.hasOwnProperty("IGST")) {
			fd1 += `<tr>
				<td colspan="${tax_column_count}">IGST Total</td>
				<td>${td["IGST"]}</td>
			</tr>`
			footer_row_count++
		}

		let fd2 = ""
		if (customerOrderDetails.tax_category != null) {
			fd2 = `<tr>
				<td colspan="${tax_column_count}">TDS/TCS Total</td>
				<td>${Math.round((customerOrderDetails.tax_category == "TDS" ? customerOrderDetails.item_array.reduce((p, o) => { return p + ((o.quantity * o.rate) - (o.discount_type == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) }, 0) : subTotal) * (customerOrderDetails.tax_id == null ? 0 : parseFloat(customerOrderDetails.tax_percentage)) / 100 * decimalPlace) / decimalPlace}</td>
			</tr>`
			footer_row_count++
		}

		let cd = customerOrderDetails.order_currency

		let decimalPlace = cd.decimal_place;

		function round(value, decimals) {
			return Number(Math.round(value + 'e' + decimals) + 'e-' + decimals);
		}

		let roundedSubTotal = round(subTotal, decimalPlace);

		table_details = table_details.replace("{{subtotal}}", roundedSubTotal)

		let roundedFd1 = Object.entries(td).map(([taxType, amount]) => {
			return `<tr>
				<td colspan="${tax_column_count}">${taxType} Total</td>
				<td>${round(amount, decimalPlace)}</td>
			</tr>`
		}).join('');

		let roundedFd2 = `<tr>
			  <td colspan="${tax_column_count}">TDS/TCS Total</td>
			  <td>${round(Math.round((customerOrderDetails.tax_category == "TDS" ? customerOrderDetails.item_array.reduce((p, o) => { return p + ((o.quantity * o.rate) - (o.discount_type == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) }, 0) : subTotal) * (customerOrderDetails.tax_id == null ? 0 : parseFloat(customerOrderDetails.tax_percentage)) / 100 * decimalPlace) / decimalPlace, decimalPlace)}</td>
		  </tr>`

		table_details = table_details.replace("{{footer_row_count}}", footer_row_count)
		table_details = table_details.replace("{{tax_total_details}}", roundedFd1);
		table_details = table_details.replace("{{tds_tcs_details}}", roundedFd2);

		table_details = table_details.replace("{{adjustment_text}}", customerOrderDetails.adjustment.text)
		table_details = table_details.replace("{{adjustment_value}}", customerOrderDetails.adjustment.value)

		let d = customerOrderDetails.item_array
		let tw = new ToWords()

		table_details = table_details.replace("{{total_items}}", d.length)
		table_details = table_details.replace("{{total_in_words}}", tw.convert(customerOrderDetails.grand_total) + " Only")
		table_details = table_details.replace("{{customer_note}}", customerOrderDetails.customer_notes)
		table_details = table_details.replace("{{terms_and_conditions}}", customerOrderDetails.terms_and_conditions)

		let rs = table_details.indexOf("##")
		let re = table_details.lastIndexOf("##")
		let r_code = table_details.substring(rs + 2, re)

		let item = customerOrderDetails.item_array.map((obj, i) => {

			let temp = r_code
			temp = temp.replace("{{description_of_goods}}", obj.item_name)
			temp = temp.replace("{{quantity}}", obj.quantity)
			temp = temp.replace("{{amount}}", Math.round((((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) + (obj.taxable == 0 ? 0 : ((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * (obj.tax_group_percentage / 100))) * decimalPlace) / decimalPlace)
			temp = temp.replace("{{taxable_percentage}}", obj.tax_group_percentage === null ? 0 : obj.tax_group_percentage)

			let tcd = ""
			if (td.hasOwnProperty("CGST")) {
				let v = obj.taxable == 0 ? undefined : obj.tax_group_details.find(x => x.tax_type == "CGST")
				if (v != undefined) {
					tcd += `<td>${v.tax_percentage}%</td>
					<td>${Math.round((Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace) * v.tax_percentage / 100 * decimalPlace) / decimalPlace}</td>`
				}
				else {
					tcd += `<td>--</td>
					<td>--</td>`
				}
			}
			if (td.hasOwnProperty("SGST")) {
				let v = obj.taxable == 0 ? undefined : obj.tax_group_details.find(x => x.tax_type == "SGST")
				if (v != undefined) {
					tcd += `<td>${v.tax_percentage}%</td>
					<td>${Math.round((Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace) * v.tax_percentage / 100 * decimalPlace) / decimalPlace}</td>`
				}
				else {
					tcd += `<td>--</td>
					<td>--</td>`
				}
			}
			if (td.hasOwnProperty("UTGST")) {
				let v = obj.taxable == 0 ? undefined : obj.tax_group_details.find(x => x.tax_type == "UTGST")
				if (v != undefined) {
					tcd += `<td>${v.tax_percentage}%</td>
					<td>${Math.round((Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace) * v.tax_percentage / 100 * decimalPlace) / decimalPlace}</td>`
				}
				else {
					tcd += `<td>--</td>
					<td>--</td>`
				}
			}
			if (td.hasOwnProperty("IGST")) {
				let v = obj.taxable == 0 ? undefined : obj.tax_group_details.find(x => x.tax_type == "IGST")
				if (v != undefined) {
					tcd += `<td>${v.tax_percentage}%</td>
					<td>${Math.round((Math.round(((obj.quantity * obj.rate) - (obj.discount_type == "amount" ? obj.discount_unit : obj.quantity * obj.rate * obj.discount_unit / 100)) * decimalPlace) / decimalPlace) * v.tax_percentage / 100 * decimalPlace) / decimalPlace}</td>`
				}
				else {
					tcd += `<td>--</td>
					<td>--</td>`
				}
			}

			temp = temp.replace("{{tax_column_details}}", tcd)
			table_details = table_details.replace("{{document_total}}", customerOrderDetails.order_currency.currency_symbol + " " + customerOrderDetails.grand_total)
			return temp
		}).join("")
		table_details = table_details.substring(0, rs) + item + table_details.substring(re + 2, table_details.length);

		let modifiedBillTemplate = printBillTemplate
		modifiedBillTemplate = modifiedBillTemplate.replace("{{organization_details}}", organization_details)
		modifiedBillTemplate = modifiedBillTemplate.replace("{{branch_details}}", branch_details)
		modifiedBillTemplate = modifiedBillTemplate.replace("{{main_details}}", document_details)
		modifiedBillTemplate = modifiedBillTemplate.replace("{{customer_details}}", customer_details)
		modifiedBillTemplate = modifiedBillTemplate.replace("{{item_details}}", table_details)
		// console.log(modifiedTemplate);
		if (operation === 'print') {
			let nnww = window.open()
			nnww.document.body.parentNode.innerHTML = modifiedBillTemplate;

			setTimeout(() => {
				nnww.print();
			}, 1000);
		}
		else if (operation === 'download') {
			return modifiedBillTemplate;
		}
	}

	const generateDownload = async () => {

		setLoading(true);
		// const htmlContent = handleDownload();
		const htmlContent = handleOperation('download')

		let object = {};
		// console.log(organization_name)
		object.organizaiton_name = organization_name
		object.html_code = htmlContent
		object.organization_id = organization_id
		object.page_name = page_name;

		console.log(object);
		setDownloadModalShow(true)
		setLoading(false);

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}

		axios.post(`${process.env.REACT_APP_SERVER_URL}/download-oder-details-pdf`, object, headers).then((res) => {
			setDownloadModalShow(false)
			if (res.data.operation === "success") {
				swal("File Created!", "Go to File.", "success").then(() => {
					window.open(res.data.data.file_url, "_blank");
				});
			} else {
				swal("Oops!", "Something went wrong!", "error");
			}
		})
			.catch(function (error) {
				console.log(error)
				setLoading(false);
			});
	}

	const newInvoiceSubmit = () => {
		var object = {}

		object.customer_order_id = props.match.params.id
		object.organization_id = organization_id
		object.page_name = page_name;

		// console.log(object);
		setSubmitButtonState(true)

		var headers = {
			headers: {
				"Content-Type": "application/json",
				"Accept": "*/*",
				"token": getCookie("access_token")
			}
		}
		axios.post(`${process.env.REACT_APP_SERVER_URL}/add-customer-invoice`, object, headers).then((res) => {
			setSubmitButtonState(false)
			if (res.data.operation === "success") {
				swal("Great!", `Invoice created as ${res.data.data.saved_ref} and saved successfully!`, "success").then((value) => { history.push(`/view-customer-invoice/${res.data.data.customer_invoice_id}`) });
			}
			else if (res.data.operation === "duplicate") {
				swal("Oops!", "This Reference Number already exists!!", "error");
			}
			else {
				swal("Oops!", "Something went wrong!", "error");
			}
		})
			.catch(function (error) {
				console.log(error)
			});
	}


	return (
		<>
			<div>
				<div className="d-flex flex-stack mb-5">
					<div className="page-title d-flex flex-column justify-content-center flex-wrap me-3">
						<h1 className="page-heading d-flex text-dark fw-bold fs-3 flex-column justify-content-center my-0">View Customer Order
							<small className="text-muted fs-7 fw-bold my-1 ms-1"></small>
						</h1>
					</div>

					{
						state === 1 &&
						<Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
							<Container>
								<Navbar.Toggle aria-controls="responsive-navbar-nav" />
								<Navbar.Collapse className='position-relative'>
									<Nav className="align-items-end end-0 gap-3 navbar-nav p-5 position-absolute">
										{
											approveFinalButtonShow && customerOrderDetails.add_status === "CUSTOMER_ORDER_PENDING_APPROVAL" &&
											<Nav.Item>
												<button className='btn btn-light-success btn-hover-scale border border-success' title='Final Approve' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
													onClick={() => {
														swal("Alert!", "Are you sure you want to proceed?", {
															buttons: {
																nothing: { text: "Cancel", className: "btn btn-secondary" },
																edit: { text: "Modify", className: "btn btn-warning" },
																approve: { text: "Approve", className: "btn btn-danger" },
															}
														})
															.then((value) => {
																switch (value) {
																	case "edit": history.push(`/edit-customer-order/${props.match.params.id}`); break;
																	case "approve": approveOrderSubmit(true, false, ""); break;
																}
															});
													}}
												>
													<span className="indicator-label"><i className="bi bi-shield-check p-0 fs-1"></i></span>
													<span className="indicator-progress">Please wait...
														<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
													</span>
												</button>
											</Nav.Item>
										}
										{
											approveButtonShow && customerOrderDetails.add_status === "CUSTOMER_ORDER_PENDING_APPROVAL" &&
											<Nav.Item>
												<button className='btn btn-light-success btn-hover-scale btn-sm border border-success' title='Approve' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
													onClick={() => {
														swal("Alert!", "Are you sure you want to proceed?", {
															buttons: {
																nothing: { text: "Cancel", className: "btn btn-secondary" },
																edit: { text: "Modify", className: "btn btn-warning" },
																approve: { text: "Approve", className: "btn btn-danger" },
															}
														})
															.then((value) => {
																switch (value) {
																	case "edit": history.push(`/edit-customer-order/${props.match.params.id}`); break;
																	case "approve": approveOrderSubmit(false, false, ""); break;
																}
															});
													}}
												>
													<span className="indicator-label"><i className="bi bi-check-circle p-0 fs-1"></i></span>
													<span className="indicator-progress">Please wait...
														<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
													</span>
												</button>
											</Nav.Item>
										}
										{
											cancelButtonShow && customerOrderDetails.add_status === "CUSTOMER_ORDER_PENDING_APPROVAL" &&
											<Nav.Item>
												<button className='btn btn-light-danger btn-hover-scale btn-sm border border-danger' title='Cancel' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
													onClick={() => {
														swal({
															title: "Alert!",
															text: "Please type a reason before cancelling",
															icon: "warning",
															content: "input",
															buttons: true,
															dangerMode: true,
														})
															.then((msg) => {
																if (msg === "") {
																	swal("Alert!", "Message cannot be empty", "warning")
																} else if (msg) {
																	approveOrderSubmit(false, true, msg);
																}
															});
													}}
												>
													<span className="indicator-label"><i className="bi bi-x-circle p-0 fs-1"></i></span>
													<span className="indicator-progress">Please wait...
														<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
													</span>
												</button>
											</Nav.Item>
										}
										{
											customerOrderDetails.add_status === "CUSTOMER_ORDER_FINALIZED" &&
											<Nav.Item>
												<button className="btn btn-light-success btn-hover-scale btn-sm border border-success" title='process to invoice' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState} onClick={() => { newInvoiceSubmit() }}>
													<span className="indicator-label"><i class="bi bi-files p-0 fs-1"></i></span>
													{/* <span className="indicator-label">
															<svg viewBox="0 0 100 100" fill="#ffffff" width="32" height="32">
																<path d="M89.858,21.702c-0.123-0.364-0.32-0.7-0.593-0.972L74.27,5.735C73.81,5.274,73.151,5,72.5,5h-25C46.119,5,45,6.119,45,7.5  V35H22.5c-1.381,0-2.5,1.119-2.5,2.5v55c0,1.381,1.119,2.5,2.5,2.5h40c1.381,0,2.5-1.119,2.5-2.5V65h22.5c1.381,0,2.5-1.119,2.5-2.5  v-40C90,22.219,89.948,21.968,89.858,21.702C89.735,21.338,89.948,21.968,89.858,21.702z M75,13.536L81.464,20H75V13.536z   M50,43.536L56.464,50H50V43.536z M60,90H25V40h20v12.5c0,1.381,1.119,2.5,2.5,2.5H60V90z M85,60H65v-7.5  c0-0.651-0.274-1.31-0.735-1.77L50,36.465V10h20v12.5c0,1.381,1.119,2.5,2.5,2.5H85V60z" />
																<path d="M17.5,15C10.607,15,5,20.607,5,27.5c0,3.338,1.3,6.478,3.661,8.839c0.488,0.488,1.128,0.732,1.768,0.732  c0.64,0,1.279-0.244,1.768-0.732c0.977-0.976,0.977-2.559,0-3.536C10.78,31.387,10,29.503,10,27.5c0-4.136,3.364-7.5,7.5-7.5h5  h3.964L22.5,23.965l-1.768,1.768c-0.977,0.976-0.977,2.559,0,3.535C21.22,29.756,21.86,30,22.5,30s1.28-0.244,1.768-0.732l10-10  c0.977-0.976,0.977-2.559,0-3.535l-10-10c-0.976-0.977-2.56-0.977-3.535,0c-0.977,0.976-0.977,2.559,0,3.535l1.768,1.768L26.464,15  H22.5H17.5z" />
															</svg>
														</span> */}
													<span className="indicator-progress">
														<span className="spinner-border spinner-border-sm align-middle ms-2"></span>
													</span>
												</button>
											</Nav.Item>
										}
										{
											(customerOrderDetails.add_status === "CUSTOMER_ORDER_PENDING_APPROVAL" || customerOrderDetails.add_status === "CUSTOMER_ORDER_DRAFT" || customerOrderDetails.add_status === "CUSTOMER_ORDER_FINALIZED") &&
											<Nav.Item>
												<button className='btn btn-light-warning btn-hover-scale btn-sm border border-warning' title='Edit' data-kt-indicator={submitButtonState ? "on" : "off"} disabled={submitButtonState}
													onClick={() => {
														const warningMessage = customerOrderDetails.add_status === "CUSTOMER_ORDER_FINALIZED"
															? "Are you sure you want to proceed? Editing the details after being finalized will reset the status."
															: "Are you sure you want to proceed?";

														swal("Alert!", warningMessage, {
															buttons: {
																nothing: { text: "Cancel", className: "btn btn-secondary" },
																edit: { text: "Modify", className: "btn btn-warning" }
															}
														})
															.then((value) => {
																switch (value) {
																	case "edit": history.push(`/edit-customer-order/${props.match.params.id}`); break;
																}
															});
													}}
												>
													{/* <span className="indicator-label">Edit Details</span> */}
													<i className="bi bi-pencil-square fs-1 p-0"></i>
												</button>
											</Nav.Item>
										}
										<Nav.Item>
											<button className='btn btn-info' onClick={() => { setPrintModalShow(true) }}><i className="bi bi-printer fs-2 p-0"></i></button>
										</Nav.Item>
										<Nav.Item>
											<button className='btn btn-info' onClick={() => { setDownloadModalShow(true) }}><i className="bi bi-file-earmark-arrow-down fs-2 p-0"></i></button>
										</Nav.Item>
									</Nav>
								</Navbar.Collapse>
							</Container>
						</Navbar>
					}
				</div>
				<div>
					{state === 1 ?
						<>
							<div className="d-flex flex-column gap-7 gap-lg-10">
								<div className="d-flex flex-wrap flex-stack gap-5 gap-lg-10">
									<ul className="nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x border-0 fs-4 fw-semibold mb-lg-n2 me-auto" role="tablist">
										<li className="nav-item" role="presentation">
											<a className="nav-link text-active-primary pb-4 active" data-bs-toggle="tab" href="#kt_ecommerce_sales_order_summary" aria-selected="true" role="tab">Order Summary</a>
										</li>
										<li className="nav-item" role="presentation">
											<a className="nav-link text-active-primary pb-4" data-bs-toggle="tab" href="#kt_ecommerce_sales_order_history" aria-selected="false" role="tab" tabIndex="-1">Order History</a>
										</li>
									</ul>
								</div>
								<div className="tab-content">
									<div className="tab-pane fade active show" id="kt_ecommerce_sales_order_summary" role="tab-panel">
										<div className="d-flex flex-column gap-7 gap-lg-10">
											<div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
												<div className="card flex-equal">
													<div className="card-header">
														<div className="card-title">
															<h2>Order Details (#{customerOrderDetails.customer_order_number})</h2>
														</div>
													</div>
													<div className="card-body py-4">

														<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
															<tbody className="fw-semibold text-gray-600">
																<tr>
																	<td className="text-muted">
																		<div className="d-flex align-items-center">
																			<span className="svg-icon svg-icon-2 me-2">
																				<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																					<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																				</svg>
																			</span>Status
																		</div>
																	</td>
																	<td className="fw-bold text-end">
																		<div style={{ position: "relative", display: "inline-block" }} >
																			<button className={`py-2 me-1 rounded btn btn-sm btn-${customerOrderDetails.add_status === "CUSTOMER_ORDER_PENDING_APPROVAL" ? "warning" : customerOrderDetails.add_status === "CUSTOMER_ORDER_FINALIZED" ? "success" : "secondary"}`}
																				onClick={() => { setApprovalChainPopup(true); }}
																				onBlur={() => { setApprovalChainPopup(false) }}
																			>
																				{customerOrderDetails.add_status.replace("CUSTOMER_ORDER", "").toLowerCase().replace(/_/g, ' ').replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())}
																			</button>
																			{
																				customerOrderDetails.approval_info.value.length > 0 &&
																				<div style={{ position: "absolute", right: "0px", top: "30px", boxShadow: "0 2px 5px 0 rgb(0 0 0 / 26%)", backgroundColor: "#FFFFFF", color: "black", padding: "1rem", width: "25rem", borderRadius: "0.5rem", transition: "all 0.25s ease-in-out", zIndex: 1, opacity: approvalChainPopup ? "1" : "0", visibility: approvalChainPopup ? "visible" : "hidden" }}>
																					{
																						customerOrderDetails.approval_info.type == "multilevel_approval" ?
																							<>
																								<div className='text-center border-bottom fs-4'>Multilevel Approval</div>
																								<div>
																									{
																										customerOrderDetails.approval_info.value.map((obj, i) => {
																											return (
																												<div key={i} className='mx-auto mt-2 gap-5' style={{ width: "95%", backgroundColor: "#edf0f2", borderRadius: "10px", display: "flex", alignItems: "center", padding: "10px", position: "relative", marginBottom: "30px" }}>
																													<div style={{ width: "30px", height: "30px", border: "5px #ffc700 solid", borderRadius: "10px" }}></div>
																													<div className='flex-grow-1 text-start'>
																														<div className='fs-4'>Level {obj.level}</div>
																														{
																															obj.user_value.map((val, j) => {
																																return <div key={j} className="fs-7"><span className='bullet bg-primary me-2'></span>{val}</div>
																															})
																														}
																													</div>
																													<div style={{ position: "absolute", width: "2px", height: "30px", backgroundColor: "darkgray", top: "100%", left: "24px" }}></div>
																												</div>
																											)
																										})
																									}

																									<div className='mx-auto my-2 gap-20' style={{ width: "95%", backgroundColor: "#edf0f2", borderRadius: "10px", display: "flex", alignItems: "center", padding: "10px" }}>
																										<i className="fa-solid fa-star fs-1" style={{ color: "#ff8100" }} ></i>
																										<div className='fs-4'>Approved</div>
																									</div>
																								</div>
																							</>
																							:
																							customerOrderDetails.approval_info.type == "custom_approval" &&
																							<>
																								<div className='text-center border-bottom fs-4'>Custom Approval</div>
																								<div>
																									<div className='mx-auto mt-2 gap-5' style={{ width: "95%", backgroundColor: "#edf0f2", borderRadius: "10px", display: "flex", alignItems: "center", padding: "10px", position: "relative", marginBottom: "30px" }}>
																										<div style={{ width: "30px", height: "30px", border: "5px #ffc700 solid", borderRadius: "10px" }}></div>
																										<div className='flex-grow-1 text-start'>
																											<div className='fs-4'>{customerOrderDetails.approval_info.value[0].user_type}</div>
																											{
																												customerOrderDetails.approval_info.value[0].user_value.map((val, i) => {
																													return <div key={i} className="fs-7"><span className='bullet bg-primary me-2'></span>{val}</div>
																												})
																											}
																										</div>
																										<div style={{ position: "absolute", width: "2px", height: "30px", backgroundColor: "darkgray", top: "100%", left: "24px" }}></div>
																									</div>
																									<div className='mx-auto my-2 gap-20' style={{ width: "95%", backgroundColor: "#edf0f2", borderRadius: "10px", display: "flex", alignItems: "center", padding: "10px" }}>
																										<i className="fa-solid fa-star fs-1" style={{ color: "#ff8100" }} ></i>
																										<div className='fs-4'>Approved</div>
																									</div>
																								</div>
																							</>
																					}
																				</div>
																			}
																		</div>
																	</td>
																</tr>
																<tr>
																	<td className="text-muted">
																		<div className="d-flex align-items-center">
																			<span className="svg-icon svg-icon-2 me-2">
																				<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																					<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																				</svg>
																			</span>Order Date
																		</div>
																	</td>
																	<td className="fw-bold text-end">{moment(customerOrderDetails.order_date).format("ll")}</td>
																</tr>
																<tr>
																	<td className="text-muted">
																		<div className="d-flex align-items-center">
																			<span className="svg-icon svg-icon-2 me-2">
																				<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"></path>
																					<path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"></path>
																					<path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"></path>
																				</svg>
																			</span>Reference Id
																		</div>
																	</td>
																	<td className="fw-bold text-end">{customerOrderDetails.reference_number !== "" ? customerOrderDetails.reference_number : "No reference number provided"}</td>
																</tr>
																<tr>
																	<td className="text-muted">
																		<div className="d-flex align-items-center">
																			<span className="svg-icon svg-icon-2 me-2">
																				<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																					<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																				</svg>
																			</span>Due Date
																		</div>
																	</td>
																	<td className="fw-bold text-end">{moment(customerOrderDetails.due_date).format("ll")}</td>
																</tr>
															</tbody>
														</table>

													</div>
												</div>
												<div className="card flex-equal">
													<div className="card-header">
														<div className="card-title">
															<h2>GSTIN Details</h2>
														</div>
													</div>
													<div className="card-body py-4">
														<div className="table-responsive">
															<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
																<tbody className="fw-semibold text-gray-600">
																	<tr>
																		<td className="text-muted">
																			<div className="d-flex align-items-center">
																				<span className="svg-icon svg-icon-2 me-2">
																					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"></path>
																						<path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"></path>
																						<path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"></path>
																					</svg>
																				</span>GST Treatment
																			</div>
																		</td>
																		<td className="fw-bold text-end">{gstDetails.gst_treatment}</td>
																	</tr>
																	{
																		(gstDetails.gst_treatment !== "Consumer" && gstDetails.gst_treatment !== "Unregistered Business") &&
																		<>
																			<tr>
																				<td className="text-muted">
																					<div className="d-flex align-items-center">
																						<span className="svg-icon svg-icon-2 me-2">
																							<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																								<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																								<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																							</svg>
																						</span>GSTIN
																					</div>
																				</td>
																				<td className="fw-bold text-end">{gstDetails.GstIn}</td>
																			</tr>
																			<tr>
																				<td className="text-muted">
																					<div className="d-flex align-items-center">
																						<span className="svg-icon svg-icon-2 me-2">
																							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																								<path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"></path>
																								<path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"></path>
																								<path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"></path>
																							</svg>
																						</span>Trade Name
																					</div>
																				</td>
																				<td className="fw-bold text-end">{gstDetails.trade_name !== "" ? gstDetails.trade_name : "No given trade name"}</td>
																			</tr>
																			<tr>
																				<td className="text-muted">
																					<div className="d-flex align-items-center">
																						<span className="svg-icon svg-icon-2 me-2">
																							<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																								<path opacity="0.3" d="M3.20001 5.91897L16.9 3.01895C17.4 2.91895 18 3.219 18.1 3.819L19.2 9.01895L3.20001 5.91897Z" fill="currentColor"></path>
																								<path opacity="0.3" d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21C21.6 10.9189 22 11.3189 22 11.9189V15.9189C22 16.5189 21.6 16.9189 21 16.9189H16C14.3 16.9189 13 15.6189 13 13.9189ZM16 12.4189C15.2 12.4189 14.5 13.1189 14.5 13.9189C14.5 14.7189 15.2 15.4189 16 15.4189C16.8 15.4189 17.5 14.7189 17.5 13.9189C17.5 13.1189 16.8 12.4189 16 12.4189Z" fill="currentColor"></path>
																								<path d="M13 13.9189C13 12.2189 14.3 10.9189 16 10.9189H21V7.91895C21 6.81895 20.1 5.91895 19 5.91895H3C2.4 5.91895 2 6.31895 2 6.91895V20.9189C2 21.5189 2.4 21.9189 3 21.9189H19C20.1 21.9189 21 21.0189 21 19.9189V16.9189H16C14.3 16.9189 13 15.6189 13 13.9189Z" fill="currentColor"></path>
																							</svg>
																						</span>Legal Name
																					</div>
																				</td>
																				<td className="fw-bold text-end">{gstDetails.legal_name}</td>
																			</tr>
																		</>
																	}

																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>

											<div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
												<div className="card flex-equal">
													<div className="card-header">
														<div className="card-title">
															<h2>Customer Details</h2>
														</div>
													</div>
													<div className="card-body py-4">
														<div className="table-responsive">
															<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
																<tbody className="fw-semibold text-gray-600">
																	<tr>
																		<td className="text-muted">
																			<div className="d-flex align-items-center">
																				<span className="svg-icon svg-icon-2 me-2">
																					<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path opacity="0.3" d="M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z" fill="currentColor"></path>
																						<path d="M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z" fill="currentColor"></path>
																						<rect x="7" y="6" width="4" height="4" rx="2" fill="currentColor"></rect>
																					</svg>
																				</span>Customer Name
																			</div>
																		</td>
																		<td className="fw-bold text-end">{customerOrderDetails.customer_name}</td>
																	</tr>
																	<tr>
																		<td className="text-muted">
																			<div className="d-flex align-items-center">
																				<span className="svg-icon svg-icon-2 me-2">
																					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
																						<path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
																					</svg>
																				</span>Customer Email
																			</div>
																		</td>
																		<td className="fw-bold text-end">{customerOrderDetails.customer_email !== "" ? customerOrderDetails.customer_email : "No Email-address added"}</td>
																	</tr>
																	<tr>
																		<td className="text-muted">
																			<div className="d-flex align-items-center">
																				<span className="svg-icon svg-icon-2 me-2">
																					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path opacity="0.3" d="M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z" fill="currentColor"></path>
																						<path d="M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z" fill="currentColor"></path>
																					</svg>
																				</span>Additional User Email
																			</div>
																		</td>
																		<td className="fw-bold text-end">{customerOrderDetails.additional_user_email ? customerOrderDetails.additional_user_email : "No additional user email Provided"}</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
												<div className="card flex-equal">
													<div className="card-header">
														<div className="card-title">
															<h2>Associated Details</h2>
														</div>
													</div>
													<div className="card-body py-4">
														<div className="table-responsive">
															<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
																<tbody className="fw-semibold text-gray-600">
																	<tr>
																		<td className="text-muted">
																			<div className="d-flex align-items-center">
																				<span className="svg-icon svg-icon-2 me-2">
																					<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																						<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																					</svg>
																				</span>Branch
																			</div>
																		</td>
																		<td className="fw-bold text-end">{customerOrderDetails.branch_name}</td>
																	</tr>
																	<tr>
																		<td className="text-muted">
																			<div className="d-flex align-items-center">
																				<span className="svg-icon svg-icon-2 me-2">
																					<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																						<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																					</svg>
																				</span>Warehouse
																			</div>
																		</td>
																		<td className="fw-bold text-end">{customerOrderDetails.name}</td>
																	</tr>
																	<tr>
																		<td className="text-muted">
																			<div className="d-flex align-items-center">
																				<span className="svg-icon svg-icon-2 me-2">
																					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path d="M20 8H16C15.4 8 15 8.4 15 9V16H10V17C10 17.6 10.4 18 11 18H16C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18H21C21.6 18 22 17.6 22 17V13L20 8Z" fill="currentColor"></path>
																						<path opacity="0.3" d="M20 18C20 19.1 19.1 20 18 20C16.9 20 16 19.1 16 18C16 16.9 16.9 16 18 16C19.1 16 20 16.9 20 18ZM15 4C15 3.4 14.6 3 14 3H3C2.4 3 2 3.4 2 4V13C2 13.6 2.4 14 3 14H15V4ZM6 16C4.9 16 4 16.9 4 18C4 19.1 4.9 20 6 20C7.1 20 8 19.1 8 18C8 16.9 7.1 16 6 16Z" fill="currentColor"></path>
																					</svg>
																				</span>Place Of Supply
																			</div>
																		</td>
																		<td className="fw-bold text-end">{customerOrderDetails.place_of_supply}</td>
																	</tr>
																	<tr>
																		<td className="text-muted">
																			<div className="d-flex align-items-center">
																				<span className="svg-icon svg-icon-2 me-2">
																					<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
																						<path opacity="0.3" d="M21.6 11.2L19.3 8.89998V5.59993C19.3 4.99993 18.9 4.59993 18.3 4.59993H14.9L12.6 2.3C12.2 1.9 11.6 1.9 11.2 2.3L8.9 4.59993H5.6C5 4.59993 4.6 4.99993 4.6 5.59993V8.89998L2.3 11.2C1.9 11.6 1.9 12.1999 2.3 12.5999L4.6 14.9V18.2C4.6 18.8 5 19.2 5.6 19.2H8.9L11.2 21.5C11.6 21.9 12.2 21.9 12.6 21.5L14.9 19.2H18.2C18.8 19.2 19.2 18.8 19.2 18.2V14.9L21.5 12.5999C22 12.1999 22 11.6 21.6 11.2Z" fill="currentColor"></path>
																						<path d="M11.3 9.40002C11.3 10.2 11.1 10.9 10.7 11.3C10.3 11.7 9.8 11.9 9.2 11.9C8.8 11.9 8.40001 11.8 8.10001 11.6C7.80001 11.4 7.50001 11.2 7.40001 10.8C7.20001 10.4 7.10001 10 7.10001 9.40002C7.10001 8.80002 7.20001 8.4 7.30001 8C7.40001 7.6 7.7 7.29998 8 7.09998C8.3 6.89998 8.7 6.80005 9.2 6.80005C9.5 6.80005 9.80001 6.9 10.1 7C10.4 7.1 10.6 7.3 10.8 7.5C11 7.7 11.1 8.00005 11.2 8.30005C11.3 8.60005 11.3 9.00002 11.3 9.40002ZM10.1 9.40002C10.1 8.80002 10 8.39998 9.90001 8.09998C9.80001 7.79998 9.6 7.70007 9.2 7.70007C9 7.70007 8.8 7.80002 8.7 7.90002C8.6 8.00002 8.50001 8.2 8.40001 8.5C8.40001 8.7 8.30001 9.10002 8.30001 9.40002C8.30001 9.80002 8.30001 10.1 8.40001 10.4C8.40001 10.6 8.5 10.8 8.7 11C8.8 11.1 9 11.2001 9.2 11.2001C9.5 11.2001 9.70001 11.1 9.90001 10.8C10 10.4 10.1 10 10.1 9.40002ZM14.9 7.80005L9.40001 16.7001C9.30001 16.9001 9.10001 17.1 8.90001 17.1C8.80001 17.1 8.70001 17.1 8.60001 17C8.50001 16.9 8.40001 16.8001 8.40001 16.7001C8.40001 16.6001 8.4 16.5 8.5 16.4L14 7.5C14.1 7.3 14.2 7.19998 14.3 7.09998C14.4 6.99998 14.5 7 14.6 7C14.7 7 14.8 6.99998 14.9 7.09998C15 7.19998 15 7.30002 15 7.40002C15.2 7.30002 15.1 7.50005 14.9 7.80005ZM16.6 14.2001C16.6 15.0001 16.4 15.7 16 16.1C15.6 16.5 15.1 16.7001 14.5 16.7001C14.1 16.7001 13.7 16.6 13.4 16.4C13.1 16.2 12.8 16 12.7 15.6C12.5 15.2 12.4 14.8001 12.4 14.2001C12.4 13.3001 12.6 12.7 12.9 12.3C13.2 11.9 13.7 11.7001 14.5 11.7001C14.8 11.7001 15.1 11.8 15.4 11.9C15.7 12 15.9 12.2 16.1 12.4C16.3 12.6 16.4 12.9001 16.5 13.2001C16.6 13.4001 16.6 13.8001 16.6 14.2001ZM15.4 14.1C15.4 13.5 15.3 13.1 15.2 12.9C15.1 12.6 14.9 12.5 14.5 12.5C14.3 12.5 14.1 12.6001 14 12.7001C13.9 12.8001 13.8 13.0001 13.7 13.2001C13.6 13.4001 13.6 13.8 13.6 14.1C13.6 14.7 13.7 15.1 13.8 15.4C13.9 15.7 14.1 15.8 14.5 15.8C14.8 15.8 15 15.7 15.2 15.4C15.3 15.2 15.4 14.7 15.4 14.1Z" fill="currentColor"></path>
																					</svg>
																				</span>Currency
																			</div>
																		</td>
																		<td className="fw-bold text-end">{currencyCode}</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>

											<div className="d-flex flex-column flex-xl-row gap-7 gap-lg-10">
												<div className="card card-flush flex-equal">
													<div className="position-absolute end-0 opacity-10" style={{ top: "15%" }}>
														<img src="/assets/media/icons/duotune/ecommerce/ecm001.svg" className="w-125px" alt="Location Icon" />
													</div>
													<div className="card-header">
														<div className="card-title">
															<h2>Billing Address</h2>
														</div>
													</div>
													<div className="card-body py-3">
														<div className="address-info">
															{
																billingAddress.address_line1 &&
																<div className="address">
																	<i className="fa-solid fa-location-crosshairs"></i> {billingAddress.address_line1}
																</div>
															}
															{
																billingAddress.address_line2 &&
																<div className="address">
																	<i className="fa-solid fa-location-crosshairs"></i> {billingAddress.address_line2}
																</div>
															}
															<div className="location">
																<i className="fas fa-map-marker-alt"></i> {billingAddress.city}, {billingAddress.state}
															</div>
															<div className="pincode">
																<i className="fas fa-thumbtack"></i> {billingAddress.pincode}
															</div>
															<div className="country">
																<i className="fas fa-globe"></i> {billingAddress.country}
															</div>
														</div>
													</div>
												</div>
												<div className="card card-flush flex-equal">
													<div className="position-absolute end-0 opacity-10" style={{ top: "15%" }}>
														<img src="/assets/media/icons/duotune/ecommerce/ecm006.svg" className="w-125px" alt="Location Icon" />
													</div>
													<div className="card-header">
														<div className="card-title">
															<h2>Shipping Address</h2>
														</div>
													</div>
													<div className="card-body py-3">
														<div className="address-info">
															{
																shippingAddress.address_line1 &&
																<div className="address">
																	<i className="fa-solid fa-location-crosshairs"></i> {shippingAddress.address_line1}
																</div>
															}
															{
																shippingAddress.address_line2 &&
																<div className="address">
																	<i className="fa-solid fa-location-crosshairs"></i> {shippingAddress.address_line2}
																</div>
															}
															<div className="location">
																<i className="fas fa-map-marker-alt"></i> {shippingAddress.city}, {shippingAddress.state}
															</div>
															<div className="pincode">
																<i className="fas fa-thumbtack"></i> {shippingAddress.pincode}
															</div>
															<div className="country">
																<i className="fas fa-globe"></i> {shippingAddress.country}
															</div>
														</div>
													</div>
												</div>
											</div>

											<div className="card">
												<div className="card-header align-items-center py-5 gap-2 gap-md-5">
													<div className="card-title">
														<h2>Ordered Items</h2>
													</div>
												</div>
												<div className="card-body py-4">
													<div className="d-flex justify-content-between flex-column">
														<div className="table-responsive border-bottom mb-9">
															<table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
																<thead>
																	<tr className="border-bottom fs-6 fw-bold text-gray-700">
																		<th className="min-w-100px">Products</th>
																		<th className="min-w-70px">Rate<br />(Tax Exclusive)</th>
																		<th className="min-w-70px">Rate<br />(Tax Inclusive)</th>
																		<th className="min-w-80px">QTY</th>
																		<th className="min-w-70px">Discount</th>
																		<th className="min-w-70px">Total Amt.<br />Excl.</th>
																		<th className="min-w-70px">Tax Percentage</th>
																		<th className="min-w-70px">Tax Amount</th>
																		<th className="min-w-100px text-end pb-2">Total</th>
																	</tr>
																</thead>
																<tbody className="fw-semibold text-gray-600">
																	{itemDetails.map((Obj, index) => {
																		return (
																			<tr key={index}>
																				<td>
																					<div className="fw-bold">
																						<div className="fw-bold">{Obj.item_name}</div>
																						{Obj.tax_code !== "" && <span className="badge badge-light-primary me-2">{Obj.tax_code_type}: {Obj.tax_code}</span>}
																					</div>
																				</td>
																				<td>{currencySymbol} {Math.round(Obj.TAX_Exclusive_Price * decimalPlace) / decimalPlace}</td>
																				<td>{currencySymbol} {Math.round(Obj.TAX_Inclusive_Price * decimalPlace) / decimalPlace}</td>
																				<td>{Obj.quantity}</td>
																				<td>
																					<div className="fw-bold">
																						<div className="fw-bold">
																							{currencySymbol}{Obj.discount_type == "amount" ? <>{Obj.discount_unit}</> : <>{Math.round((Obj.rate * Obj.quantity * Number(Obj.discount_unit) / 100) * decimalPlace) / decimalPlace}</>}</div>
																						{Obj.discount_type !== "amount" && <span className="badge badge-light-primary me-2">{Obj.discount_type !== "amount" && <>{Obj.discount_unit} %</>}</span>}
																					</div>
																				</td>
																				<td>{currencySymbol} {Math.round(((Obj.quantity * Obj.rate) - (Obj.discount_type == "amount" ? Obj.discount_unit : Obj.quantity * Obj.rate * Obj.discount_unit / 100)) * decimalPlace) / decimalPlace}</td>
																				{Obj.taxable === 1 ? <td><div className="ms-5"><div>{Obj.tax_group_name} ({Obj.tax_group_percentage}%)</div><div className="mb-8">
																					{Obj.tax_group_details.map((Obj1, index1) => {
																						return (
																							<span key={index1} className="badge badge-light-primary me-2">{Obj1.tax_name} ({Obj1.tax_percentage}%)</span>
																						)
																					})}
																				</div></div></td> : <td>---</td>}
																				<td>{currencySymbol} {Math.round((Obj.taxable == 0 ? 0 : ((Obj.quantity * Obj.rate) - (Obj.discount_type == "amount" ? Obj.discount_unit : Obj.quantity * Obj.rate * Obj.discount_unit / 100)) * (Obj.tax_group_percentage / 100)) * decimalPlace) / decimalPlace}</td>
																				<td className="text-end">{currencySymbol} {Math.round((((Obj.quantity * Obj.rate) - (Obj.discount_type == "amount" ? Obj.discount_unit : Obj.quantity * Obj.rate * Obj.discount_unit / 100)) + (Obj.taxable == 0 ? 0 : ((Obj.quantity * Obj.rate) - (Obj.discount_type == "amount" ? Obj.discount_unit : Obj.quantity * Obj.rate * Obj.discount_unit / 100)) * (Obj.tax_group_percentage / 100))) * decimalPlace) / decimalPlace}</td>
																			</tr>
																		)
																	})}

																</tbody>
															</table>
														</div>
														<div className="d-flex flex-wrap justify-content-between">
															<div className="d-flex flex-column mw-md-300px">
																<div className="fw-semibold fs-5 mb-3 text-dark00">Tax Calculation</div>
																{taxDetails.map((Obj, index) => {
																	return (
																		<div key={index} className="d-flex flex-stack text-gray-800 mb-3 fs-6">
																			<div className="fw-semibold pe-5">{Obj.tax_name} ({Obj.tax_percentage}%):</div>
																			<div className="text-end fw-norma">{currencySymbol} {Obj.tax_amount}</div>
																		</div>
																	)
																})}
															</div>

															<div className="d-flex justify-content-end">
																<div className="mw-300px">
																	<div className="d-flex flex-stack mb-3">
																		<div className="fw-semibold pe-10 text-gray-600 fs-7">Subtotal:</div>
																		<div className="text-end fw-bold fs-6 text-gray-800">{currencySymbol}  {subTotal}</div>
																	</div>
																	<div className="d-flex flex-stack mb-3">
																		<div className="fw-semibold pe-10 text-gray-600 fs-7">Tax Percentage {customerOrderDetails.tax_percentage !== null && <>({customerOrderDetails.tax_name}-{customerOrderDetails.tax_percentage}%)</>}</div>
																		<div className="text-end fw-bold fs-6 text-gray-800">{currencySymbol}  {Math.round((customerOrderDetails.tax_category == "TDS" ? itemDetails.reduce((p, o) => { return p + ((o.quantity * o.rate) - (o.discount_type == "amount" ? o.discount_unit : o.quantity * o.rate * o.discount_unit / 100)) }, 0) : subTotal) * (customerOrderDetails.tax_id == null ? 0 : parseFloat(customerOrderDetails.tax_percentage)) / 100 * decimalPlace) / decimalPlace}</div>
																	</div>
																	<div className="d-flex flex-stack mb-3">
																		<div className="fw-semibold pe-10 text-gray-600 fs-7">{adjustmentText}</div>
																		<div className="text-end fw-bold fs-6 text-gray-800">{currencySymbol} {adjustmentValue}</div>
																	</div>
																	<div className="d-flex flex-stack">
																		<div className="fw-semibold pe-10 text-gray-600 fs-7">Total</div>
																		<div className="text-end fw-bold fs-6 text-gray-800">{currencySymbol}  {customerOrderDetails.grand_total}</div>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className="card">
												<div className="card-header">
													<div className="card-title">
														<h2>Additional Information</h2>
													</div>
												</div>
												<div className="card-body py-4">
													<div className="table-responsive">
														<table className="table align-middle table-row-bordered mb-0 fs-6 gy-5 min-w-300px">
															<tbody className="fw-semibold text-gray-600">
																<tr>
																	<td className="text-muted">
																		<div className="d-flex align-items-center">
																			<span className="svg-icon svg-icon-2 me-2">
																				<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																					<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																				</svg>
																			</span>Term
																		</div>
																	</td>
																	<td className="fw-bold text-end">{customerOrderDetails.term == null ? "No term period provided" : (customerOrderDetails.term.term_name + "---" + customerOrderDetails.term.term_days + " days")}</td>
																</tr>
																<tr>
																	<td className="text-muted">
																		<div className="d-flex align-items-center">
																			<span className="svg-icon svg-icon-2 me-2">
																				<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																					<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																				</svg>
																			</span>Subject
																		</div>
																	</td>
																	<td className="fw-bold text-end">{customerOrderDetails.subject !== "" ? customerOrderDetails.subject : "No Subject added to view"}</td>
																</tr>
																<tr>
																	<td className="text-muted">
																		<div className="d-flex align-items-center">
																			<span className="svg-icon svg-icon-2 me-2">
																				<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																					<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																				</svg>
																			</span>Customer Notes
																		</div>
																	</td>
																	<td className="fw-bold text-end">{customerOrderDetails.customer_notes !== "" ? customerOrderDetails.customer_notes : "No Notes Provided"}</td>
																</tr>
																<tr>
																	<td className="text-muted">
																		<div className="d-flex align-items-center">
																			<span className="svg-icon svg-icon-2 me-2">
																				<svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
																					<path opacity="0.3" d="M19 3.40002C18.4 3.40002 18 3.80002 18 4.40002V8.40002H14V4.40002C14 3.80002 13.6 3.40002 13 3.40002C12.4 3.40002 12 3.80002 12 4.40002V8.40002H8V4.40002C8 3.80002 7.6 3.40002 7 3.40002C6.4 3.40002 6 3.80002 6 4.40002V8.40002H2V4.40002C2 3.80002 1.6 3.40002 1 3.40002C0.4 3.40002 0 3.80002 0 4.40002V19.4C0 20 0.4 20.4 1 20.4H19C19.6 20.4 20 20 20 19.4V4.40002C20 3.80002 19.6 3.40002 19 3.40002ZM18 10.4V13.4H14V10.4H18ZM12 10.4V13.4H8V10.4H12ZM12 15.4V18.4H8V15.4H12ZM6 10.4V13.4H2V10.4H6ZM2 15.4H6V18.4H2V15.4ZM14 18.4V15.4H18V18.4H14Z" fill="currentColor"></path>
																					<path d="M19 0.400024H1C0.4 0.400024 0 0.800024 0 1.40002V4.40002C0 5.00002 0.4 5.40002 1 5.40002H19C19.6 5.40002 20 5.00002 20 4.40002V1.40002C20 0.800024 19.6 0.400024 19 0.400024Z" fill="currentColor"></path>
																				</svg>
																			</span>Terms and Conditions
																		</div>
																	</td>
																	<td className="fw-bold text-end">{customerOrderDetails.terms_and_conditions !== "" ? customerOrderDetails.terms_and_conditions : "No Terms and Conditions Added"}</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div className="tab-pane fade" id="kt_ecommerce_sales_order_history" role="tab-panel">
										<div className="d-flex flex-column gap-7 gap-lg-10">
											<div className="card">
												<div className="card-header">
													<div className="card-title">
														<h2>Customer Order History</h2>
													</div>
												</div>

												<div className="card-body py-4">
													{
														customerOrderHistoryDetails.length > 0 &&
														<div className="table-responsive">
															<table className="table align-middle table-row-dashed fs-6 gy-5 mb-0">
																<thead>
																	<tr className="text-start text-gray-400 fw-bold fs-7 text-uppercase gs-0">
																		<th className="min-w-100px">Date Added</th>
																		<th className="min-w-175px">Comment</th>
																		<th className="min-w-70px">Order Status</th>
																		<th className="min-w-100px">Customer Notifed</th>
																	</tr>
																</thead>
																<tbody className="fw-semibold text-gray-600">
																	{
																		customerOrderHistoryDetails.map((Obj, index) => {
																			return (
																				<tr key={index}>
																					<td>{moment(Obj.timestamp).format("ll")}</td>
																					<td>{Obj.operation_message}</td>
																					<td>
																						<StatusBadge status={Obj.customer_order_status} entity={"CUSTOMER_ORDER"} />
																					</td>
																					<td>No</td>
																				</tr>
																			)
																		})
																	}
																</tbody>
															</table>
														</div>
													}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

						</>
						: state === 2 ?
							<div className="card modal-body pt-0 pb-15 px-5 px-xl-20">
								<img src="/images/loader-06.svg" alt="" style={loaderstyle} />
							</div>
							:
							<PageError>
								<Link className='btn btn-success rounded' to={`/dashboard`}>Go to Dashboard</Link>
							</PageError>
					}
				</div>
			</div>

			<Modal show={printModalShow} onHide={() => { setPrintModalShow(false) }} centered className="bg-transparent" >
				<Modal.Body className="position-relative">
					<div className="position-absolute top-0 end-0 p-2">
						<div onClick={() => { setPrintModalShow(false) }} className="cursor-pointer">
							<i className="bi bi-x-circle fs-4 text-danger"></i>
						</div>
					</div>
					<div className='fw-bold container d-flex align-items-center gap-5'>
						<div>Choose file type to print :</div>
						<button className='btn btn-sm btn-info' onClick={() => { handleOperation('print') }}>
							<i className="bi bi-file-earmark-pdf fs-4 fw-bold"></i> A4
						</button>
						<button className='btn btn-sm btn-warning' onClick={() => { handleOperationBill('print') }}>
							<i className="bi bi-receipt fs-4 fw-bold"></i>Bill
						</button>
					</div>
				</Modal.Body>
			</Modal>

			<Modal show={downloadModalShow} onHide={() => { setDownloadModalShow(false) }} centered className="bg-transparent">
				<Modal.Body className="position-relative">
					<div className="position-absolute top-0 end-0 p-2">
						<div onClick={() => { setDownloadModalShow(false) }} className="cursor-pointer">
							<i className="bi bi-x-circle fs-4 text-danger"></i>
						</div>
					</div>
					<div className='fw-bold container d-flex align-items-center gap-5'>
						<div>Choose file type to Download :</div>
						<button className='btn btn-sm btn-info' onClick={generateDownload}>
							{
								loading ? (
									<>
										<span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
										Loading...
									</>

								) : (
									<i className="bi bi-file-earmark-pdf fs-4 fw-bold"></i>
								)
							}
							A4
						</button>
						<button className='btn btn-sm btn-warning'>Download as Other</button>
					</div>
				</Modal.Body>
			</Modal>
		</>);

};

export default ViewCustomerOrder;
