const series_data = [
    { "label": "Bill Of Supply", "sub_series_name": "bill_of_supply", "prefix_value": "BOS", "start_count": "01", "last_count": 0 },
    { "label": "Debit Note", "sub_series_name": "debit_note", "prefix_value": "DN", "start_count": "01", "last_count": 0 },
    { "label": "Invoice", "sub_series_name": "invoice", "prefix_value": "INV", "start_count": "01", "last_count": 0 },
    { "label": "Sales Invoice", "sub_series_name": "sales_invoice", "prefix_value": "SINV", "start_count": "01", "last_count": 0 },
    { "label": "Recurring Invoice", "sub_series_name": "sales_recurring_invoice", "prefix_value": "RINV", "start_count": "01", "last_count": 0 },
    { "label": "Estimate", "sub_series_name": "estimate", "prefix_value": "EST", "start_count": "01", "last_count": 0 },
    { "label": "Delivery Challan", "sub_series_name": "delivery_challan", "prefix_value": "DC", "start_count": "01", "last_count": 0 },
    { "label": "Customer Payment", "sub_series_name": "customer_payment", "prefix_value": "CMR", "start_count": "01", "last_count": 0 },
    { "label": "Vendor Payment", "sub_series_name": "vendor_payment", "prefix_value": "VMR", "start_count": "01", "last_count": 0 },
    { "label": "Vendor Payment Receipt", "sub_series_name": "vendor_payment_receipt", "prefix_value": "VPR", "start_count": "01", "last_count": 0 },
    { "label": "Credit Note", "sub_series_name": "credit_note", "prefix_value": "CN", "start_count": "01", "last_count": 0 },
    { "label": "Purchase Order", "sub_series_name": "purchase_order", "prefix_value": "PO", "start_count": "01", "last_count": 0 },
    { "label": "Sales Order", "sub_series_name": "sales_order", "prefix_value": "SO", "start_count": "01", "last_count": 0 },
    { "label": "Dealer Order", "sub_series_name": "dealer_order", "prefix_value": "DO", "start_count": "01", "last_count": 0 },
    { "label": "Retainer Invoice", "sub_series_name": "retainer_invoice", "prefix_value": "RET", "start_count": "01", "last_count": 0 },
    { "label": "Vendor Credits", "sub_series_name": "vendor_credits", "prefix_value": "VCN", "start_count": "01", "last_count": 0 },
    { "label": "Dealer Collection", "sub_series_name": "dealer_collection", "prefix_value": "DCR", "start_count": "01", "last_count": 0 },
    { "label": "Dealer Collection (PDC)", "sub_series_name": "dealer_collection_pdc", "prefix_value": "PDCR", "start_count": "01", "last_count": 0 },
    { "label": "Expense Record", "sub_series_name": "expense_record", "prefix_value": "EXP", "start_count": "01", "last_count": 0 },
    { "label": "Creditor Payment Receipt", "sub_series_name": "creditor_payment_receipt", "prefix_value": "CPR", "start_count": "01", "last_count": 0 },
    { "label": "Manufacturer Payment Receipt", "sub_series_name": "manufacturer_payment_receipt", "prefix_value": "MPR", "start_count": "01", "last_count": 0 },
    { "label": "Goods Received", "sub_series_name": "goods_received", "prefix_value": "GR", "start_count": "01", "last_count": 0 },
    { "label": "Bills", "sub_series_name": "bills", "prefix_value": "BILL", "start_count": "01", "last_count": 0 },
    { "label": "Sales Returns", "sub_series_name": "sales_returns", "prefix_value": "SR", "start_count": "01", "last_count": 0 }
]
// match sub_series_name and label during additon of new sub series part
export default series_data